import ASSIGNING_RIDER from '../../assets/images/Status/1-assigning-rider.svg'
import RIDER_ASSIGNED from '../../assets/images/Status/2-rider-assigned.svg'
import RIDER_PICKUP from '../../assets/images/Status/3-rider-pickup.svg'
import RIDER_PICKUP_DONE from '../../assets/images/Status/4-rider-pickup-done.svg'
import ARRIVED_AT_HUB from '../../assets/images/Status/5-arrived-at-hub.svg'
import RIDER_DROP_OFF from '../../assets/images/Status/6-rider-dropoff.svg'
import ORDER_ARRIVED from '../../assets/images/Status/7-order-arrived.svg'
import ORDER_COMPLETED from '../../assets/images/Status/8-order-completed.svg'
import ORDER_CANCELLED from '../../assets/images/Status/9-order-cancelled.svg'

export const ORDER_STATUS_IMAGE = {
  ASSIGNING_RIDER,
  RIDER_ASSIGNED,
  RIDER_PICKUP,
  RIDER_PICKUP_DONE,
  ARRIVED_AT_HUB,
  RIDER_DROP_OFF,
  ORDER_ARRIVED,
  ORDER_COMPLETED,
  ORDER_CANCELLED
}
