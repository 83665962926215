import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Drawer, Space, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SubmitButton from './SubmitButton';
import FormInput from './FormInput';
import { ITEM_TYPES } from '../utils/enums/AppIcons';
import { formatText } from '../utils/helpers/purefunctions';
import { setOnDemandItemType, setOnDemandItemDescription } from '../redux/onDemand/actions';
import { setStandardItemType, setStandardItemDescription } from '../redux/standard/actions';
import { MODULE_PATH } from '../constants/Module';

const ItemDetailsList = ({
  show,
  view,
  onSetItemDetailsDrawer,
  setOnDemandItemType,
  setStandardItemType,
  setOnDemandItemDescription,
  setStandardItemDescription,
  standardItemType,
  onDemandItemType,
  standardItemDescription,
  onDemandItemDescription,
}) => {
  const [type, setType] = useState(null);
  const [description, setDescription] = useState('');
  const [inputFocus, setInputFocus] = useState(null);

  const isStandard = view === MODULE_PATH.STANDARD.MAIN;

  useEffect(() => {
    if (isStandard) {
      setStandardItemType(standardItemType);
      setStandardItemDescription(standardItemDescription);
    } else {
      setOnDemandItemType(onDemandItemType);
      setOnDemandItemDescription(onDemandItemDescription);
    }
  }, []);

  const handleItems = () => {
    if (isStandard) {
      setStandardItemType(type);
      setStandardItemDescription(description);
    } else {
      setOnDemandItemType(type);
      setOnDemandItemDescription(description);
    }
    onSetItemDetailsDrawer();
  };

  const ItemDisplay = () => {
    return (
      <>
        {Object.keys(ITEM_TYPES).map((data, index) => (
          <span
            className={`item ${type === data ? 'selected' : ''}`}
            onClick={() => setType(data)}
            key={index}
          >
            <span>{formatText(data)}</span>
            <img src={ITEM_TYPES[data.toUpperCase()]} alt="item" />
          </span>
        ))}
      </>
    );
  };

  return (
    <Drawer
      title="Item Details"
      placement="bottom"
      height="280px"
      className="item-type-drawer"
      onClose={() => onSetItemDetailsDrawer()}
      open={show}
      closable={false}
      extra={
        <Space>
          <Button className="close-btn" onClick={() => onSetItemDetailsDrawer()}>
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
        </Space>
      }
    >
      <div className="item-details-container">
        <span className="title">Item Type</span>
        <div className="item-types">
          <div className="viewport">
            <div className="horizontal">
              <ItemDisplay />
            </div>
          </div>
        </div>
        <FormInput
          value={description}
          onChange={value => setDescription(value)}
          onClick={() => setDescription('')}
          onFocus={() => setInputFocus('description')}
          inputFocusName={inputFocus === 'description'}
          onBlur={() => setInputFocus(null)}
          placeholder="Item Description"
          hasCounter={true}
        />
        <SubmitButton isProceedValue={type} name="Confirm" onClick={() => handleItems()} />
      </div>
    </Drawer>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setOnDemandItemType,
      setStandardItemType,
      setOnDemandItemDescription,
      setStandardItemDescription,
    },
    dispatch
  );

const mapStateToProps = state => ({
  view: state.homeReducer.view,
  onDemandItemType: state.onDemandReducer.itemType,
  standardItemType: state.standardReducer.itemType,
  onDemandItemDescription: state.onDemandReducer.itemDescription,
  standardItemDescription: state.standardReducer.itemDescription,
});

export default connect(mapStateToProps, matchDispatchToProps)(ItemDetailsList);
