import DEFAULT from '../../assets/images/Couriers Images/On-demand Couriers/Courier-no-icon.png';
import BORZO from '../../assets/images/Couriers Images/On-demand Couriers/2x/Borzo.png';
import MRSPEEDY from '../../assets/images/Couriers Images/On-demand Couriers/2x/Borzo.png';
import GRAB from '../../assets/images/Couriers Images/On-demand Couriers/2x/Grab.png';
import LALAMOVE from '../../assets/images/Couriers Images/On-demand Couriers/2x/Lalamove.png';
import TRANSPORTIFY from '../../assets/images/Couriers Images/On-demand Couriers/2x/Transportify.png';
import NINJAVAN from '../../assets/images/Couriers Images/Standard Couriers/2x/NinjaVan.png';
import QUADX from '../../assets/images/Couriers Images/Standard Couriers/2x/QuadX.png';
import XDE from '../../assets/images/Couriers Images/Standard Couriers/2x/XDE.png';
import CLIQNSHIP from '../../assets/images/Couriers Images/Standard Couriers/2x/Cliqnship.png';

export const COURIER_IMAGES = {
  MRSPEEDY,
  BORZO,
  GRAB,
  LALAMOVE,
  TRANSPORTIFY,
  NINJAVAN,
  QUADX,
  XDE,
  CLIQNSHIP,
  DEFAULT,
};
