import React from 'react';
import { useNavigate } from 'react-router';
import orderCancelled from '../../../../shared/assets/images/Cancel-orders/order-cancelled.svg';
import { MODULE_PATH } from '../../../../shared/constants/Module';

const OrderCancelled = () => {
  const navigate = useNavigate();
  const handleReturnHome = () => navigate(MODULE_PATH.HOME);
  const gotoOrderDetails = () => navigate(MODULE_PATH.ORDERS.ORDER_DETAILS);

  return (
    <>
      <div className="order-cancelled-container">
        <div className="image">
          <img src={orderCancelled} alt="Order Cancelled" className="order-cancelled-img" />
        </div>
        <div className="cancelled-content">
          <div className="label">Your order has been cancelled.</div>
          <div className="text">
            The refund for this order is currently being processed and will be automatically
            credited to your GCash wallet within 3-5 business days.
          </div>
        </div>
        <div className="cancelled-buttons">
          <div className="view-order-details" onClick={gotoOrderDetails}>
            View Order Details
          </div>
          <div className="back-my-orders" onClick={handleReturnHome}>
            Back to My Orders
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderCancelled;
