import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const OrderControlBtn = ({
  onClickForRefund,
  onClickTrackOrder,
  onClickBookAgain,
  paymentStatus,
  status,
}) => {
  if (status === 'DONE') {
    return (
      <div className="track-order-btn" onClick={onClickBookAgain}>
        <span>Book Again</span>
      </div>
    );
  }

  if (status === 'CANCELLED') {
    if (!paymentStatus) {
      return (
        <div className="track-order-btn" onClick={onClickForRefund}>
          <span>For Refund</span>
        </div>
      );
    }
    return (
      <div className="track-order-btn" onClick={onClickBookAgain}>
        <span>Rebook</span>
      </div>
    );
  }
  return (
    <div className="track-order-btn" onClick={onClickTrackOrder}>
      <FontAwesomeIcon icon={solid('location-arrow')} />
      <span>Track Order</span>
    </div>
  );
};

export default OrderControlBtn;
