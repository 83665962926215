export const PAGE = {
  HOME: 'Home',
  ADDRESS_BOOK: 'Address Book',
};

export const MODULE_PATH = {
  HOME: '/',
  ON_DEMAND: {
    MAIN: '/on-demand-delivery-form',
  },
  STANDARD: {
    MAIN: '/standard-delivery-form',
    SENDER_DETAILS: '/sender-details-form',
    PRODUCT_LIST: '/product-list-selection',
    COURIER_LIST: '/courier-list-selection',
    SELECT_ADDRESS: '/select-address',
    ORDER_HISRORY: '/order-history-page',
  },
  SHARED: {
    VEHICLE: '/vehicle-types-selection',
    ITEM: '/item-details-selection',
    COURIER: '/couriers-selection',
  },
  ADDRESS: {
    SELECT_ADDRESS: '/choose-address',
    SEARCH_LOCATION: '/search-location',
    PICKUP_DETAILS: '/pick-up-details',
  },
  ADDRESS_BOOK: {
    MAIN: '/address-book',
    FORM: '/address-book-forms',
    MAP: '/address-book-map',
  },
  MY_ACCOUNTS: {
    MAIN: '/my-account',
    ACCOUNT_DETAILS: '/account-details',
    CONTACT_US: '/contact-us',
    ABOUT_US: '/about-us',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_CONDITIONS: '/terms-and-conditions',
  },
  ORDERS: {
    VIEW_ALL_ORDERS: '/all-orders',
    PAYMENT_BREAKDOWN: '/payment-breakdown',
    ORDER_DETAILS: '/order-details',
    CANCEL_ORDER: '/order-cancellation',
    ORDER_CANCELLED: '/order-cancelled-page',
    SEARCH_ORDER: '/search-orders',
  },
  PAYMENT: {
    MAIN: '/payment-status-page',
    PAYMENT_SUMMARY: '/payment-summary',
    PAYMENT_PROCESS: '/processing-payment',
  },
  EXTRAS: {
    HELP: '/help-page',
    LOADER: '/loading-page',
    ERROR: '/error-page',
    COMING_SOON: '/coming-soon-page',
    COMING_SOON_IOS: '/coming-soon-ios',
  },
};
