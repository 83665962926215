import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router';
import OrdersCard from '../../../../../shared/components/OrdersCard';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setFormView } from '../../../../../shared/redux/home/actions';
import { MODULE_PATH, PAGE } from '../../../../../shared/constants/Module';
import { BackTop } from 'antd';

import {
  fetchCancelledOrders,
  fetchDoneOrders,
  fetchForRefundOrders,
  fetchOrdersFromMongo,
  fetchPendingOrders,
} from '../../../../../shared/redux/home/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { formatOrderListMongo, sortList } from '../../../../../shared/utils/helpers/purefunctions';
import { ICON } from '../../../../../shared/utils/enums/AppIcons';

const ViewAllOrders = ({ setFormView }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const fetchedAll = useSelector(state => state.homeReducer.fetchAll);
  const user = useSelector(state => state.usersReducer.currentUser);
  const { orderList, completedOrderList, orderListMongo, forRefundOrders, cancelledOrders } =
    useSelector(state => state.homeReducer);
  const formattedOrderListMongo = formatOrderListMongo(orderListMongo);

  const allCancelledOrders = [...formattedOrderListMongo, ...forRefundOrders, ...cancelledOrders];
  const allOrders = [...orderList, ...completedOrderList, ...allCancelledOrders];

  useEffect(() => {
    const actionFunctions = [
      fetchPendingOrders,
      fetchForRefundOrders,
      fetchCancelledOrders,
      fetchOrdersFromMongo,
      fetchDoneOrders,
    ];
    actionFunctions.forEach(actionFunction => {
      dispatch(actionFunction(user.id, 'false'));
    });
  }, [user.id, dispatch]);

  const filterOrders = (orders, searchValue) => {
    return orders.filter(order => {
      const deliveryDetails = JSON.parse(order.meta);
      const isMatch =
        order.refNo.includes(searchValue) ||
        order.customerName.includes(searchValue) ||
        deliveryDetails.customerName.includes(searchValue) ||
        order.customerName.toLowerCase().includes(searchValue.toLowerCase()) ||
        deliveryDetails.customerName.toLowerCase().includes(searchValue.toLowerCase());
      return isMatch;
    });
  };

  const handleChange = e => {
    const { value } = e.target;
    const filtered = filterOrders(allOrders, value);

    if (!value) return setIsSearching(false);

    setFilteredOrders(filtered);
    setIsSearching(true);
  };

  const tempOngoingOrders = isSearching ? filteredOrders : orderList;
  const tempCompleted = isSearching ? filteredOrders : completedOrderList;
  const tempCancelledOrders = isSearching ? filteredOrders : allCancelledOrders;

  const NoOrder = ({ status }) => (
    <span className="loading__orders" style={{ color: '#7d838f', fontSize: '12px' }}>
      <div className="no-orders-icon">
        <img src={ICON.NO_ORDER_ALL} title="No Orders Yet" alt="no order yet" />
      </div>
      <span className="header">{`There are no ${status} orders!`}</span>
      <span className="no-orders-txt">
        Book your <span style={{ color: '#E94E75' }}>new order</span> now!
      </span>
    </span>
  );

  const renderOrders = (orders, status) => {
    const filterLatestOrder = sortList(orders).slice(0, 9);
    if (!fetchedAll) {
      return (
        <span className="loading__orders" style={{ color: '#7d838f', fontSize: '12px' }}>
          Loading . . .
        </span>
      );
    }
    return !orders.length ? <NoOrder status={status} /> : <OrdersCard orders={filterLatestOrder} />;
  };

  const items = [
    {
      key: '1',
      label: 'Ongoing',
      children: renderOrders(tempOngoingOrders, 'ongoing'),
    },
    {
      key: '2',
      label: 'Completed',
      children: renderOrders(tempCompleted, 'completed'),
    },
    {
      key: '3',
      label: 'Cancelled',
      children: renderOrders(tempCancelledOrders, 'cancelled'),
    },
  ];

  const navigateToHome = () => {
    setFormView(PAGE.HOME);
    navigate(MODULE_PATH.HOME);
  };

  return (
    <div className="view__all__orders">
      <BackTop />
      <div className="header">
        <Button
          className="back-button"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigateToHome()}
        />
        <FontAwesomeIcon icon={solid('magnifying-glass')} />
        <input placeholder="Search order..." onChange={handleChange} />
      </div>

      <Tabs defaultActiveKey="1" items={items} indicatorSize={origin => origin + 50} />
    </div>
  );
};

const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormView,
    },
    dispatch
  );
};

export default connect(null, matchDispatchToProps)(ViewAllOrders);
