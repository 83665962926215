import produce from 'immer';
import { SET_CURRENT_USER } from '../enums/types';

const initialState = {
  currentUser: {
    email: '',
    firstName: '',
    lastName: '',
    gcashNumber: '',
    id: '',
  },
};

// const initialState = {
//   currentUser: {
//     email: 'lizllante@gmail.com',
//     firstName: 'BILLSPAY',
//     gcashNumber: '09056628913',
//     id: '110700400000000000647412367',
//     lastName: 'UAT TESTING',
//   },
// };

// const initialState = {
//   currentUser: {
//     email: 'mgocampo21@gmail.com',
//     firstName: 'MARY GRACE',
//     lastName: 'OCAMPO',
//     gcashNumber: '09177930363',
//     id: '110700400000000000106238155',
//   },
// };

// const initialState = {
//   currentUser: {
//     email: 'reymart@ctoglobal.co',
//     firstName: 'REYMART',
//     lastName: 'BORDO',
//     gcashNumber: '09502986747',
//     id: '110700400000000000648610737',
//   },
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_CURRENT_USER:
        draft.currentUser = action.payload;
        break;
      default:
        return draft;
    }
  });
