import React from 'react';

const AddressBookLoader = () => {
  return (
    <div className="card-box">
      <div className="card-title">
        <span className="skeleton-box" />
        <span className="skeleton-box" />
        <span className="skeleton-box" />
      </div>
      <div className="card-address">
        <span className="skeleton-box" />
        <span className="skeleton-box" />
      </div>
      <div className="card-contact">
        <span className="skeleton-box" />
        <span className="skeleton-box" />
      </div>
    </div>
  );
};

export default AddressBookLoader;
