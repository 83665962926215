import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import kgImage from '../../../../shared/assets/images/OndemandDelivery/kg.png';
import boxImage from '../../../../shared/assets/images/OndemandDelivery/box.png';

import DPHDAO from '../../../../shared/utils/dao/DPH';
import { Skeleton, Space } from 'antd';
import { get } from 'lodash';
import {
  STANDARD_COURIERS,
  STANDARD_DIMENSIONS,
} from '../../../../shared/utils/enums/StandardDelivery';
import { ICON, STANDARD_ICON } from '../../../../shared/utils/enums/AppIcons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import StickyHeader from '../../../../shared/components/StickyHeader';
import { MODULE_PATH } from '../../../../shared/constants/Module';

import { COURIER_IMAGES } from '../../../../shared/utils/enums/CourierImages';
import { formatBills, moveErrorsToLast } from '../../../../shared/utils/helpers/purefunctions';
import { setCourier } from '../../../../shared/redux/standard/actions';
import { setOnDemandInitialState } from '../../../../shared/redux/onDemand/actions';
import EMPTY_COURIER from '../../../../shared/assets/svg/courier_illustration.svg';
import { GCASH, serviceType } from '../../../../shared/utils/enums/AppConstants';
import { PRODUCT_SIZE_NOT_SUPPORTED } from '../../../../shared/utils/enums/Messages';

const StandardCourierList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [excluded, setExcluded] = useState([]);
  const [serviceable, setServiceable] = useState([]);
  const [standardCourier, setStandardCourier] = useState([]);
  const [deliveryPickupDays, setDeliveryPickupDays] = useState([]);

  const standard = useSelector(state => state.standardReducer);

  const product = get(standard, 'product');
  const weight = get(product, 'description.weightText');
  const senderDetails = get(standard, 'pickUp.senderDetails');
  const recipientDetails = get(standard, 'dropOff.recipientDetails');

  const productSize = product && product.value.replace(' ', '_');
  const productSizeInitial = productSize[0] === 'E' ? 'XL' : productSize[0];

  const pattern = /\d+/g;
  const [productWeight] = weight.match(pattern);

  const getScheduledPayload = useMemo(
    () => ({
      serviceType: serviceType.scheduled,
      pickup: {
        province: senderDetails.province,
        city: senderDetails.city,
        barangay: senderDetails.barangay,
      },
      delivery: {
        province: recipientDetails.province,
        city: recipientDetails.city,
        barangay: recipientDetails.barangay,
      },
      dimensions: {
        length: 0,
        width: 0,
        height: 0,
      },
      weight: Number(productWeight),
    }),
    [
      senderDetails.province,
      senderDetails.city,
      senderDetails.barangay,
      recipientDetails.province,
      recipientDetails.city,
      recipientDetails.barangay,
      productWeight,
    ]
  );

  const getByPartnersPayload = useMemo(
    () => ({
      business_id: GCASH,
      applyAllCodes: true,
      voucher_codes: [],
      partners: STANDARD_COURIERS,
      pickup: {
        province: senderDetails.province,
        city: senderDetails.city,
        barangay: senderDetails.barangay,
      },
      delivery: {
        province: recipientDetails.province,
        city: recipientDetails.city,
        barangay: recipientDetails.barangay,
      },
    }),
    [
      senderDetails.province,
      senderDetails.city,
      senderDetails.barangay,
      recipientDetails.province,
      recipientDetails.city,
      recipientDetails.barangay,
    ]
  );

  useEffect(() => {
    const getServiceMatrix = async () => {
      const serviceMatrix = await DPHDAO.getByPartners(getByPartnersPayload);
      setExcluded(serviceMatrix.result.excluded);
      setServiceable(serviceMatrix.result.serviceable);
    };

    getServiceMatrix();
  }, [getByPartnersPayload]);

  useEffect(() => {
    const getStandardCourier = async () => {
      const standard = await DPHDAO.getAvailableScheduledPartner(getScheduledPayload);
      const updatedStandard = [...standard.filterCouriers];
      const uniqueIdMap = new Map();

      standard.filterCouriers.forEach(data => {
        const isAvailableProductSize = data.products.some(
          product => product.size === productSizeInitial
        );
        if (!isAvailableProductSize) {
          const error = [PRODUCT_SIZE_NOT_SUPPORTED];
          updatedStandard.push({ ...data, id: data.id, error });
        }
      });

      updatedStandard.forEach(obj => {
        if (!uniqueIdMap.has(obj.id)) {
          uniqueIdMap.set(obj.id, obj);
        } else if (obj.error) {
          uniqueIdMap.set(obj.id, obj);
        }
      });

      const filteredStandard = Array.from(uniqueIdMap.values());

      excluded.forEach(obj => {
        const clientId = obj.partner.client_id;
        const isExistingClientId = standard.filterCouriers.some(data => data.id === clientId);
        const error = [];

        if (!isExistingClientId) {
          const hasPickupAndDelivery = /(?=.*delivery)(?=.*pickup)/.test(obj.excludedBy);
          const isDelivery = obj.excludedBy.includes('delivery');
          const isPickup = obj.excludedBy.includes('pickup');

          if (isDelivery)
            error.push('Delivery address is not included in courier’s serviceable areas.');

          if (isPickup)
            error.push('Pickup address is not included in courier’s serviceable areas.');

          if (hasPickupAndDelivery)
            error.push(
              'Pickup and Delivery address is not included in courier’s serviceable areas.'
            );

          const newObj = {
            id: clientId,
            error,
          };

          filteredStandard.push(newObj);
        }
      });

      const pickupDeliveryInfo = serviceable.map(obj => {
        return {
          deliveryDays: obj.estimated_delivery,
          pickupDays: obj.estimated_pickup,
          clientId: obj.partner.client_id,
        };
      });

      setDeliveryPickupDays(pickupDeliveryInfo);
      setStandardCourier(filteredStandard);
    };
    getStandardCourier();
  }, [getScheduledPayload, excluded, productSize, serviceable]);

  const prevNavigate = {
    label: 'Choose Courier',
    imgPath: ICON.ON_DEMAND,
    path: MODULE_PATH.STANDARD.MAIN,
  };

  const handleSelectCourier = data => {
    if (!data.rate) return;
    dispatch(setCourier(data));
    navigate(MODULE_PATH.STANDARD.MAIN, { state: { isClicked: true } });
  };

  const SkeletonLoaders = ({ count }) => (
    <>
      {Array.from({ length: count }, (_, i) => (
        <div className="courier-list loader" key={i}>
          <div className="courier-info">
            <Space>
              <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
              <Skeleton.Input
                block={true}
                active={true}
                size={'small'}
                style={{ width: '220px' }}
              />
            </Space>
          </div>
          <div className="courier-vehicle-type">
            <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
            <Space>
              <Skeleton.Input
                block={true}
                active={true}
                size={'small'}
                style={{ width: '250px' }}
              />
            </Space>
          </div>
        </div>
      ))}
    </>
  );

  const CourierList = ({ standardCourier }) => {
    const filteredStandardCouriers = standardCourier.filter(courier => !courier?.error);

    if (filteredStandardCouriers.length === 0) {
      return (
        <div className="empty-courier">
          <div className="empty-courier-container">
            <div className="image">
              <img src={EMPTY_COURIER} alt="empty courier" />
            </div>
            <div className="content">No available couriers on your product size</div>
            <div className="sub-content">Please select another product size</div>
          </div>
        </div>
      );
    }

    return (
      filteredStandardCouriers &&
      filteredStandardCouriers.map((order, index) => {
        const [productRate] = order?.products
          .filter(product => product.size === productSizeInitial)
          .flat();

        const filteredServiceDays = deliveryPickupDays.filter(obj => obj.clientId === order.id);
        const pickupDays = filteredServiceDays.length
          ? `1-${filteredServiceDays[0].pickupDays} days`
          : '';

        const deliveryDays = filteredServiceDays.length
          ? `1-${filteredServiceDays[0].deliveryDays} days`
          : '';

        const selectedCourier = {
          courier: order.id,
          rate: productRate?.price,
          productSize,
        };

        return (
          <div
            className={`courier-details-card ${!selectedCourier.rate ? 'error' : ''}`}
            key={index}
            onClick={() => handleSelectCourier(selectedCourier)}
          >
            <div className="courier-info">
              <div className="courier">
                <img src={COURIER_IMAGES[order.id]} alt="courier" />
                <span>{order.id}</span>
              </div>
              <span>{productRate ? `P ${formatBills(productRate.price)}` : ''}</span>
            </div>

            {order.error ? (
              <span>{order.error}</span>
            ) : (
              <>
                <div className="delivery-days">
                  <div className="pickup">
                    <FontAwesomeIcon icon={solid('calendar-days')} />
                    <div>
                      Pickup <span>{pickupDays}</span>
                    </div>
                  </div>
                  <div className="delivery">
                    <FontAwesomeIcon icon={solid('truck')} />
                    <div>
                      Delivery <span>{deliveryDays}</span>
                    </div>
                  </div>
                </div>
                <div className="product">
                  <img src={STANDARD_ICON[productSize.toUpperCase()]} alt="product-icon" />
                  <div className="product-description">
                    <span>{`${productSize} pouch`}</span>
                    <div className="dimensions">
                      <img src={kgImage} alt="kg" />
                      <span>{STANDARD_DIMENSIONS[order.id][productSize].weightText}</span>
                      <img src={boxImage} alt="bag" />
                      <span>{STANDARD_DIMENSIONS[order.id][productSize].dimensionText}</span>
                    </div>
                    <span>Product Description</span>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      })
    );
  };

  return (
    <div className="courier__list__container__standard">
      <StickyHeader title="Choose Courier" onPrevNavigate={prevNavigate} />
      <div className="content">
        {!standardCourier.length ? (
          <SkeletonLoaders count={4} />
        ) : (
          <CourierList standardCourier={standardCourier} />
        )}
      </div>
    </div>
  );
};

export default StandardCourierList;
