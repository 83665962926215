import React, { useState, useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'lodash';
import { Button, Skeleton, Space, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import AddressBook from '../../../../shared/assets/images/Icons/Menu Icons/address-book-inactive-1.svg';
import { useLocation, useNavigate } from 'react-router';
import Maps from './Maps';
import axios from 'axios';
import { setPickUp, setDropOff, setPointLocation } from '../../../../shared/redux/onDemand/actions';
import StartPointLocation from '../../../../shared/assets/images/OndemandDelivery/start_point_location.png';
import AddressBookDAO from '../../../../shared/utils/dao/AddressBook';
import {
  MAPS_GEOCODE,
  MAPS_API,
  MAPS_API_KEY,
  zeroKm,
} from '../../../../shared/utils/enums/AppConstants';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  isEmpty,
  phoneFormat,
  isValidPhoneNumber,
} from '../../../../shared/utils/helpers/purefunctions';

import { MODULE_PATH } from '../../../../shared/constants/Module';
import FormInput from '../../../../shared/components/FormInput';
import { REF } from '../../../../shared/constants/AddressBook';
import { memo } from 'react';

const PinLocation = ({
  propsPickUp,
  propsDropOff,
  pointLocation,
  setPickUp,
  setDropOff,
  view,
  user,
}) => {
  const navigate = useNavigate();

  const [address, setAddress] = React.useState('');
  const [pinLocation, setPinLocation] = useState(null);
  const [latAndLng, setLatandLng] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isFillUp, setIsFillUp] = useState(false);
  const [inputFocus, setInputFocus] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const [mapHeight, setMapHeight] = useState('50%');
  const [notesToRider, setNotesToRider] = useState({ notes: '' });
  const [senderDetails, setSenderDetails] = useState({
    id: '',
    contactNo: '',
    fullName: '',
    emailAddress: '',
    unitFloorHouse: '',
    bldgStreet: '',
    province: '',
    city: '',
    barangay: '',
    postalCode: '',
    landmark: '',
  });

  const location = useSelector(state => state.onDemandReducer.location);
  const locate = useLocation();
  const locationReference = useSelector(state => state.onDemandReducer.locationReference);
  const selectedAddress = useSelector(state => state.addressReducer.selectedAddress);
  const ondemand = useSelector(state => state.onDemandReducer);
  const selectedAddressDetails = location.split(',');
  const hasAddress = pinLocation?.formatted_address;
  const { contactNo, fullName, unitFloorHouse } = senderDetails;

  let cityLocation = '';
  let provinceLocation = '';

  useEffect(() => {
    if (!isEmpty(selectedAddress) && locationReference) {
      setSenderDetails({
        ...selectedAddress,
        fullName: selectedAddress.fullName,
        unitFloorHouse: selectedAddress.unitFloorHouse,
        contactNo: selectedAddress.contactNo,
      });
    }

    if (isEmpty(selectedAddress) && pointLocation === 'dropOff') {
      setSenderDetails({});
    }
  }, []);

  useEffect(() => {
    let address = '';

    if (pointLocation === 'PickUp' && !isEmpty(propsPickUp.senderDetails)) {
      const {
        unitFloorHouse = '',
        bldgStreet = '',
        barangay = '',
        city = '',
        province = '',
      } = propsPickUp.senderDetails;
      address = `${unitFloorHouse} ${bldgStreet} ${barangay} ${city} ${province}`;
    }

    if (pointLocation === 'DropOff' && !isEmpty(propsDropOff.recipientDetails)) {
      const {
        unitFloorHouse = '',
        bldgStreet = '',
        barangay = '',
        city = '',
        province = '',
      } = propsDropOff.recipientDetails;
      address = `${unitFloorHouse} ${bldgStreet} ${barangay} ${city} ${province}`;
    }
    onSetGeocodeByAddress(location);
    return () => {};
  }, []);

  useEffect(() => {
    const { fullName, unitFloorHouse, contactNo } = senderDetails;
    setIsFillUp([contactNo, unitFloorHouse, fullName].every(value => !isEmpty(value)));
  }, [senderDetails]);

  useEffect(() => {
    const { pickUp, dropOff } = ondemand;
    if (pointLocation === 'PickUp') {
      if (!isEmpty(propsPickUp.senderDetails)) {
        setSenderDetails({
          address: pickUp?.senderDetails?.address,
          contactNo: pickUp?.senderDetails?.contactNo,
          fullName: pickUp?.senderDetails?.fullName,
          unitFloorHouse: pickUp?.senderDetails?.unitFloorHouse,
        });
      }
    }
    if (pointLocation === 'DropOff') {
      if (!isEmpty(propsDropOff.recipientDetails)) {
        setSenderDetails({
          address: dropOff?.recipientDetails?.address,
          contactNo: dropOff?.recipientDetails?.contactNo,
          fullName: dropOff?.recipientDetails?.fullName,
          unitFloorHouse: dropOff?.recipientDetails?.unitFloorHouse,
        });
      }
    }
  }, []);

  const navigatePinLocation = () => {
    if (pointLocation === 'PickUp') {
      cityLocation = propsPickUp.city;
      provinceLocation = propsPickUp.province;
    }

    if (pointLocation === 'DropOff') {
      cityLocation = propsDropOff.city;
      provinceLocation = propsDropOff.province;
    }

    const { contactNo, fullName, emailAddress, unitFloorHouse, postalCode } = senderDetails;

    if (view === MODULE_PATH.STANDARD.MAIN && isEmpty(postalCode)) {
      return showNotif('error', 'Postal code is required.');
    }

    if (contactNo) {
      const validateContactNumber = /^9\d{9}$/;

      if (!validateContactNumber.test(contactNo.replace(/ /g, '')))
        return showNotif('error', 'Please enter a valid mobile number.');
    }

    if (emailAddress) {
      const validateEmail = String(emailAddress)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (!validateEmail) return showNotif('error', 'Invalid email format. Please try again.');
    }

    const mobileNo = contactNo.replace(/ /g, '');

    if (pointLocation === 'PickUp') {
      setPickUp({
        ...propsPickUp,
        pickupAddress: location,
        address: location,
        notesToRider,
        latitude: latAndLng.lat,
        longitude: latAndLng.lng,
        senderDetails: {
          ...senderDetails,
          contactNo: mobileNo,
          address: locationReference ? location : selectedAddress.address,
          province: propsPickUp.province,
          city: propsPickUp.city,
        },
      });
    }

    if (pointLocation === 'DropOff') {
      setDropOff({
        ...propsDropOff,
        deliveryAddress: location,
        address: location,
        addressType: selectedAddressDetails[0],
        notesToRider,
        latitude: latAndLng.lat,
        longitude: latAndLng.lng,
        recipientDetails: {
          ...senderDetails,
          contactNo: mobileNo,
          address: locationReference ? location : selectedAddress.address,
          province: propsDropOff.province,
          city: propsDropOff.city,
        },
      });
    }

    if (isSubscribed) {
      const addressBookDao = new AddressBookDAO();

      const address = {
        location,
        unitFloorHouse,
        fullName,
        contactNo,
        cityLocation,
        provinceLocation,
        addressType: selectedAddressDetails[0],
        latitude: latAndLng.lat,
        longitude: latAndLng.lng,
      };
      addressBookDao.saveLocation(user.id, address);
    }

    return view === MODULE_PATH.STANDARD.MAIN
      ? navigate(MODULE_PATH.STANDARD.MAIN)
      : navigate(MODULE_PATH.ON_DEMAND.MAIN);
  };

  const showNotif = debounce(async (type, msg) => {
    message[type](msg);
  }, 500);

  const onSetGeocodeByAddress = debounce(address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        onSetPinLocation({
          lat: latLng.lat,
          lng: latLng.lng,
        });
        setLatandLng({ lat: latLng.lat, lng: latLng.lng });
      })
      .catch(err => {
        if (err === 'ZERO_RESULTS') {
          message.error('Address not found. Please manually pin your location instead.');
        } else {
          message.error(err);
        }
      });
  }, 1000);

  const onSetPinLocation = debounce(async data => {
    try {
      const res = await axios.get(
        `${MAPS_GEOCODE}?latlng=${data.lat},${data.lng}&key=${MAPS_API_KEY}`
      );
      if (res.data.results[0]) {
        const resData = res.data.results[0];
        setPinLocation(resData);
      } else {
        message.error("Can't locate address. ");
      }
    } catch (err) {
      message.error(err);
    }
  }, 1000);

  const handleInputFocus = fieldName => {
    !isClicked && setInputFocus(fieldName);
  };

  const handleMarkerDragEnd = e => {
    onSetPinLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const handleRemoveInputFocus = () => {
    if (!isClicked && inputFocus === 'contactNo' && !isEmpty(senderDetails?.contactNo)) {
      let newValue = senderDetails.contactNo.replace(/ /g, '');
      const validatePhone = isValidPhoneNumber(senderDetails.contactNo);
      if (validatePhone.isValid) {
        const input = validatePhone.value;
        var zip = input.substring(0, 3);
        var middle = input.substring(3, 6);
        var last = input.substring(6, 10);
        newValue = `${zip} ${middle} ${last}`;
      }
      setSenderDetails({ ...senderDetails, contactNo: newValue });
    }

    setInputFocus(null);
  };

  const handleCheckBox = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
      console.log('user id', user.id);
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setIsSubscribed(current => !current);
  };

  const handleClearField = field => {
    setIsClicked(true);
    setSenderDetails({
      ...senderDetails,
      [field]: '',
    });
    setIsClicked(false);
  };

  const handleNotesClear = field => {
    setNotesToRider({ ...notesToRider, [field]: '' });
  };

  const handleFormChange = useCallback(
    (field, value) => {
      setSenderDetails({ ...senderDetails, [field]: value });
    },
    [senderDetails]
  );

  const handleChangeNote = (field, value) => {
    setNotesToRider({ ...notesToRider, [field]: value });
  };

  const ProceedButton = ({ onClick }) => {
    if (!isFillUp) {
      return (
        <Button className="disable-btn">
          {pointLocation === 'PickUp' ? 'Confirm Pickup Location' : 'Confirm Drop Off Location'}
        </Button>
      );
    }
    return (
      <Button className="sender-details-btn" onClick={onClick}>
        {pointLocation === 'PickUp' ? 'Confirm Pickup Location' : 'Confirm Drop Off Location'}
      </Button>
    );
  };

  const handleChange = value => {
    setAddress(value);
  };

  const handleSelect = value => {
    setAddress(value);
  };

  const preventHandler = e => {
    e.stopPropagation();
  };

  return (
    <div className="pin-location-container">
      <div className="addressbook-container">
        <div className="recently__used__location">
          <Button
            className="btn-return"
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(MODULE_PATH.ADDRESS.SELECT_ADDRESS)}
          />
          <img onClick={e => preventHandler(e)} src={StartPointLocation} alt="point location" />
          {locate?.state?.showAutoComplete ? (
            <div>
              <PlacesAutocomplete
                value={
                  hasAddress ? (
                    pinLocation?.formatted_address
                  ) : (
                    <Space style={{ display: 'grid' }}>
                      <Skeleton.Input block={true} active={true} size={'small'} />
                      <Skeleton.Input block={true} active={true} size={'small'} />
                    </Space>
                  )
                }
                onChange={handleChange}
                onSelect={handleSelect}
                className="address-data"
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div>
                    <span className={`address-info ${hasAddress ? '' : 'address-loader'}`}>
                      {hasAddress ? (
                        pinLocation?.formatted_address
                      ) : (
                        <Space style={{ display: 'grid' }}>
                          <Skeleton.Input block={true} active={true} size={'small'} />
                          <Skeleton.Input block={true} active={true} size={'small'} />
                        </Space>
                      )}
                    </span>

                    <div>
                      {loading && <div>Loading...</div>}
                      {suggestions.map(suggestion => {
                        const style = suggestion.active
                          ? { backgroundColor: '#a83232', cursor: 'pointer' }
                          : { backgroundColor: '#ffffff', cursor: 'pointer' };

                        return (
                          <div {...getSuggestionItemProps(suggestion, { style })}>
                            {suggestion.description}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
          ) : (
            <div className="address-data">
              <span className={`address-info ${hasAddress ? '' : 'address-loader'}`}>
                {hasAddress ? (
                  pinLocation?.formatted_address
                ) : (
                  <Space style={{ display: 'grid' }}>
                    <Skeleton.Input block={true} active={true} size={'small'} />
                    <Skeleton.Input block={true} active={true} size={'small'} />
                  </Space>
                )}
              </span>
            </div>
          )}
        </div>
      </div>
      {hasAddress && (
        <>
          <Maps
            googleMapURL={`${MAPS_API}?key=${MAPS_API_KEY}`}
            coordinates={pinLocation?.geometry?.location || zeroKm}
            onSetPinLocation={(location, latlong) => onSetPinLocation(location)}
            onMarkerDragEnd={handleMarkerDragEnd}
            containerElement={
              <div
                onClick={() => (mapHeight === '50%' ? setMapHeight('100%') : setMapHeight('50%'))}
                style={{ height: `50vh` }}
                className="map-container"
              />
            }
            mapElement={<div style={{ height: `100%`, marginTop: '-110px' }} />}
            loadingElement={<div style={{ height: `50%` }} />}
          />
          <div className="sender-details-fields">
            <div className="sender-details-form">
              <span className="title">
                {pointLocation === 'PickUp' ? 'Pickup Details' : 'Drop-off Details'}
              </span>
              <FormInput
                value={unitFloorHouse}
                onChange={value => handleFormChange(REF.UNIT_FLOOR, value)}
                onClick={() => handleClearField(REF.UNIT_FLOOR)}
                onFocus={() => handleInputFocus(REF.UNIT_FLOOR)}
                inputFocusName={inputFocus === REF.UNIT_FLOOR}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Unit/Floor/House No"
                isReqOptional="req"
                hasCounter={true}
              />

              <FormInput
                value={fullName}
                onChange={value => handleFormChange(REF.FULLNAME, value)}
                onClick={() => handleClearField(REF.FULLNAME)}
                onFocus={() => handleInputFocus(REF.FULLNAME)}
                inputFocusName={inputFocus === REF.FULLNAME}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Contact Name"
                isReqOptional="req"
              />
              <FormInput
                value={contactNo}
                onChange={value => handleFormChange(REF.CONTACT_NO, value)}
                onClick={() => handleClearField(REF.CONTACT_NO)}
                onFocus={() => handleInputFocus(REF.CONTACT_NO)}
                inputFocusName={inputFocus === REF.CONTACT_NO}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Phone Number"
                isReqOptional="req"
                suffixIcon="PHONE_NO"
                prefixOn={true}
              />
              <FormInput
                value={notesToRider.notes}
                onChange={value => handleChangeNote('notes', value)}
                onClick={() => handleNotesClear('notes')}
                onFocus={() => handleInputFocus('notes')}
                inputFocusName={inputFocus === 'notes'}
                onBlur={() => handleRemoveInputFocus()}
                placeholder="Notes to Rider"
              />

              <div className="forms-checkbox">
                <input
                  type="checkbox"
                  value={isSubscribed}
                  onChange={handleCheckBox}
                  id="subscribe"
                  name="subscribe"
                  className={isSubscribed ? 'check-box-active' : 'checkbox-inactive'}
                />
                <div className="label">
                  <span className={isSubscribed ? 'active' : 'inactive'}>Save this place</span>
                  <span>
                    Save this location to your <img src={AddressBook} alt="address book" />
                    address book for future orders.
                  </span>
                </div>
              </div>

              <ProceedButton onClick={navigatePinLocation} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPickUp,
      setDropOff,
    },
    dispatch
  );

const mapStateToProps = state => ({
  pointLocation: state.onDemandReducer.pointLocation,
  propsPickUp: state.onDemandReducer.pickUp,
  propsDropOff: state.onDemandReducer.dropOff,
  view: state.homeReducer.view,
  user: state.usersReducer.currentUser,
});

export default connect(mapStateToProps, matchDispatchToProps)(memo(PinLocation));
