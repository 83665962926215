/* global my */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './shared/assets/less/app.less';
import './shared/styles/tailwind.css';
import 'antd/dist/antd.css';
import { Provider } from 'react-redux';
import 'firebase/auth';
import { auth } from './shared/utils/helpers/firebaseUtils';
import store from './shared/redux/store';
import { setCurrentUser } from './shared/redux/users/actions';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { setSSOCookies } from './shared/utils/helpers/SSO';
import Unauthorized from './app/modules/external/UnauthorizedPage';
import Login from './shared/utils/dao/Login';
import Splash from './app/includes/splash/Splash';
import App from './app/body/index';
import { isEmpty } from './shared/utils/helpers/purefunctions';
import { queryClient } from './shared/utils/helpers/QueryClient';
import { QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));

const render = (AppComponent, store) => {
  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <AppComponent />
      </QueryClientProvider>
    </Provider>
  );
};

const autoSignIn = async () => {
  try {
    const CREDS = {
      STAGING: {
        EMAIL: 'gcash@parcels.ph',
        PASSWORD: 'l5w2hnsi7',
      },
      PRODUCTION: {
        EMAIL: 'gcash@parcels.ph',
        PASSWORD: 'qwzo6gd2n',
      },
    };

    const email = CREDS.PRODUCTION.EMAIL;
    const password = CREDS.PRODUCTION.PASSWORD;
    const res = await signInWithEmailAndPassword(auth, email, password);

    setSSOCookies([
      {
        name: 'uId',
        value: res.user.uid,
      },
    ]);
  } catch (error) {
    console.error(error);
  }
};

// autoSignIn();

const login = async gcashInfo => {
  const { authCode } = gcashInfo;
  const loginDao = new Login();
  const code = await loginDao.saveGcashAuthCode(authCode);
  const userInfo = await loginDao.getAuthCode(code);
  const user = await loginDao.saveUser(userInfo?.userId, userInfo);
  store.dispatch(setCurrentUser(user));
};

const runApp = async () => {
  try {
    await autoSignIn();
    await my.postMessage({ sendToMiniProgramFromWebApp: '1' });
    my.onMessage = async e => {
      if (!isEmpty(e?.code)) {
        await login(e.code);
        render(App, store);
      } else {
        render(Unauthorized, store);
      }
    };
  } catch (err) {
    render(Unauthorized, store);
    auth.signOut();
  }
};

render(Splash, store);
runApp();
