import { SET_DEFAULT_ADDRESS, SET_ADDRESS, SET_LIST_ADDRESS, SET_SELECTED_ADDRESS, SET_ADDRESSBOOK_INITIAL_STATE, SET_RECENTLY_ADDRESS } from "../enums/types";
import AddressBookDAO from "../../utils/dao/AddressBook";
import { isEmpty } from '../../utils/helpers/purefunctions'

export const setAddressBookInitialState = () => ({
  type: SET_ADDRESSBOOK_INITIAL_STATE
})

export const setAddress = (address) => ({
  type: SET_ADDRESS,
  payload: address
})

export const setDefaultAddress = (defaultAddress) => ({
  type: SET_DEFAULT_ADDRESS,
  payload: defaultAddress
})

export const setRecentlyAddress = (recentlyAddress) => ({
  type: SET_RECENTLY_ADDRESS,
  payload: recentlyAddress
})

export const setSelectedAddress = (selectedAddress) => ({
  type: SET_SELECTED_ADDRESS,
  payload: selectedAddress
})

export const setListAddress = (addressList) => ({
  type: SET_LIST_ADDRESS,
  payload: addressList
})

export const fetchListOfAddress = (userId) => {
  return async (dispatch, getState) => {
    const addressDao = new AddressBookDAO()
    addressDao.getAddressByUser(userId, (addresses) => {
      let defaultAdd = {};
      let recentlyAddress = {};
      let addressList = []
      let addressBook = []
      let order = 3

      addresses.forEach(address => {
        if (address.recently === true) {
          recentlyAddress = { ...address, order: 1 }
        } else if (address.defaultPickUp === true) {
          defaultAdd = { ...address, order: 2 }
        } else {
          addressList.push({ ...address, order })
          order++
        }
      })

      if (!isEmpty(recentlyAddress)) {
        addressBook.push(recentlyAddress)
      }

      let addessBookList = [ ...addresses]
      let defaultAddress = addessBookList.filter( item => item.defaultPickUp === true )
      if (defaultAddress.length) {
        defaultAdd = defaultAddress[0]
      }

      if (!isEmpty(defaultAdd) && recentlyAddress.id !== defaultAdd.id) {
        addressBook.push(defaultAdd)
      }

      dispatch(setRecentlyAddress(recentlyAddress))
      dispatch(setDefaultAddress(defaultAdd))
      dispatch(setListAddress([ ...addressBook,...addressList]))
    }) 
  }
}