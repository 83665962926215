import React, { useEffect } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Orders from './orders';

import { bindActionCreators } from 'redux';
import { BackTop } from 'antd';
import {
  setFormView,
  fetchPendingOrders,
  fetchForRefundOrders,
  fetchCancelledOrders,
  fetchDoneOrders,
  fetchOrdersFromMongo,
} from '../../../shared/redux/home/actions';
import { fetchListOfAddress, setSelectedAddress } from '../../../shared/redux/addressBook/actions';
import { connect, useSelector } from 'react-redux';
import { setOnDemandInitialState } from '../../../shared/redux/onDemand/actions';
import { setStandardInitialState } from '../../../shared/redux/standard/actions';
import { setFetchALL } from '../../../shared/redux/home/actions';
import { isEmpty } from '../../../shared/utils/helpers/purefunctions';

function Home({
  setStandardInitialState,
  setOnDemandInitialState,
  fetchPendingOrders,
  fetchForRefundOrders,
  fetchCancelledOrders,
  fetchDoneOrders,
  fetchOrdersFromMongo,
  user,
  fetchListOfAddress,
  setSelectedAddress,
  setFetchALL,
}) {
  const { orderList, orderListMongo, forRefundOrders, cancelledOrders, completedOrderList } =
    useSelector(state => state.homeReducer);

  const hasOrder = [
    cancelledOrders,
    orderList,
    orderListMongo,
    completedOrderList,
    forRefundOrders,
    setFetchALL,
  ].some(array => !isEmpty(array));

  useEffect(() => {
    if (hasOrder) {
      setFetchALL(true);
    }
  }, [hasOrder, setFetchALL]);

  /* Don't include fetchPendingOrders and fetchDoneOrders as dependency to avoid flickering in orderDetails*/
  useEffect(() => {
    fetchPendingOrders(user.id);
    fetchForRefundOrders(user.id);
    fetchCancelledOrders(user.id);
    fetchOrdersFromMongo(user.id);
    fetchDoneOrders(user.id, 'false');
  }, [fetchOrdersFromMongo, user.id]);

  useEffect(() => {
    fetchListOfAddress(user.id);
  }, []);

  useEffect(() => {
    setOnDemandInitialState();
    setFormView();
    setSelectedAddress({});
  }, [setOnDemandInitialState, setStandardInitialState]);

  return (
    <div className={`home-container`}>
      <Header />
      <Orders />
      <Footer />
      <BackTop />
    </div>
  );
}
const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormView,
      setStandardInitialState,
      setOnDemandInitialState,
      fetchPendingOrders,
      fetchForRefundOrders,
      fetchCancelledOrders,
      fetchDoneOrders,
      fetchOrdersFromMongo,
      fetchListOfAddress,
      setSelectedAddress,
      setFetchALL,
    },
    dispatch
  );
};
const mapStateToProps = state => ({
  user: state.usersReducer.currentUser,
  fetched: state.homeReducer.fetch,
});
export default connect(mapStateToProps, matchDispatchToProps)(Home);
