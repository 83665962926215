import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { useNavigate } from 'react-router';
import { handleReturnMyAccounts } from '../../../../../shared/util/helpers/purefunctions';
import { TERMS_AND_CONDITIONS } from '../../../../../shared/utils/enums/TermsAndConditions';

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { description, contents } = TERMS_AND_CONDITIONS;

  const navigatePrevPage = () => {
    const link = location?.state?.link || '';
    return link ? navigate(link) : handleReturnMyAccounts(navigate);
  };

  return (
    <div className="terms-condtions-container">
      <div className="terms-conditions-content">
        <div className="header">
          <Button
            className="btn-return-myAccounts"
            onClick={() => navigatePrevPage()}
            icon={<ArrowLeftOutlined />}
          />
          <div className="terms-and-conditions"> Terms and Conditions </div>
        </div>
        <div className="body">
          <div className="body-title">Terms and Conditions</div>
          <div className="body-content">
            <p className="description">{description}</p>
            <div className="contents">
              <ol>
                {contents.map((value, index) => (
                  <>
                    <li key={index}>{value.title}</li>
                    <div className="data">
                      {value.body.map((data, key) => (
                        <div key={key}>{data}</div>
                      ))}
                    </div>
                  </>
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsAndConditions;
