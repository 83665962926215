import Cookie from 'js-cookie';

export const clearSSOCookies = () => {
    Cookie.remove('sessionToken');
    Cookie.remove('uId');
    Cookie.remove('email');
    Cookie.remove('isAccountLinked');
}

export const setSSOCookies = (cookies) => {
    const defaultAttributes = {
        expires: 7
    }
    cookies.forEach((cookie) => {
        Cookie.set(cookie.name, cookie.value, {...defaultAttributes, ...cookie.attributes});
    })
}