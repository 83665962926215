import React from 'react';

const FooterBtn = ({ label, onClick }) => {
  return (
    <div className={`footer__btn ${label}`} onClick={onClick}>
      <div className="btn">{label}</div>
    </div>
  );
};

export default FooterBtn;
