/* global my */
import { GCASH_APP_ID } from '../../../shared/utils/enums/AppConstants';

class GCash {
  static async linkOut(url) {
    const result = await my.postMessage({ url, GCASH_APP_ID });
    return result;
  }
}

export default GCash;
