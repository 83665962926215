import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import ParcelsLogo from '../../../../../shared/assets/images/ParcelsLogo/parcels-logo.png';
import { handleReturnMyAccounts } from '../../../../../shared/util/helpers/purefunctions';

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="about-us-container">
      <div className="about-us-content">
        <div className="header">
          <Button
            className="btn-return-myAccounts"
            onClick={() => handleReturnMyAccounts(navigate)}
            icon={<ArrowLeftOutlined />}
          />
          <div className="about-us">About Us</div>
        </div>
        <div className="body-content">
          <div className="parcels-logo-container">
            <img src={ParcelsLogo} alt="Parcels logo" className="parcels-logo" />
            <div className="version">v2.0.0 (1.0.95) Build; Build 1.0.95</div>
            <div className="powered-by">
              Powered by <i>Last Mile, Inc.</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
