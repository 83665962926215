/* global my */
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { MODULE_PATH } from '../../shared/constants/Module';
import Preloader from '../../shared/elements/Preloader';
import Home from './Home/index';
import AddressBook from './AddressBooks';
import AddressBookForm from './AddressBooks/AddressBookForm';
import AddressBookPinMapLocation from './AddressBooks/AddressBookForm/MapLocation';
import OnDemandDelivery from './OndemandDelivery';
import VehicleTypes from '../../shared/components/VehicleTypeList';
import ItemDetailsList from '../../shared/components/ItemDetailsList';
import CouriersList from '../../shared/components/CouriersList';

import StandardDelivery from './StandardDelivery';
import SenderDetails from './StandardDelivery/components/SenderDetails';
import SelectAddress from './StandardDelivery/components/SelectAddress';
import MyAccounts from './Home/myAccounts';
import AccountDetails from './Home/myAccounts/components/AccountDetails';
import ContactUs from './Home/myAccounts/components/ContactUs';
import AboutUs from './Home/myAccounts/components/AboutUs';
import PrivacyPolicy from './Home/myAccounts/components/PrivacyPolicy';
import TermsAndConditions from './Home/myAccounts/components/TermsAndConditions';
import SelectPickupAddress from './Home/components/SelectPickupAddress';
import ViewAllOrders from './Home/orders/components/ViewAllOrders';
import PaymentBreakdown from './Payment/PaymentBreakdown';
import PaymentSummary from './Payment';

import SearchLocation from './Home/components/SearchLocation';
import PickupDetails from './Home/components/PickupDetails';
import SavedPlacesForm from './Home/components/SavedPlacesForm';
import OrderDetails from './Home/orders/components/orderDetails';
import PaymentProcess from './Payment/PaymentProcess';
import Payment from './Payment/Payment';
import CancelOrder from './OndemandDelivery/components/CancelOrder';
import Loader from './OndemandDelivery/elements/Loader';
import OrderCancelled from './OndemandDelivery/elements/OrderCancelled';
import Error from './OndemandDelivery/elements/Error';
import Help from './OndemandDelivery/components/Help';
import SearchOders from './OndemandDelivery/elements/SearchOrder';
import ComingSoon from '../../shared/components/ComingSoon';
import ComingSoonIOS from '../../shared/components/ComingSoonPage';
import ProductList from './StandardDelivery/components/ProductList';
import StandardCourierList from './StandardDelivery/components/StandardCourierList';
import OrderHistory from './StandardDelivery/components/OrderHistory';

function App({ loader }) {
  const {
    ON_DEMAND,
    STANDARD,
    SHARED,
    ADDRESS_BOOK,
    MY_ACCOUNTS,
    ORDERS,
    PAYMENT,
    EXTRAS,
    ADDRESS,
  } = MODULE_PATH;
  return (
    <>
      {loader && <Preloader />}
      <BrowserRouter forceRefresh={true}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path={ON_DEMAND.MAIN} element={<OnDemandDelivery />} />
          <Route path={SHARED.VEHICLE} element={<VehicleTypes />} />
          <Route path={SHARED.ITEM} element={<ItemDetailsList />} />
          <Route path={SHARED.COURIER} element={<CouriersList />} />
          <Route path={STANDARD.MAIN} element={<StandardDelivery />} />
          <Route path={STANDARD.PRODUCT_LIST} element={<ProductList />} />
          <Route path={STANDARD.COURIER_LIST} element={<StandardCourierList />} />
          <Route path={STANDARD.SENDER_DETAILS} element={<SenderDetails />} />
          <Route path={STANDARD.SELECT_ADDRESS} element={<SelectAddress />} />
          <Route path={STANDARD.ORDER_HISRORY} element={<OrderHistory />} />
          <Route path={ADDRESS.SELECT_ADDRESS} element={<SelectPickupAddress />} />
          <Route path={ADDRESS.SEARCH_LOCATION} element={<SearchLocation />} />
          <Route path={ADDRESS.PICKUP_DETAILS} element={<PickupDetails />} />
          <Route path={ADDRESS.SAVED_PLACES_FORM} element={<SavedPlacesForm />} />
          <Route path={PAYMENT.MAIN} element={<Payment />} />
          <Route path={PAYMENT.PAYMENT_SUMMARY} element={<PaymentSummary />} />
          <Route path={PAYMENT.PAYMENT_PROCESS} element={<PaymentProcess />} />
          <Route path={ADDRESS_BOOK.MAIN} element={<AddressBook />} />
          <Route path={ADDRESS_BOOK.FORM} element={<AddressBookForm />} />
          <Route path={ADDRESS_BOOK.MAP} element={<AddressBookPinMapLocation />} />
          <Route path={MY_ACCOUNTS.MAIN} element={<MyAccounts />} />
          <Route path={MY_ACCOUNTS.ACCOUNT_DETAILS} element={<AccountDetails />} />
          <Route path={MY_ACCOUNTS.CONTACT_US} element={<ContactUs />} />
          <Route path={MY_ACCOUNTS.ABOUT_US} element={<AboutUs />} />
          <Route path={MY_ACCOUNTS.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={MY_ACCOUNTS.TERMS_CONDITIONS} element={<TermsAndConditions />} />
          <Route path={ORDERS.VIEW_ALL_ORDERS} element={<ViewAllOrders />} />
          <Route path={ORDERS.PAYMENT_BREAKDOWN} element={<PaymentBreakdown />} />
          <Route path={ORDERS.ORDER_DETAILS} element={<OrderDetails />} />
          <Route path={ORDERS.CANCEL_ORDER} element={<CancelOrder />} />
          <Route path={ORDERS.ORDER_CANCELLED} element={<OrderCancelled />} />
          <Route path={ORDERS.SEARCH_ORDER} element={<SearchOders />} />
          <Route path={EXTRAS.LOADER} element={<Loader />} />
          <Route path={EXTRAS.ERROR} element={<Error />} />
          <Route path={EXTRAS.HELP} element={<Help />} />
          <Route path={EXTRAS.COMING_SOON} element={<ComingSoon />} />
          <Route path={EXTRAS.COMING_SOON_IOS} element={<ComingSoonIOS />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

function mapStateToProps(state) {
  return {
    loader: state.homeReducer.loader,
  };
}

export default connect(mapStateToProps, null)(App);
