import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { handleReturnMyAccounts } from '../../../../../shared/util/helpers/purefunctions';
import EnvelopeCircleCheck from '../../../../../shared/assets/images/My-Accounts/envelope-circle-check.png';
import { SUPPORT_CONTACTS } from '../../../../../shared/utils/enums/AppConstants';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  copyToClipBoard,
  showConfirmationModal,
} from '../../../../../shared/utils/helpers/purefunctions';

const ContactUs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { helpDesk, submitTicket, helpCenter, chatSupportLink, faq } = SUPPORT_CONTACTS;

  const navigateToPrevPage = () => {
    const link = location?.state?.link || '';
    return link ? navigate(link) : handleReturnMyAccounts(navigate);
  };

  const handleOpenLink = async url => {
    showConfirmationModal(url);
  };

  return (
    <div className="contact-us-container">
      <div className="contact-us-content">
        <div className="header">
          <Button
            className="btn-return-myAccounts"
            onClick={() => navigateToPrevPage()}
            icon={<ArrowLeftOutlined />}
          />
          <div className="contact-us">Contact Us</div>
        </div>
        <div className="body-content">
          <div className="help-section">
            <div>How can we help you?</div>
            <div>
              Report your concerns by leaving a message and we'll respond as soon as we can.
            </div>
          </div>
          <div className="contact-section">
            <div className="app-related-issues" onClick={() => copyToClipBoard(helpDesk)}>
              <img
                src={EnvelopeCircleCheck}
                alt="Envelope Circle Check"
                className="envelope-circle-check-img"
              />
              <div className="contact issues">Email Support</div>
              <div className="link">{helpDesk}</div>
              <span className="copy__icon">
                <FontAwesomeIcon icon={solid('copy')} />
              </span>
            </div>
            <div className="submit-ticket" onClick={() => handleOpenLink(chatSupportLink)}>
              <FontAwesomeIcon icon={solid('comment')} />
              <div className="contact">Chat with Live Support</div>
              <span className="copy__icon">
                <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />
              </span>
            </div>
            <div className="submit-ticket" onClick={() => handleOpenLink(submitTicket)}>
              <FontAwesomeIcon icon={solid('ticket')} />
              <div className="contact">Submit a Ticket</div>
              <span className="copy__icon">
                <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />
              </span>
            </div>
            <div className="help-center" onClick={() => handleOpenLink(helpCenter)}>
              <FontAwesomeIcon icon={solid('globe')} />
              <div className="contact">Visit our Help Center</div>
              <span className="copy__icon">
                <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />
              </span>
            </div>
            <div className="faq" onClick={() => handleOpenLink(faq)}>
              <FontAwesomeIcon icon={solid('circle-question')} />
              <div className="contact">FAQs</div>
              <span className="copy__icon">
                <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
