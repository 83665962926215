import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  copyToClipBoard,
  showConfirmationModal,
} from '../../../../shared/utils/helpers/purefunctions';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { SUPPORT_CONTACTS } from '../../../../shared/utils/enums/AppConstants';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import { isEmpty } from 'lodash';

const Help = () => {
  const navigate = useNavigate();
  const orderList = useSelector(state => state.homeReducer.targetOrder);
  const { gcash } = orderList;
  const { lastmileEmail, helpCenter, chatSupportLink } = SUPPORT_CONTACTS;
  const refundAttempt = gcash?.refund?.attempt;
  const meta = orderList.meta ? JSON.parse(orderList.meta) : {};
  const trackingNumber = `Tracking No. ${meta.trackingNumber}`;

  const refundStatus = () => {
    const { gcash, status } = orderList;

    if (gcash?.refund?.success) return 'Refunded';
    if (status === 'CANCELLED' && !gcash?.refund?.success) return 'For Refund';

    return 'Paid';
  };

  const handleOpenLink = url => {
    showConfirmationModal(url);
  };

  return (
    <>
      <div className="help-container">
        <div className="help-content">
          <div className="header">
            <Button
              className="btn-return-myAccounts"
              onClick={() =>
                typeof meta.trackingNumber === 'undefined'
                  ? navigate(MODULE_PATH.HOME)
                  : navigate(MODULE_PATH.ORDERS.ORDER_DETAILS)
              }
              icon={<ArrowLeftOutlined />}
            />
            <div className="about-us">Need Help?</div>
          </div>
          <div className="body-content" style={{ width: '100vw' }}>
            <div className="row-1">
              <div className="label-row-1">Experiencing any issues with your orders?</div>
              <div className="text-row-1">
                Report any delivery concerns about this order to your assigned courier.
              </div>
            </div>
            <div className="row-2">
              <div className="order-info">
                <div className="child-row-1 courier">
                  {orderList.courierId === 'MRSPEEDY' ? 'BORZO' : orderList.courierId}
                </div>
                <div className="child-row-2">
                  <div>
                    <span>{`${
                      isEmpty(meta.trackingNumber) ? 'Tracking No.' : trackingNumber || ''
                    }`}</span>
                    <span
                      className="copy__icon"
                      onClick={() => copyToClipBoard(trackingNumber || '')}
                    >
                      <FontAwesomeIcon icon={solid('copy')} />
                    </span>
                  </div>
                  <div className="date-time">
                    {moment(orderList.completionDateTime).format('MMMM DD, YYYY hh:mm A')}
                  </div>
                </div>
                <div className="child-row-3">
                  <div className="payment-status">Order and Payment Status</div>
                  <div className="order-status">
                    <FontAwesomeIcon icon={solid('circle')} />
                    <div className="status">{orderList.status || orderList.subStatus}</div>
                  </div>
                  <div className={refundAttempt > 2 ? 'support-refund' : 'for-refund'}>
                    {refundStatus()}
                  </div>
                </div>
              </div>
              <div className="app-related-issues">
                <div className="title">For App Related Issues</div>
                <span>
                  Report your concerns by leaving a message and we'll respond as soon as we can.
                </span>
              </div>

              <div className="refund-concerns" onClick={() => copyToClipBoard(lastmileEmail)}>
                <FontAwesomeIcon icon={solid('envelope-circle-check')} />
                <div className="refund-label label">Email us at</div>
                <div className="link">{lastmileEmail}</div>
                <span className="copy__icon">
                  <FontAwesomeIcon icon={solid('copy')} />
                </span>
              </div>
              <div className="courier-hotline" onClick={() => handleOpenLink(chatSupportLink)}>
                <FontAwesomeIcon icon={solid('comment')} />
                <div className="courier-label">Chat with Live Support</div>
                <span className="copy__icon">
                  <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />
                </span>
              </div>
              <div
                className="courier-hotline"
                style={{ marginBottom: '20px' }}
                onClick={() => handleOpenLink(helpCenter)}
              >
                <FontAwesomeIcon icon={solid('globe')} />
                <div className="courier-label">Visit our Help Center</div>
                <span className="copy__icon">
                  <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
