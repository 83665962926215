import axios from 'axios';
import { DEPLOYMENT } from '../enums/AppConstants';
import { message } from 'antd';

class PaymentDAO {
  constructor() {
    this.API = DEPLOYMENT.PARCELS_API;
    this.AUTHORIZATION = '163e3ebd6ea01de274904864e42e2776';
  }

  async gcashPayment(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/payment`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });
      message.info(`gcash payment api:`, response.data.result);
      return {
        success: true,
        result: response.data.result,
      };
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async gcashPaymentInquiry(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/payment/inquiry`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });

      return {
        success: true,
        result: response.data.result,
      };
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async getTransaction(data) {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.API}/v1/transaction`,
        params: { userId: data },
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
      });

      return {
        success: true,
        result: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async pushTransaction(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/transaction`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });

      return {
        success: true,
        result: response.data.result,
      };
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }

  async refund(data) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/refund`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data,
      });
      console.log('refund response.data:', response.data);
      return response.data;
    } catch (error) {
      return {
        success: false,
        message: error.message,
      };
    }
  }
}

export default PaymentDAO;
