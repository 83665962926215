import { Button } from 'antd';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ParcelsLogo from '../../../../shared/assets/images/ParcelsLogo/parcels-logo.png';
import AccountDetails from '../../../../shared/assets/images/My-Accounts/account-details.png';
import ContactUs from '../../../../shared/assets/images/My-Accounts/contact-us.png';
import TermsAndConditions from '../../../../shared/assets/images/My-Accounts/terms-and-conditions.png';
import PrivacyPolicy from '../../../../shared/assets/images/My-Accounts/privacy-policy.png';
import AboutUs from '../../../../shared/assets/images/My-Accounts/about-us.png';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import { setFormView } from '../../../../shared/redux/home/actions';
import { PAGE } from '../../../../shared/constants/Module';

const MyAccounts = ({ setFormView }) => {
  const { MY_ACCOUNTS } = MODULE_PATH;
  const navigate = useNavigate();

  const navigateToHome = () => {
    setFormView(PAGE.HOME);
    navigate(MODULE_PATH.HOME);
  };

  const goToAccountDetails = () => navigate(MY_ACCOUNTS.ACCOUNT_DETAILS);
  const goToContactUs = () => navigate(MY_ACCOUNTS.CONTACT_US);
  const goToTermsAndConditions = () => navigate(MY_ACCOUNTS.TERMS_CONDITIONS);
  const goToPrivacyPolicy = () => navigate(MY_ACCOUNTS.PRIVACY_POLICY);
  const goToAboutUs = () => navigate(MY_ACCOUNTS.ABOUT_US);
  return (
    <div className="my-accounts-container">
      <div className="my-accounts-content">
        <div className="my-accounts-header">
          <Button className="btn-return-home" onClick={navigateToHome}>
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
          <div className="my-accounts">My Accounts</div>
        </div>
        <div className="parcels-logo-container">
          <img src={ParcelsLogo} alt="Parcels logo" className="parcels-logo" />
        </div>
        <div className="items-container">
          <div className="account__details" onClick={goToAccountDetails}>
            <img src={AccountDetails} alt="Account Details" className="account-details-img" />
            <div className="account-details-text">Account Details</div>
          </div>
          <div className="contact__us" onClick={goToContactUs}>
            <img src={ContactUs} alt="Contact Us" className="contact-us-img" />
            <div className="contact-us-text">Contact Us</div>
          </div>
          <div className="terms__and__conditions" onClick={goToTermsAndConditions}>
            <img
              src={TermsAndConditions}
              alt="Terms and Conditions"
              className="terms-and-conditions-img"
            />
            <div className="terms-and-conditions-text">Terms And Conditions</div>
          </div>
          <div className="privacy__policy" onClick={goToPrivacyPolicy}>
            <img src={PrivacyPolicy} alt="Privacy Policy" className="privacy-policy-img" />
            <div className="privacy-policy-text">Privacy Policy</div>
          </div>
          <div className="about__us" onClick={goToAboutUs}>
            <img src={AboutUs} alt="About Us" className="about-us-img" />
            <div className="about-us-text">About Us</div>
          </div>
        </div>
      </div>
    </div>
  );
};
const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormView,
    },
    dispatch
  );
};

export default connect(null, matchDispatchToProps)(MyAccounts);
