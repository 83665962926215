// STATUSES
export const ASSIGNING_RIDER = 'Assigning A Rider';
export const ASSIGNED = 'Assigning A Rider';
export const ACCEPTED = 'Assigning A Rider';
export const QUEUED = 'Assigning A Rider';
export const ARRIVED_AT_HUB = 'ARRIVED_AT_HUB';
export const STARTED_PICKUP = 'Pickup Started';
export const STARTED = 'Pickup Started' || 'Delivery Started';
export const DONE_PICKUP = 'Pickup Done';
export const STARTED_DELIVERY = 'Delivery Started';
export const ORDER_ARRIVED = 'Order Arrived';
export const FOR_REFUND = 'For Refund';
export const REFUNDED = 'Refunded';
export const CANCELLED = 'Cancelled';
export const COMPLETED = 'Completed';
export const DONE = 'Completed';

export const ORDER_STATUSES = {
  QUEUED,
  ASSIGNING_RIDER,
  ASSIGNED,
  ACCEPTED,
  COMPLETED,
  CANCELLED,
  STARTED_PICKUP,
  STARTED_DELIVERY,
  DONE_PICKUP,
  DONE,
};

// PENDING STATUSES
export const PENDING_STATUSES = {
  QUEUED,
  ASSIGNING_RIDER,
  ASSIGNED,
  ACCEPTED,
};

// FOR PICKUP STATUSES
export const FOR_PICKUP_STATUSES = {
  STARTED,
  STARTED_PICKUP,
  DONE_PICKUP,
};

// FOR DELIVERY STATUSES
export const FOR_DELIVERY_STATUSES = {
  STARTED,
  STARTED_DELIVERY,
  ORDER_ARRIVED,
};

// FOR CANCELLED STATUSES
export const CANCELLED_STATUSES = {
  CANCELLED,
  FOR_REFUND,
  REFUNDED,
};

export const TEST_ORDERS = [
  {
    orderNo: '2022001',
    cod: 'Paid',
    senderName: 'Justine Alcantara',
    senderAddress: 'Avocado St. Lupang Pari, Brgy. San Miguel, Pasig City, 1600',
    recipientName: 'Anthony Dela Vega',
    recipientAddress: '536 Calbayog St, Mandaluyong, 1550 Metro Manila',
    orderStatus: 'Assigning a Rider',
  },
];

export const PAST_ORDERS = [
  {
    orderNo: '2022001',
    paymentStatus: 'Paid',
    senderName: 'Justine Alcantara',
    senderAddress: 'Avocado St. Lupang Pari, Brgy. San Miguel, Pasig City, 1600',
    recipientName: 'Anthony Dela Vega',
    recipientAddress: '536 Calbayog St, Mandaluyong, 1550 Metro Manila',
    orderStatus: 'Completed',
  },
  {
    orderNo: '2022001',
    paymentStatus: 'Paid',
    senderName: 'Justine Alcantara',
    senderAddress: 'Avocado St. Lupang Pari, Brgy. San Miguel, Pasig City, 1600',
    recipientName: 'Anthony Dela Vega',
    recipientAddress: '536 Calbayog St, Mandaluyong, 1550 Metro Manila',
    orderStatus: 'Completed',
  },
  {
    orderNo: '2022001',
    paymentStatus: 'For Refund',
    senderName: 'Justine Alcantara',
    senderAddress: 'Avocado St. Lupang Pari, Brgy. San Miguel, Pasig City, 1600',
    recipientName: 'Anthony Dela Vega',
    recipientAddress: '536 Calbayog St, Mandaluyong, 1550 Metro Manila',
    orderStatus: 'Cancelled',
  },
];

export const ADDRESS = [
  {
    name: 'Anthony Dela Vega',
    contactNumber: '09123456789',
    email: 'anthony@gmail.com',
    address: '536 Calbayog St, Mandaluyong, 1550 Metro Manila',
    tag: 'Office',
  },
];

export const HOME = 'Home';
export const OFFICE = 'Office';
export const ADDRESS_TYPES = {
  HOME,
  OFFICE,
};

export const EDIT = 'Edit';
export const DELETE = 'Delete';
export const SET_AS_DEFAULT_PICKUP = 'Set as default pickup';
export const ADDRESS_ACTIONS = [SET_AS_DEFAULT_PICKUP, EDIT, DELETE];

export const MAPS_GEOCODE = 'https://maps.googleapis.com/maps/api/geocode/json';
export const MAPS_API = 'https://maps.googleapis.com/maps/api/js';

export const MAPS_API_KEY = 'AIzaSyByQ-nFn_8DisIBMfm4fRmY-tz_Rhm5Znk';

export const API_BASE_URL = 'https://us-central1-fleet-dev-ph.cloudfunctions.net';

export const zeroKm = {
  latitude: 14.609054,
  longitude: 121.022257,
};

export const PH = 'PH';
export const COUNTRIES = [{ value: PH, label: 'Philippines' }];

export const REGIONS = [
  {
    key: 'CAR',
    region_name: 'CAR',
    administrative_area_level_1: 'CAR',
  },
  {
    key: 'NCR',
    region_name: 'NCR',
    administrative_area_level_1: 'NCR',
  },
  {
    key: '01',
    region_name: 'REGION 1',
    administrative_area_level_1: 'Ilocos Region',
  },
  {
    key: '02',
    region_name: 'REGION 2',
    administrative_area_level_1: 'Cagayan Valley',
  },
  {
    key: '03',
    region_name: 'REGION 3',
    administrative_area_level_1: 'Central Luzon',
  },
  {
    key: '4A',
    region_name: 'REGION 4-A',
    administrative_area_level_1: 'Calabarzon',
  },
  {
    key: '4B',
    region_name: 'REGION 4-B',
    administrative_area_level_1: 'MIMAROPA',
  },
  {
    key: '05',
    region_name: 'REGION 5',
    administrative_area_level_1: 'Bicol',
  },
  {
    key: '06',
    region_name: 'REGION 6',
    administrative_area_level_1: 'Western Visayas',
  },
  {
    key: '07',
    region_name: 'REGION 7',
    administrative_area_level_1: 'Central Visayas',
  },
  {
    key: '08',
    region_name: 'REGION 8',
    administrative_area_level_1: 'Eastern Visayas',
  },
  {
    key: '09',
    region_name: 'REGION 9',
    administrative_area_level_1: 'Zamboanga Peninsula',
  },
  {
    key: '10',
    region_name: 'REGION 10',
    administrative_area_level_1: 'Northern Mindanao',
  },
  {
    key: '11',
    region_name: 'REGION 11',
    administrative_area_level_1: 'Davao Region',
  },
  {
    key: '12',
    region_name: 'REGION 12',
    administrative_area_level_1: 'SOCCSKSARGEN',
  },
  {
    key: '13',
    region_name: 'REGION 13',
    administrative_area_level_1: 'Caraga',
  },
  {
    key: 'BARMM',
    region_name: 'BARMM',
    administrative_area_level_1: 'ARMM',
  },
];

export const ILOCOS_NORTE = 'ILOCOS NORTE';
export const ILOCOS_SUR = 'ILOCOS SUR';
export const LA_UNION = 'LA UNION';
export const PROVINCIAL_LIST = {
  ILOCOS_NORTE,
  ILOCOS_SUR,
  LA_UNION,
};

export const CANCEL_ORDER_REASONS = [
  'No longer need the sevices.',
  'Vehicle cannot fit my goods.',
  'Driver was rude.',
  'Extra fees are too high.',
  'Driver was unresponsive.',
  'Driver asked to cancel.',
  'Change address details.',
  'Change order instruction details.',
  'Too long to have a driver.',
  'The driver does not communicate.',
  'Raining (Package cannot get wet)',
  'Wrong Pin',
];

export const REFUND_STATUS = {
  Failed: 'F',
  Unknown: 'U',
  Success: 'S',
};

export const CANCEL_STATUS = {
  Success: 'Success',
};

export const SUPPORT_CONTACTS = {
  helpDesk: 'help@lastmile.ph',
  submitTicket: 'https://ps.lastmile.ph/portal/en/kb/parcel',
  helpCenter: 'https://ps.lastmile.ph/portal/en/home',
  lastmileEmail: 'help@lastmile.ph',
  courierEmail: 'support@courier.ph',
  chatSupportLink:
    'https://salesiq.zoho.com/signaturesupport.ls?widgetcode=534859cd04b16c3b218622a95a50a89bc25bd29576e5f7aa5ffbe02d75e295b1',
  faq: 'https://ps.lastmile.ph/portal/en/kb/parcels',
};

export const BORZO_VEHICLES = {
  8: 'MOTORCYCLE',
  7: 'SEDAN',
};

export const COURIER_VEHICLES = {
  GRAB: {
    Vehicles: {
      Bike: 'MOTORCYCLE',
      CAR: 'SEDAN',
    },
  },
  MRSPEEDY: {
    Vehicles: {
      8: 'MOTORCYCLE',
      7: 'SEDAN',
    },
  },
  LALAMOVE: {
    Vehicles: {
      MOTORCYCLE: 'MOTORCYCLE',
      MPV: 'MPV',
      VAN1000: 'VAN_L300',
      SEDAN: 'SEDAN',
      VAN: 'VAN_MULTICAB',
      TRUCK550: 'TRUCK',
      LD_10WHEEL_TRUCK: 'LD_10W_TRUCK',
      '10WHEEL_TRUCK': 'TEN_WHEELER_TRUCK',
      TRUCK330: 'TRUCK330',
    },
  },
  TRANSPORTIFY: {
    Vehicles: {
      ECONOMY: 'SEDAN',
      L300: 'VAN_L300',
      'pickup truck': 'PICKUP_TRUCK',
      'long pickup': 'LONG_PICKUP_TRUCK',
      'economy extra space': 'MPV',
      'light van': 'VAN_MULTICAB',
      'canopy multicab': 'CANOPY_MULTICAB',
      'small pickup': 'SMALL_PICKUP',
      'closed van extra space': 'CLOSED_VAN_XS',
      'closed van': 'CLOSED_VAN',
      'pickup truck extra space': 'PICKUP_TRUCK_XS',
      'mini dump truck': 'MINI_DUMP_TRUCK',
      'wing van (40ft)': 'WING_VAN_40',
      'long pickup truck': 'SIX_WHEELER_FORWARD_TRUCK_XS',
      '6w fwd truck': 'SIX_WHEELER_FORWARD_TRUCK',
      VAN: 'TRUCK330',
      '10w wing van': 'TEN_WHEELER_WING_VAN_32',
    },
  },
};

export const QUEUED_VIA_API = 'Queued via API';

export const DPH_API_URL = {
  Production: 'https://us-central1-fleet-ph.cloudfunctions.net',
  Staging: 'https://us-central1-fleet-staging-2333a.cloudfunctions.net',
};

export const PARCELSIO_API_URL = {
  Production: 'https://api.myparcels.ph',
  Staging: 'https://staging-api.myparcels.ph',
};

export const DEFAULT_REASON = 'The user cancels the order due to some reason.';

export const ORDER_STATUS = {
  STARTED_PICKUP: 'Rider has been assigned',
  DONE_PICKUP: 'Rider is on the way to the delivery location',
  STARTED_DELIVERY: 'Rider is on the way to the delivery location',
  DONE: 'Order has been completed',
  CANCELLED: 'Order has been cancelled',
};

export const ORDER_SUB_HEADER = {
  STARTED_PICKUP: 'Please prepare the item for pickup.',
  DONE_PICKUP: 'The recipient will receive the item soon.',
  STARTED_DELIVERY: 'The recipient will receive the item soon.',
};

export const ENV_ZONE = {
  STAGING: {
    EMAIL: 'gcash@parcels.ph',
    PASSWORD: 'l5w2hnsi7',
    DPH_API: 'https://us-central1-fleet-staging-2333a.cloudfunctions.net',
    DPH_SERVICE_MATRIX: 'https://solutions.fleet.ph:9016',
    DPH_SERVICE_MATRIX_AUTH: '5C7E17C3698FC6767603D93ABE8540B3FA337ACADD13552081B96A687B552EC4',
    PARCELS_API: 'https://staging-api.myparcels.ph',
  },
  PRODUCTION: {
    EMAIL: 'gcash@parcels.ph',
    PASSWORD: 'qwzo6gd2n',
    DPH_API: 'https://us-central1-fleet-ph.cloudfunctions.net',
    DPH_SERVICE_MATRIX: 'https://solutions.fleet.ph:9017',
    DPH_SERVICE_MATRIX_AUTH: '3DB764C916E26CEBA17B6D5D1F9A0BF0C3B3F7E36DBB3DC330DC35E2D4B17C2B',
    PARCELS_API: 'https://api.myparcels.ph',
  },
};

export const ZONE = ENV_ZONE.PRODUCTION;

export const DEPLOYMENT = {
  ENV: ZONE.NAME,
  EMAIL: ZONE.EMAIL,
  PASSWORD: ZONE.PASSWORD,
  DPH_API: ZONE.DPH_API,
  DPH_SERVICE_MATRIX: ZONE.DPH_SERVICE_MATRIX,
  DPH_SERVICE_MATRIX_AUTH: ZONE.DPH_SERVICE_MATRIX_AUTH,
  PARCELS_API: ZONE.PARCELS_API,
};

export const serviceType = {
  onDemand: 'onDemand',
  scheduled: 'scheduled',
};

export const GCASH_APP_ID = '2170020135085685';
export const GCASH = 'GCASH';
