import { message } from 'antd';
import { MODULE_PATH } from '../../constants/Module';

export const capitalizeFirstChar = text => {
  const arr = text.toLowerCase().split(' ');

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }

  const str = arr.join(' ');
  return str;
};

export const handleReturnMyAccounts = navigate => {
  return navigate(MODULE_PATH.MY_ACCOUNTS.MAIN);
};

export const copyToClipBoard = linkRef => {
  linkRef.current.select();
  document.execCommand('copy');
  message.success('Successfully copied to clipboard!');
};

export const isNotEmpty = obj => {
  return Object.keys(obj).length !== 0;
};
