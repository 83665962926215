import {
  SET_ONDEMAND_ORDER_ID,
  SET_ONDEMAND_POINT_LOCATION,
  SET_ONDEMAND_COURIER,
  SET_ONDEMAND_PICK_UP,
  SET_ONDEMAND_DROP_OFF,
  SET_ONDEMAND_PICKUP_DATETIME,
  SET_VEHICLE_TYPE,
  SET_ONDEMAND_ITEM_TYPE,
  SET_ONDEMAND_ITEM_DESCRIPTION,
  SET_ONDEMAND_ITEM_PRICE,
  SET_ONDEMAND_PICKUP_TIME,
  SET_ONDEMAND_INITIAL_STATE,
  SET_PAYMENT_REQUEST_ID,
  SET_ONDEMAND_PAYMENT_REQS,
  SET_ONDEMAND_PAYMENT_RES,
  SET_LOCATION,
  SET_LOCATION_REFERENCE,
} from '../enums/types';

export const setOnDemandInitialState = () => ({
  type: SET_ONDEMAND_INITIAL_STATE,
});

export const setOnDemandPaymentReqs = paymentReqs => ({
  type: SET_ONDEMAND_PAYMENT_REQS,
  payload: paymentReqs,
});

export const setOnDemandPaymentRes = paymentRes => ({
  type: SET_ONDEMAND_PAYMENT_RES,
  payload: paymentRes,
});

export const setOrderId = orderId => ({
  type: SET_ONDEMAND_ORDER_ID,
  payload: orderId,
});

export const setPointLocation = pointLocation => ({
  type: SET_ONDEMAND_POINT_LOCATION,
  payload: pointLocation,
});

export const setCourier = courier => ({
  type: SET_ONDEMAND_COURIER,
  payload: courier,
});

export const setPickUp = pickUp => ({
  type: SET_ONDEMAND_PICK_UP,
  payload: pickUp,
});

export const setDropOff = dropOff => ({
  type: SET_ONDEMAND_DROP_OFF,
  payload: dropOff,
});

export const setPickupDateTime = pickupDateTime => ({
  type: SET_ONDEMAND_PICKUP_DATETIME,
  payload: pickupDateTime,
});

export const setVehicleType = vehicleType => ({
  type: SET_VEHICLE_TYPE,
  payload: vehicleType,
});

export const setOnDemandItemType = itemType => ({
  type: SET_ONDEMAND_ITEM_TYPE,
  payload: itemType,
});

export const setOnDemandItemDescription = itemDescription => ({
  type: SET_ONDEMAND_ITEM_DESCRIPTION,
  payload: itemDescription,
});

export const setItemPrice = itemPrice => ({
  type: SET_ONDEMAND_ITEM_PRICE,
  payload: itemPrice,
});

export const setPickUpTime = pickUpTime => ({
  type: SET_ONDEMAND_PICKUP_TIME,
  payload: pickUpTime,
});

export const setPaymentRequestId = paymentRequestId => ({
  type: SET_PAYMENT_REQUEST_ID,
  payload: paymentRequestId,
});

export const setLocation = location => ({
  type: SET_LOCATION,
  payload: location,
});

export const setLocationReference = locationReference => ({
  type: SET_LOCATION_REFERENCE,
  payload: locationReference,
});
