import moment from 'moment-timezone';
import { VEHICLE_TYPES } from '../utils/enums/DeliveryConstants';

export const ORDER_INITIAL_STATE = {
  pointLocation: '',
  courier: {},
  pickUpTime: 'PickUpNow',
  pickUp: {
    latitude: 0,
    longitude: 0,
    senderDetails: '',
    notesToRider: '',
    pickupAddress: '',
    barangay: '',
    pickupCity: '',
    province: '',
  },
  dropOff: {
    latitude: 0,
    longitude: 0,
    recipientDetails: '',
    notesToRider: '',
    deliveryAddress: '',
    barangay: '',
    deliveryCity: '',
    province: '',
  },
  // pickupDateTime: moment().valueOf(),
  pickupDateTime: null,
  location: '',
  locationReference: false,
  itemType: '',
  itemDescription: '',
  itemPrice: '0.00',
  vehicleType: VEHICLE_TYPES[0],
  defaultAddress: false,
  paymentRequestId: '',
  orderId: '',
  paymentReqs: {},
  paymentRes: {},
};

export const PICK_CARD = {
  NOW: 'PickUpNow',
  LATER: 'PickUpLater',
};
