import React, { useEffect, useState } from 'react';
import { Carousel } from 'antd';
import ParcelSVG from '../../../../shared/assets/svg/Parcels-icon-pink.svg';
import Banner from '../../../../shared/assets/images/Banners/Banner-3x.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router';
import { capitalizeName } from '../../../../shared/utils/helpers/purefunctions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { setBannersList } from '../../../../shared/redux/banner/actions';
import { MODULE_PATH } from '../../../../shared/constants/Module';

const Header = ({ propsUser, bannersList, setBannersList }) => {
  const storage = getStorage();
  const navigate = useNavigate();
  const goToMyAccounts = () => navigate(MODULE_PATH.MY_ACCOUNTS.MAIN);
  const goToSearchOrders = () => navigate(MODULE_PATH.ORDERS.SEARCH_ORDER);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const newBannersList = [...bannersList];
    if (newBannersList.length) {
      setBanners(newBannersList);
    } else {
      getListofBanners();
    }
  }, []);

  const getListofBanners = () => {
    const expectedNosOfBanner = 3;
    for (let i = 1; i <= expectedNosOfBanner; i++) {
      const storageRef = ref(storage, `GCASH/BANNERS/carousel-img${i}-pink.png`);
      getDownloadURL(storageRef)
        .then(url => {
          setBanners(prevbanner => [...prevbanner, url]);
          setBannersList(url);
        })
        .catch(error => {
          setBanners(prevbanner => [...prevbanner, Banner]);
          setBannersList(Banner);
        });
    }
  };
  const newBanner = banners.length ? banners : [Banner];
  newBanner.sort((a, b) => a.localeCompare(b));
  return (
    <>
      <div className="header-container">
        <div className="parcel-logo">
          <img src={ParcelSVG} alt="Parcel PH Logo" />
        </div>
        <div className="greetings-container">
          <span className="greeting">
            {`Hello ${capitalizeName(propsUser?.firstName.toLowerCase()) || ''}`},
          </span>
          <h4>Welcome to Parcels</h4>
        </div>
        <div className="search-icon-container" onClick={goToSearchOrders}>
          <FontAwesomeIcon className="search-icon" icon={solid('magnifying-glass')} />
        </div>
        <div className="search-icon-container" onClick={goToMyAccounts}>
          <FontAwesomeIcon className="search-icon" icon={solid('bars')} />
        </div>
      </div>
      <Carousel autoplay>
        {newBanner.map((img, idx) => {
          return (
            <div key={`container--${idx}`}>
              <img key={`banner--${idx}`} src={img} alt="Parcel Banner" className="parcel-banner" />
            </div>
          );
        })}
      </Carousel>
    </>
  );
};
function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setBannersList,
    },
    dispatch
  );
}
const mapStateToProps = state => ({
  propsUser: state.usersReducer.currentUser,
  bannersList: state.bannerReducer.bannersList,
});
export default connect(mapStateToProps, matchDispatchToProps)(Header);
