import {
  child,
  get,
  ref,
  set,
  push,
  update,
  remove,
  onValue,
  getDatabase,
} from 'firebase/database';
import { realtimeDb } from '../helpers/firebaseUtils';

import { cleanAdress } from '../helpers/purefunctions';

import moment from 'moment-timezone';
class AddressBookDAO {
  constructor() {
    this.addressPath = 'CLIENTS/GCASH/ADDRESS_BOOK';
  }

  saveAddress(userId, address) {
    const db = realtimeDb;
    const addressKey = push(child(ref(db), `${this.addressPath}/${userId}`)).key;

    set(ref(db, `${this.addressPath}/${userId}/${addressKey}`), {
      id: addressKey,

      addressType: address?.addressType || '',
      unitFloorHouse: address.unitFloorHouse,
      bldgStreet: address.bldgStreet,
      barangay: address?.barangay || '',
      city: address.city,
      province: address.province,
      postalCode: address?.postalCode || '',
      landmark: address?.landmark || '',
      latitude: address?.latitude || 14.609054,
      longitude: address?.longitude || 121.022257,
      address: `${address.unitFloorHouse}, ${address.bldgStreet}, ${cleanAdress(
        address?.province || null,
        address?.city || null,
        address?.barangay || null,
        address?.postalCode || null
      )}`,

      contactNo: address?.contactNo || '',
      fullName: address.fullName,
      emailAddress: address?.emailAddress || '',
      notesToRider: address?.notesToRider || '',

      createdAt: moment().valueOf(),
      updatedAt: moment().valueOf(),

      defaultPickUp: false,
      recently: false,
      isSavePlaces: true || false,
    });
  }

  saveLocation(userId, address) {
    console.log('address: ', address);
    const db = realtimeDb;
    const addressKey = push(child(ref(db), `${this.addressPath}/${userId}`)).key;
    const timestamp = Math.floor(Date.now() / 1000);

    set(ref(db, `${this.addressPath}/${userId}/${addressKey}`), {
      addressType: address?.addressType || '',
      address: address.location,
      city: address?.cityLocation,
      province: address?.provinceLocation,
      unitFloorHouse: address.unitFloorHouse,
      fullName: address.fullName,
      contactNo: address.contactNo,
      id: addressKey,
      latitude: address.latitude,
      longitude: address.longitude,
      isSavePlaces: true,
      timestamp,
    });
  }

  getAddressByUser(userId, fnCallback) {
    const dbRef = getDatabase();
    const addressRef = ref(dbRef, `${this.addressPath}/${userId}`);
    onValue(addressRef, snapshot => {
      if (snapshot.exists()) {
        const snaps = snapshot.val();
        const userAddress = [];
        for (const snap in snaps) {
          userAddress.push({
            id: snap,
            ...snaps[snap],
          });
        }
        fnCallback(userAddress);
      }
    });
  }

  editAddress(id, userId, address) {
    const dbRef = ref(realtimeDb, `${this.addressPath}/${userId}/${id}`);

    update(dbRef, {
      id,

      addressType: address?.addressType || '',
      unitFloorHouse: address.unitFloorHouse,
      bldgStreet: address.bldgStreet,
      barangay: address?.barangay || '',
      city: address.city,
      province: address.province,
      postalCode: address?.postalCode || '',
      landmark: address?.landmark || '',
      latitude: address?.latitude || 14.609054,
      longitude: address?.longitude || 121.022257,
      address: `${address.unitFloorHouse}, ${address.bldgStreet}, ${cleanAdress(
        address?.province || null,
        address?.city || null,
        address?.barangay || null,
        address?.postalCode || null
      )}`,

      contactNo: address?.contactNo || '',
      fullName: address.fullName,
      emailAddress: address?.emailAddress || '',
      notesToRider: address?.notesToRider || '',

      updatedAt: moment().valueOf(),

      defaultPickUp: false,
      recently: false,
    })
      .then(() => {})
      .catch(err => console.log('err', err));
  }

  deleteAddress(userId, id) {
    remove(ref(realtimeDb, `${this.addressPath}/${userId}/${id}`))
      .then(() => {})
      .catch(err => console.log(err));
  }
}

export default AddressBookDAO;
