import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setProduct } from '../../../../shared/redux/standard/actions';
import kgImage from '../../../../shared/assets/images/OndemandDelivery/kg.png';
import boxImage from '../../../../shared/assets/images/OndemandDelivery/box.png';
import StickyHeader from '../../../../shared/components/StickyHeader';
import { ICON, STANDARD_ICON } from '../../../../shared/utils/enums/AppIcons';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import {
  COURIER_PRODUCT_SIZES,
  PRODUCT_SIZES,
} from '../../../../shared/utils/enums/StandardDelivery';

const ProductList = ({ setProduct }) => {
  let navigate = useNavigate();

  const prevNavigate = {
    label: 'Choose Vehicle Type',
    imgPath: ICON.ON_DEMAND,
    path: MODULE_PATH.STANDARD.MAIN,
  };
  const ProductIcon = ({ value }) => {
    let productIcon = 'SMALL';
    const productSize = value.split(' ').join('_').toUpperCase();
    productIcon = COURIER_PRODUCT_SIZES[productSize];
    return <img src={STANDARD_ICON[productIcon]} alt={value} />;
  };

  const handleSelectProduct = data => {
    setProduct(data);
    navigate(MODULE_PATH.STANDARD.MAIN, { state: { isClicked: true } });
  };

  return (
    <div className="product__container">
      <StickyHeader title="Choose Product Size" onPrevNavigate={prevNavigate} />
      {PRODUCT_SIZES.map((data, index) => (
        <div className="product__list" key={index}>
          <div className="info" onClick={() => handleSelectProduct(data)}>
            <ProductIcon value={data.value} />
            <div className="product-info">
              <div className="header">{data.value}</div>
              <div className="details">
                <span>
                  <img src={kgImage} alt="kg" /> {data.description.weightText}
                </span>

                <span>
                  <img src={boxImage} alt="bag" /> {data.description.dimensionText}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setProduct,
    },
    dispatch
  );

export default connect(null, matchDispatchToProps)(ProductList);
