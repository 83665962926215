import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import ErrorCircle from '../../../../shared/assets/images/Process/payment-internal-error.png';
import RefundProcess from '../../../../shared/assets/images/Refund/refund-process-vector.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setTransaction } from '../../../../shared/redux/transaction/actions';
import moment from 'moment-timezone';
import { MODULE_PATH } from '../../../../shared/constants/Module';

const Error = ({ orderDetails, ongoingTarget, setTransaction }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const refundResultStatus = location?.state?.refundStatus;
  const attempt = location?.state?.attempt;

  const navigateToPrevPage = () => {
    const { refNo = '' } = orderDetails;
    let obj = {
      refNo,
      refundStatus: true,
      timeStamp: moment().valueOf(),
    };
    setTransaction({ ...ongoingTarget, [refNo]: obj });
    return navigate(MODULE_PATH.ORDERS.ORDER_DETAILS, { state: { refundStatus: refundResultStatus } });
  };

  const ProcessRefund = () => (
    <>
      <div className="refund-process-container">
        <img src={RefundProcess} alt="loader" className="refund-process" />
        <div className="text">
          <div>Refund in process</div>
        </div>
        <div className="label">
          <div>Your refund will be credited to your</div>
          <div>GCash account within 3-5 business days.</div>
        </div>
        <div className="process-btn" onClick={() => navigateToPrevPage()}>
          <FontAwesomeIcon icon={solid('arrow-left-long')} />
          <div className="btn-label">Back to Order Details</div>
        </div>
      </div>
    </>
  );

  const Error = () => (
    <>
      <div className="error-container">
        <img src={ErrorCircle} alt="loader" className="error-circle" />
        <div className="text">
          <div>Sorry, we are unable to process</div>
          <div>your refund at this time.</div>
        </div>
        <div className="label">
          <div>Looks like we're having an internal issue</div>
          <div>processing your refund. Please try again.</div>
        </div>
        <div className="error-btn" onClick={() => navigateToPrevPage()}>
          <FontAwesomeIcon icon={solid('arrow-left-long')} />
          <div className="btn-label">Back to Order Details</div>
        </div>
      </div>
    </>
  );

  const RefundProcessPage = () => {
    if (attempt === 2) {
      return <ProcessRefund />;
    }
    return <Error />;
  };
  return refundResultStatus === 'F' ? <ProcessRefund /> : <RefundProcessPage />;
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTransaction,
    },
    dispatch
  );
}

const mapStateToProps = state => ({
  orderDetails: state.homeReducer.targetOrder,
  ongoingTarget: state.transactionReducer.ongoingTarget,
});

export default connect(mapStateToProps, matchDispatchToProps)(Error);
