import React from 'react';

const StandardDeliveryDays = () => {
  return (
    <div className="standard__delivery__days">
      <span>Pickup</span> will take place within <span>1-3 business days </span>
      excluding holidays, and <span>delivery</span> will follow within
      <span> 2-3 business days.</span>
    </div>
  );
};

export default StandardDeliveryDays;
