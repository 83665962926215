import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPointLocation } from '../../../../shared/redux/standard/actions';
import startMapMarker from '../../../../shared/assets/images/OndemandDelivery/start_point_location.png';
import endMapMarker from '../../../../shared/assets/images/location/map-marker-alt.png';
import { ICON } from '../../../../shared/utils/enums/AppIcons';

import { isEmpty } from '../../../../shared/utils/helpers/purefunctions';

const PickupDropOff = ({ setPointLocation }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const standard = useSelector(state => state.standardReducer);
  const targetOrder = useSelector(state => state.homeReducer.targetOrder);

  const isConfirm = location?.state?.isClicked;

  const onClickLocation = location => {
    setPointLocation(location);
    navigate(MODULE_PATH.STANDARD.SENDER_DETAILS);
  };

  const { pickUp, dropOff } = standard;

  const hasSetPickUp = !isEmpty(pickUp?.senderDetails?.fullName);
  const hasSetDropOff = !isEmpty(dropOff?.recipientDetails?.fullName);
  const hasTargetOrder = !isEmpty(targetOrder);
  return (
    <>
      <div className="standard__form__input">
        <div className="map-markers">
          <img src={startMapMarker} alt="end-map-marker" />
          <div className="vertical-line"></div>
          <img src={endMapMarker} alt="start-map-marker" />
        </div>
        <div className="input-pickup-dropoff">
          <input
            className="pickup-input"
            placeholder="Pickup"
            defaultValue=""
            onClick={() => onClickLocation('PickUp')}
            value={pickUp?.senderDetails?.fullName || ''}
          />
          <input
            className="dropoff-input"
            placeholder="Drop-off"
            defaultValue=""
            onClick={() => onClickLocation('DropOff')}
            value={dropOff?.recipientDetails?.fullName || ''}
          />
        </div>
      </div>
    </>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPointLocation,
    },
    dispatch
  );

const mapStateToProps = state => ({
  pointLocation: state.onDemandReducer.pointLocation,
  view: state.homeReducer.view,
  dropOff: state.onDemandReducer.dropOff,
  orderDetails: state.homeReducer.targetOrder,
});

export default connect(mapStateToProps, matchDispatchToProps)(PickupDropOff);
