import React, { useEffect, useState } from 'react';
import { BackTop } from 'antd';

import { ICON } from '../../../../shared/utils/enums/AppIcons';
import { isEmpty } from '../../../../shared/utils/helpers/purefunctions';
import AddressBookLoader from '../../../../shared/elements/Loader/AddressBookLoader';

const List = ({ handleDelete, handleUpdate, data }) => {
  const [loader, setLoader] = useState(true);

  const Repeat = ({ numberOfTimes = 0, children }) => [...Array(numberOfTimes)].map(() => children);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
  }, []);

  const list = [...data];
  const sortByMilliseconds = (a, b) => {
    // 31556966399000 ASC
    // 1000 DESC
    const timeA = a?.updatedAt || a?.timestamp || 1000;
    const timeB = b?.updatedAt || a?.timestamp || 1000;

    return timeB - timeA;
  };

  list.sort(sortByMilliseconds);

  const AddressBook = ({ book, index }) => {
    const formatContactNumber = () => {
      return book?.createdAt ? book.contactNo : `0${book?.contactNo?.replace(/\s+/g, '')}`;
    };
    return (
      <div className="card-box" key={`${index}${book.id}`} onClick={() => handleUpdate(book)}>
        <div className="card-title" key={`${index}${book.id}-title`}>
          <span key={`${index}${book.id}-bookmark`}>
            <img src={ICON.BOOKMARK} />
          </span>
          <span key={`${index}${book.id}-type`}>{book?.addressType || book?.province}</span>
          <span onClick={e => handleDelete(e, book.id)} key={`${index}${book.id}-trash`}>
            <img src={ICON.TRASH} />
          </span>
        </div>
        <div className="card-address" key={`${index}${book.id}-address`}>
          <span key={`${index}${book.id}-pin`}>
            <img src={ICON.PIN} />
          </span>
          <span key={`${index}${book.id}-address`}>{book.address}</span>
        </div>
        <div className="card-contact" key={`${index}${book.id}-contact`}>
          <span key={`${index}${book.id}-contact`}>
            <img src={ICON.CONTACT} />
          </span>
          <span key={`${index}${book.id}-trash`}>{`${book.fullName} ${
            book.contactNo ? '• ' : ''
          }${formatContactNumber()}`}</span>
        </div>
      </div>
    );
  };
  return (
    <div id="content-container">
      <BackTop />
      <div className={`address-book-card-list ${loader ? 'no-scroll' : ''}`}>
        {loader && (
          <Repeat numberOfTimes={8}>
            <AddressBookLoader />
          </Repeat>
        )}

        {!loader &&
          !isEmpty(list) &&
          list.map((address, index) => (
            <AddressBook book={address} index={`${Number(index) + 1}`} />
          ))}
      </div>
    </div>
  );
};

export default List;
