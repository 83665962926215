import axios from 'axios';
import { DEPLOYMENT } from '../enums/AppConstants';

let sessionToken;
let apiKey;
let API = DEPLOYMENT.DPH_API;
let SERVICE_MATRIX = DEPLOYMENT.DPH_SERVICE_MATRIX;
let SERVICE_MATRIX_AUTH = DEPLOYMENT.DPH_SERVICE_MATRIX_AUTH;
let EMAIL = DEPLOYMENT.EMAIL;
let PASSWORD = DEPLOYMENT.PASSWORD;
class DPH {
  static async login() {
    const response = await axios({
      method: 'POST',
      url: `${API}/login`,
      headers: {
        Accept: 'application/json',
      },
      data: {
        email: EMAIL,
        password: PASSWORD,
      },
    });
    const { results } = response.data;
    sessionToken = sessionToken || results.sessionToken;
    apiKey = apiKey || results.apiKey;
  }

  static async getOnDemandQuotation(data) {
    try {
      await this.login();
      const getQuotation = await axios({
        method: 'POST',
        url: `${API}/v3/getOnDemandQuotation`,
        headers: {
          Accept: 'application/json',
          'auth-token': sessionToken,
        },
        data,
        params: {
          apiKey,
        },
      });
      console.log('getQuote Data', data, sessionToken);
      return getQuotation.data.result;
    } catch (err) {
      return err.response;
    }
  }

  static async getAvailableScheduledPartner(data) {
    try {
      await this.login();
      const getQuotation = await axios({
        method: 'POST',
        url: `${API}/v3/getAvailablePartners`,
        headers: {
          Accept: 'application/json',
          'auth-token': sessionToken,
        },
        data,
        params: {
          apiKey,
        },
      });
      const couriers = ['QUADX', 'NINJAVAN', 'XDE', 'CLIQNSHIP'];
      const courierList = getQuotation.data.result;
      const filterCouriers = courierList.filter(courier => couriers.includes(courier.id));
      return { filterCouriers, success: getQuotation.data.success };
    } catch (err) {
      return err.response;
    }
  }

  static async getByPartners(data) {
    try {
      await this.login();
      const result = await axios({
        method: 'POST',
        url: `${SERVICE_MATRIX}/serviceMatrix/getByPartners`,
        headers: {
          Accept: 'application/json',
          Authorization: SERVICE_MATRIX_AUTH,
        },
        data,
        params: {
          apiKey,
        },
      });
      console.log('result data:', result.data);
      return result.data;
    } catch (err) {
      return err.message;
    }
  }

  static async createOrder(data, partnerId) {
    await this.login();
    try {
      const response = await axios({
        method: 'POST',
        url: `${API}/v3/createPost`,
        headers: {
          Accept: 'application/json',
          'auth-token': sessionToken,
        },
        data,
        params: {
          partnerId,
          apiKey,
        },
      });
      return response.data;
    } catch (err) {
      return err.response.data;
    }
  }

  //fetchPost//ORDERs

  static async fetchPosts() {
    await this.login();
    try {
      const response = await axios({
        method: 'GET',
        url: `${API}/getPost`,
        headers: {
          Accept: 'application/json',
          'auth-token': sessionToken,
        },
        params: {
          apiKey,
          partnerId: 'GCASH',
          type: 'post',
        },
      });
      return response.data.result;
    } catch (err) {
      return err.response;
    }
  }

  static async getPartners() {
    await this.login();
    try {
      const response = await axios({
        method: 'GET',
        url: `${API}/getPartners`,
        headers: {
          'auth-token': sessionToken,
        },
        params: {
          apiKey,
        },
      });

      return response.data.results;
    } catch (err) {
      return err.response;
    }
  }

  static async cancelPost(data) {
    await this.login();

    try {
      const response = await axios({
        method: 'POST',
        url: `${API}/v3/cancelPost`,
        headers: {
          Accept: 'application/json',
          'auth-token': sessionToken,
        },
        params: {
          apiKey,
        },
        data,
      });
      return response.data;
    } catch (err) {
      return err.response;
    }
  }
}

export default DPH;
