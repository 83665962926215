import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { Input, message, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { COURIER_IMAGES } from '../../../shared/utils/enums/CourierImages';
import DPHDAO from '../../../shared/utils/dao/DPH';
import { TRADE_PAY } from '../../../shared/utils/enums/GCashResponse';
import PaymentDAO from '../../../shared/utils/dao/PaymentDAO';
import { setPaymentRequestId, setCourier } from '../../../shared/redux/onDemand/actions';
import { setStandardPaymentRequestId } from '../../../shared/redux/standard/actions';
import { setLoader } from '../../../shared/redux/home/actions';
import {
  phraseFormat,
  isEmpty,
  formatBills,
  capitalizeName,
  createOrderPayload,
  formatUnderscoredText,
} from '../../../shared/utils/helpers/purefunctions';
import StickyHeader from '../../../shared/components/StickyHeader';
import { MODULE_PATH } from '../../../shared/constants/Module';
import { ICON } from '../../../shared/utils/enums/AppIcons';
import FooterBtn from '../../../shared/components/FooterBtn';
import { STANDARD_COURIERS } from '../../../shared/utils/enums/StandardDelivery';
import { get } from 'lodash';
import { BORZO, MRSPEEDY } from '../../../shared/utils/enums/Providers';

const confirm = Modal.confirm;

const PaymentSummary = ({
  onDemandcourier,
  standardcourier,
  vehicleType,
  setLoader,
  onDemandProps,
  standardProps,
  userInfo,
  setOnDemandPaymentRequestId,
  setStandardPaymentRequestId,
  onDemandPaymentRequestId,
  standardPaymentRequestId,
  setCourier,
}) => {
  let navigate = useNavigate();
  const [requestId, setRequestId] = useState('');
  const [declaredItemPrice, setDeclaredItemPrice] = useState('');

  const { view, targetOrder } = useSelector(state => state.homeReducer);

  const isStandard = view === MODULE_PATH.STANDARD.MAIN;
  const isScheduledServiceType = STANDARD_COURIERS.includes(targetOrder.courierId);

  const courier = isStandard ? standardcourier : onDemandcourier;
  const serviceType =
    isScheduledServiceType || isStandard ? standardProps.product.value : vehicleType.vehicleType;

  const weight = get(standardProps.product, 'description.weightText');
  const senderDetails = standardProps.pickUp.senderDetails;
  const recipientDetails = standardProps.dropOff.recipientDetails;

  const pattern = /\d+/g;
  const [productWeight] = weight.match(pattern);

  const standardDeclaredValue = (1.5 / 100) * declaredItemPrice;
  const standardShippingFee = standardcourier.rate + standardDeclaredValue;

  const rate = isStandard
    ? formatBills(Number(standardShippingFee || 0) + 5)
    : formatBills(Number(onDemandcourier.rate || 0) + 5);

  const scheduledPartnersPayload = {
    serviceType: 'scheduled',
    pickup: {
      province: senderDetails.province,
      city: senderDetails.city,
      barangay: senderDetails.barangay,
    },
    delivery: {
      province: recipientDetails.province,
      city: recipientDetails.city,
      barangay: recipientDetails.barangay,
    },
    dimensions: {
      length: 0,
      width: 0,
      height: 0,
    },
    weight: Number(productWeight),
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (onDemandPaymentRequestId) {
      setRequestId(onDemandPaymentRequestId);
    }
    if (standardPaymentRequestId) {
      setRequestId(standardPaymentRequestId);
    }
  }, []);

  const payGCash = async () => {
    await proceedPayWithGCash();
    setLoader(false);
  };

  const payWithGCash = async () => {
    setLoader(true);

    if (isStandard) {
      const standard = await DPHDAO.getAvailableScheduledPartner(scheduledPartnersPayload);
      if (standard) {
        const filteredStandardCourier = standard.filterCouriers.filter(
          data => data.id === standardcourier.courier
        );

        const [productInfo] = filteredStandardCourier
          .map(data =>
            data.products.filter(
              productDetails => productDetails.size === standardProps.product.value.charAt(0)
            )
          )
          .flat();

        if (filteredStandardCourier.length) {
          if (productInfo.price !== standardcourier.rate) {
            const newRate = productInfo.price;
            setLoader(false);
            confirm({
              className: 'discard-order',
              okText: 'Yes',
              cancelText: 'No',
              content: `Courier rate has changed, from P ${formatBills(
                standardcourier.rate
              )} to P ${formatBills(newRate)}. Would you like to proceed?`,
              onOk: async () => {
                setCourier({ ...standardcourier, rate: newRate });
              },
              onCancel: () => {
                console.log('Cancel');
              },
            });
          } else {
            await payGCash();
          }
        } else {
          await payGCash();
        }
      } else {
        setLoader(false);
        return message.error('Please retry payment.');
      }
    } else {
      const onDemand = await DPHDAO.getOnDemandQuotation({
        couriers: [onDemandcourier.courier.toUpperCase()],
        ...formatReqsData(),
      });

      if (onDemand) {
        const getQuote = onDemand.filter(quote => quote.courier === onDemandcourier.courier);
        if (getQuote.length) {
          if (getQuote[0].rate !== onDemandcourier.rate) {
            const newRate = Number(getQuote[0].rate || 0);
            setLoader(false);
            confirm({
              className: 'discard-order',
              okText: 'Yes',
              cancelText: 'No',
              content: `Courier rate has changed, from P ${formatBills(
                onDemandcourier.rate
              )} to P ${formatBills(newRate)}. Would you like to proceed?`,
              onOk: async () => {
                setCourier({ ...onDemandcourier, rate: newRate });
              },
              onCancel: () => {
                console.log('Cancel');
              },
            });
          } else {
            await payGCash();
          }
        } else {
          await payGCash();
        }
      } else {
        setLoader(false);
        return message.error('Please retry payment.');
      }
    }
  };

  const formatReqsData = () => {
    const { pickUp, dropOff, vehicleType, pickupDateTime } = onDemandProps;
    const dimension = (vehicleType.description.dimensionText.match(/\d+/g) || []).map(n =>
      parseInt(n)
    );
    const weight = (vehicleType.description.weightText.match(/\d+/g) || []).map(n => parseInt(n));
    return {
      pickup_province: !isEmpty(pickUp.senderDetails?.province)
        ? pickUp.senderDetails.province
        : 'METRO MANILA',
      data: {
        localeKey: 'en_PH',
        pickup: {
          name: pickUp.senderDetails.fullName,
          phone: String(pickUp.senderDetails.contactNo),
          address: pickUp.address,
          lat: pickUp.latitude,
          long: pickUp.longitude,
          email: pickUp.senderDetails.emailAddress || userInfo.currentUser.email,
        },
        delivery: {
          name: dropOff.recipientDetails.fullName,
          phone: String(dropOff.recipientDetails.contactNo),
          address: dropOff.address,
          lat: dropOff.latitude,
          long: dropOff.longitude,
        },
        codAmount: 0,
        scheduleAt: pickupDateTime,
        vehicleType: vehicleType.vehicleType.toUpperCase(),
        dimensions: {
          depth: dimension[2],
          width: dimension[1],
          height: dimension[2],
          weight: weight[0],
        },
      },
    };
  };
  const proceedPayWithGCash = async () => {
    let paymentAPI = {};

    const userId = userInfo.currentUser.id;
    const paymentAmount = {
      currency: 'PHP',
      value: String(rate).split('.').join('') || '000',
    };

    if (isStandard) {
      paymentAPI = {
        userId,
        paymentAmount,
        paymentRequestId: requestId,
        refNo: standardProps?.orderId,
        paymentOrderTitle: standardProps?.itemType || standardProps?.itemDescription,
      };
    } else {
      paymentAPI = {
        userId,
        paymentAmount,
        paymentRequestId: requestId,
        refNo: onDemandProps?.orderId,
        paymentOrderTitle: onDemandProps?.itemType || onDemandProps?.itemDescription,
      };
    }

    /* call payments/pay via parcelsIO first to get the paymentRequestId and payment status */
    const PaymentDao = new PaymentDAO();
    const paidBooking = await PaymentDao.gcashPayment(paymentAPI);

    if (paidBooking.success) {
      /* create booking order first so once notify url response, booking order exist */
      const createdPaymentRequestId = paidBooking.result.paymentRequestId;
      const createdPaymentId = paidBooking.result.paymentId;
      const paymentUrl = paidBooking.result.actionForm.redirectionUrl;

      setRequestId(createdPaymentRequestId);
      setOnDemandPaymentRequestId(createdPaymentRequestId);
      setStandardPaymentRequestId(createdPaymentRequestId);

      my.tradePay({
        paymentUrl,
        success: function (res) {
          if (['9000', '8000', '6004', '4000'].includes(String(res?.resultCode))) {
            return navigate(MODULE_PATH.PAYMENT.PAYMENT_PROCESS, {
              state: {
                gcash: {
                  paymentRequestId: createdPaymentRequestId,
                  paymentId: createdPaymentId,
                  paymentAmount,
                },
                refNo: paymentAPI.refNo,
              },
            });
          }
          setLoader(false);
        },
        fail: function (res) {
          setLoader(false);
          message.info(
            TRADE_PAY[res?.resultCode] || `Failed: Processing Error ${res?.resultCode || ''}`
          );
        },
      });
    } else {
      message.info(`Failed: ${paidBooking.message}`);
    }
  };
  const courierName = courier.courier === MRSPEEDY ? BORZO : courier.courier.toLowerCase();
  const formattedCourierName = phraseFormat(String(courierName).toLowerCase());

  const prevNavigate = {
    path: isStandard ? MODULE_PATH.STANDARD.MAIN : MODULE_PATH.ON_DEMAND.MAIN,
    state: {},
  };
  const deliveryLabel = isStandard ? 'Standard Delivery' : 'On-demand Delivery';
  const nextNavigate = { label: deliveryLabel, imgPath: ICON.ON_DEMAND, path: '/' };

  const handleBlur = () => {
    if (!isNaN(declaredItemPrice)) {
      let formattedValue = parseFloat(declaredItemPrice).toFixed(2);
      // addCommaThousandAmount = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      setDeclaredItemPrice(formattedValue);
    } else {
      message.error('Invalid input');
    }
  };

  return (
    <div className="payment__summary">
      <StickyHeader
        onPrevNavigate={prevNavigate}
        onNextNavigate={nextNavigate}
        title="Payment Summary"
      />
      <div className="body">
        <div className="row-1">
          <div className="column-1">
            <div className="title">Declared Item Price</div>
            <Input
              onBlur={() => handleBlur()}
              bordered={false}
              onChange={e => setDeclaredItemPrice(e.target.value)}
              value={declaredItemPrice}
              prefix={<FontAwesomeIcon icon={solid('peso-sign')} style={{ color: '#e94e75;' }} />}
              placeholder="0.00"
            />
          </div>
          <div className="column-2">
            <div className="title">Item Insurance</div>
            <div className="description">
              Item insurance is automatically applied based on the selected courier.
            </div>
            <div className="courier-insurance">
              <span>{`${capitalizeName(courierName)} Insurance`}</span>
            </div>
          </div>
        </div>
        <div className="row-2">
          <span>Courier to complete your order:</span>
          <div className="courier-info">
            <img src={COURIER_IMAGES[courier.courier]} alt="courier img" />
            <div className="service-type">
              <span>{capitalizeName(courierName)}</span>
              <span>
                {serviceType === 'MPV' ? serviceType : formatUnderscoredText(serviceType)}
              </span>
            </div>
          </div>
          <div className="footer">
            <FontAwesomeIcon icon={solid('shield-halved')} />
            <span>{`Your item is protected with ${capitalizeName(courierName)} insurance`}</span>
          </div>
        </div>
        <div className="row-3">
          <div className="shipping-fee">
            <span>Shipping Fee</span>
            <span>{isStandard ? formatBills(standardShippingFee) : formatBills(courier.rate)}</span>
          </div>
          <div className="convinience-fee">
            <span>Convenience Fee</span>
            <span>5.00</span>
          </div>
          <div className="total">
            <span>Total</span>
            <span>{`P ${rate}`}</span>
          </div>
        </div>
        <div className="row-4">
          <div className="description">Your GCash wallet will be charged upon booking</div>
          <img src={ICON.GCASH} alt="gcash logo" />
        </div>
        <FooterBtn label="Pay With GCash" onClick={() => payWithGCash()} />
      </div>
    </div>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLoader,
      setOnDemandPaymentRequestId: setPaymentRequestId,
      setStandardPaymentRequestId,
      setCourier,
    },
    dispatch
  );
}

const mapStateToProps = state => ({
  onDemandcourier: state.onDemandReducer.courier,
  standardcourier: state.standardReducer.courier,
  vehicleType: state.onDemandReducer.vehicleType,
  onDemandPaymentRequestId: state.onDemandReducer.paymentRequestId,
  standardPaymentRequestId: state.standardReducer.paymentRequestId,
  onDemandProps: state.onDemandReducer,
  standardProps: state.standardReducer,
  userInfo: state.usersReducer,
});
export default connect(mapStateToProps, matchDispatchToProps)(PaymentSummary);
