import axios from 'axios';
import { child, push, ref, set } from 'firebase/database';
import { realtimeDb } from '../helpers/firebaseUtils';
import { DEPLOYMENT } from '../enums/AppConstants';
import { message } from 'antd';

class Login {
  constructor() {
    this.customerPath = `CLIENTS/GCASH/PARCELS_USERS`;
    this.gcashAuthPath = `CLIENTS/GCASH/AUTH`;
    this.AUTHORIZATION = '163e3ebd6ea01de274904864e42e2776';
    this.API = DEPLOYMENT.PARCELS_API;
  }

  async getAuthCode(authCode) {
    try {
      const response = await axios({
        method: 'POST',
        url: `${this.API}/v1/authorization`,
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${this.AUTHORIZATION}`,
        },
        data: {
          authCode,
        },
      });

      return response.data.result.userInfo;
    } catch (error) {
      message.info(`v1 authorization: ${JSON.stringify(error)}`);
      console.log('err', error);
      return error;
    }
  }

  async saveUser(userId, userInfo) {
    const db = realtimeDb;
    let gcashNumber = '';
    let email = '';
    let firstName = '';
    let lastName = '';

    // v1
    if (userInfo?.extendInfo) {
      let extendInfo = JSON.parse(userInfo.extendInfo) || {};
      const { EMAIL_ADDRESS = '', GCASH_NUMBER = '' } = extendInfo;
      const userName = userInfo?.userName || {};

      gcashNumber = String(GCASH_NUMBER);
      email = String(EMAIL_ADDRESS);
      firstName = String(userName?.firstName || '');
      lastName = String(userName?.lastName || '');
    }

    // v2
    if (userInfo?.basicInfo) {
      const basicInfo = userInfo.basicInfo;
      gcashNumber = String(basicInfo?.mobileNumber || '');
      email = String(basicInfo?.emailAddress || '');
      firstName = String(basicInfo?.firstName || '');
      lastName = String(basicInfo?.lastName || '');
    }

    const currentUser = {
      firstName,
      lastName,
      gcashNumber,
      email,
      id: userId,
    };

    await set(ref(db, `${this.customerPath}/${userId}`), currentUser);

    return currentUser;
  }

  async saveGcashAuthCode(authCode) {
    const db = realtimeDb;
    const authCodeKey = push(child(ref(db), `${this.gcashAuthPath}/`)).key;

    await set(ref(db, `${this.gcashAuthPath}/${authCodeKey}`), {
      authCode,
    });

    return authCode;
  }
}

export default Login;
