import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import kgImage from '../assets/images/OndemandDelivery/kg.png';
import boxImage from '../assets/images/OndemandDelivery/box.png';
import StickyHeader from './StickyHeader';
import { ICON, ON_DEMAND_ICON } from '../utils/enums/AppIcons';
import { MODULE_PATH } from '../constants/Module';
import { COURIER_VEHICLE_TYPES } from '../utils/enums/Providers';
import { setCourier } from '../redux/onDemand/actions';
import DPHDAO from '../utils/dao/DPH';

import { BORZO_VEHICLES, COURIER_VEHICLES } from '../utils/enums/AppConstants';
import { COURIERS } from '../utils/enums/DeliveryConstants';
import {
  formatBills,
  filterCourierPartner,
  capitalizeName,
  isEmpty,
  formatUnderscoredText,
} from '../utils/helpers/purefunctions';
import { COURIER_IMAGES } from '../utils/enums/CourierImages';
import { Skeleton, Space, message } from 'antd';
import EMPTY_COURIER from '../assets/svg/courier_illustration.svg';

const CouriersList = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const selectedVehicle = useSelector(state => state.onDemandReducer.vehicleType);
  const onDemandProps = useSelector(state => state.onDemandReducer);

  const [onDemandCouriers, setOnDemandCouriers] = useState([]);
  const [partners, setPartners] = useState([]);

  const prevNavigate = {
    label: 'Choose Courier',
    imgPath: ICON.ON_DEMAND,
    path: MODULE_PATH.ON_DEMAND.MAIN,
  };

  const handleSelectCourier = data => {
    if (!data.rate) return;
    dispatch(setCourier(data));
    navigate(MODULE_PATH.ON_DEMAND.MAIN, { state: location?.state });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [providerPartners, onDemand] = await Promise.all([
          DPHDAO.getPartners(),
          partners.length > 0
            ? DPHDAO.getOnDemandQuotation(formatReqsData(filterCourierPartner(partners, COURIERS)))
            : Promise.resolve([]),
        ]);

        setPartners(providerPartners);
        setOnDemandCouriers(onDemand);
      } catch (error) {
        console.log('Error fetching data:', error);
      }
    };
    if (onDemandCouriers.length === 0) {
      fetchData();
    }
  }, [partners]);

  const formatReqsData = couriers => {
    const { pickUp, dropOff, vehicleType, pickupDateTime } = onDemandProps;
    const dimension = (vehicleType.description.dimensionText.match(/\d+/g) || []).map(n =>
      parseInt(n)
    );
    const weight = (vehicleType.description.weightText.match(/\d+/g) || []).map(n => parseInt(n));

    return {
      couriers,
      pickup_province: !isEmpty(pickUp.senderDetails?.province)
        ? pickUp.senderDetails.province
        : pickUp.province,
      data: {
        localeKey: 'en_PH',
        pickup: {
          name: pickUp.senderDetails.fullName,
          phone: String(pickUp.senderDetails.contactNo),
          address: pickUp.address,
          lat: pickUp.latitude,
          long: pickUp.longitude,
        },
        delivery: {
          name: dropOff.recipientDetails.fullName,
          phone: String(dropOff.recipientDetails.contactNo),
          address: dropOff.address,
          lat: dropOff.latitude,
          long: dropOff.longitude,
        },
        codAmount: 0,
        scheduleAt: pickupDateTime,
        vehicleType: vehicleType.vehicleType.toUpperCase(),
        dimensions: {
          depth: dimension[0],
          width: dimension[1],
          height: dimension[2] || 0,
          weight: weight[0],
        },
      },
    };
  };

  const VehicleIcon = ({ value }) => {
    let vehicleIcon = 'MOTORCYCLE';
    vehicleIcon = COURIER_VEHICLE_TYPES[value.toLowerCase()];
    return <img src={ON_DEMAND_ICON[vehicleIcon]} alt={value} />;
  };

  const SkeletonLoaders = ({ count }) => (
    <>
      {Array.from({ length: count }, (_, i) => (
        <div className="courier-list loader" key={i}>
          <div className="courier-info">
            <Space>
              <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
              <Skeleton.Input
                block={true}
                active={true}
                size={'small'}
                style={{ width: '220px' }}
              />
            </Space>
          </div>
          <div className="courier-vehicle-type">
            <Skeleton.Avatar active={true} size={'small'} shape={'circle'} />
            <Space>
              <Skeleton.Input
                block={true}
                active={true}
                size={'small'}
                style={{ width: '250px' }}
              />
            </Space>
          </div>
        </div>
      ))}
    </>
  );

  const CourierList = ({ ondemandQoute }) => {
    const filteredOnDemandCouriers = ondemandQoute.filter(courier => courier?.rate);

    if (filteredOnDemandCouriers.length === 0) {
      return (
        <div className="empty-courier">
          <div className="empty-courier-container">
            <div className="image">
              <img src={EMPTY_COURIER} alt="empty courier" />
            </div>
            <div className="content">No available couriers on your selected vehicle</div>
            <div className="sub-content">Please select another Vehicle Type</div>
          </div>
        </div>
      );
    }
    return (
      filteredOnDemandCouriers &&
      filteredOnDemandCouriers.map((order, index) => {
        let vehicleType;
        switch (order.courier) {
          case 'MRSPEEDY':
            vehicleType = BORZO_VEHICLES[order.vehicleType];
            break;
          case 'LALAMOVE':
            vehicleType = COURIER_VEHICLES.LALAMOVE.Vehicles[order.vehicleType];
            break;
          default:
            vehicleType = order.vehicleType;
        }
        const newVehicleType =
          order.vehicleType === 'MPV' ? vehicleType : formatUnderscoredText(vehicleType);
        return (
          <>
            <div
              className={`courier-list ${!order.rate ? 'error' : ''}`}
              key={index}
              onClick={() => handleSelectCourier(order)}
            >
              <div className="courier-info">
                <div className="courier-name">
                  <img src={COURIER_IMAGES[order.courier]} alt="Courier log" />
                  <span>
                    {order.courier.toLowerCase() === 'mrspeedy'
                      ? capitalizeName('borzo')
                      : capitalizeName(order.courier.toLowerCase())}
                  </span>
                </div>
                {order.error ? '' : <span>{`P ${formatBills(order.rate)}`}</span>}
              </div>
              <div className="courier-vehicle-type">
                {order.error ? (
                  <span>{order.error}</span>
                ) : (
                  <>
                    <VehicleIcon value={selectedVehicle.value} />
                    <div className="vehicle-info">
                      <span>{newVehicleType}</span>
                      <div className="dimensions">
                        <img src={kgImage} alt="kg" />
                        <span>{selectedVehicle.description.weightText}</span>
                        <img src={boxImage} alt="bag" />
                        <span>{selectedVehicle.description.dimensionText}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        );
      })
    );
  };
  console.log('onDemandCouriers: ', onDemandCouriers);
  return (
    <div className="courier__list__container">
      <StickyHeader title="Choose Courier" onPrevNavigate={prevNavigate} />
      {!onDemandCouriers.length ? (
        <SkeletonLoaders count={3} />
      ) : (
        <CourierList ondemandQoute={onDemandCouriers} />
      )}
    </div>
  );
};

export default CouriersList;
