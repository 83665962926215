import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import SilverMapStyle from '../../../../shared/utils/enums/SilverMapStyle.json';

import { zeroKm } from '../../../../shared/utils/enums/AppConstants';
import { ICON } from '../../../../shared/utils/enums/AppIcons';

const Maps = ({ coordinates, onSetPinLocation, onMarkerDragEnd }) => {
  const [center, setCenter] = useState(zeroKm);
  const refMap = useRef(null);

  useEffect(() => {
    const position = {
      lat: coordinates?.latitude || coordinates?.lat,
      lng: coordinates?.longitude || coordinates?.lng,
    };
    setCenter(position);
  }, [coordinates]);

  return (
    <GoogleMap
      ref={refMap}
      defaultZoom={13}
      center={center}
      defaultCenter={center}
      defaultOptions={{
        styles: SilverMapStyle,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
      }}
      className="google-map-container"
    >
      <Marker
        onDragEnd={onMarkerDragEnd}
        draggable={true}
        position={center}
        icon={{
          url: ICON.MARKER,
          scaledSize: new window.google.maps.Size(40, 40),
        }}
      />
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(Maps));
