import React from 'react';

const AssignRiderLoader = () => {
  return (
    <div className="assigning__rider__loader__container">
      <div className="assigning__rider__loader"></div>
      <div className="assigning__rider__loader"></div>
      <div className="assigning__rider__loader"></div>
      <div className="assigning__rider__loader"></div>
    </div>
  );
};

export default AssignRiderLoader;
