import React from 'react';
import { ICON } from '../utils/enums/AppIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { COURIER_IMAGES } from '../utils/enums/CourierImages';
import { MRSPEEDY_VEHICLE_TYPE } from '../utils/enums/Providers';
import {
  capitalizeName,
  formatAmount,
  isEmpty,
  paymentStatus,
  showConfirmationModal,
} from '../utils/helpers/purefunctions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setFormView, setTargetOrder } from '../redux/home/actions';
import { MODULE_PATH } from '../constants/Module';
import { ORDER_STATUSES } from '../utils/enums/AppConstants';
import OrderControlBtn from './OrderControlBtn';
import { message } from 'antd';
import { STANDARD_COURIERS } from '../utils/enums/StandardDelivery';

const OrdersCard = ({ orders }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const targetOrder = useSelector(state => state.homeReducer.targetOrder);
  const deliveryDetails = targetOrder.meta ? JSON.parse(targetOrder.meta) : {};

  const onClickSelectOrder = order => {
    dispatch(setTargetOrder(order));
    navigate(MODULE_PATH.ORDERS.ORDER_DETAILS, {
      state: { link: MODULE_PATH.ORDERS.VIEW_ALL_ORDERS },
    });
  };

  const onClickForRefund = () => {
    navigate(MODULE_PATH.ORDERS.CANCEL_ORDER);
  };

  const handleClickBookAgain = (order, isStandardDelivery) => {
    dispatch(setTargetOrder(order));
    if (isStandardDelivery) {
      navigate(MODULE_PATH.STANDARD.MAIN);
      setFormView(MODULE_PATH.STANDARD.MAIN);
      return;
    }
    setFormView(MODULE_PATH.ON_DEMAND.MAIN);
    navigate(MODULE_PATH.ON_DEMAND.MAIN);
    return;
  };

  const url = deliveryDetails?.trackingUrl;

  const handleOpenLink = async () => {
    if (!url) {
      message.error('Order has no tracking URL');
      return;
    }
    showConfirmationModal(url);
  };

  return (
    <div>
      {orders.map(order => {
        const { refNo, metaFields, gcash, courierId, status, subStatus } = order;
        const deliveryDetails = order.meta ? JSON.parse(order.meta) : {};
        const isNotForPending = ['CANCELLED', 'DONE'].includes(order.status);
        const isStandardDelivery = STANDARD_COURIERS.includes(order.courierId);
        const productSize = !isEmpty(order?.product) ? capitalizeName(order?.product?.size) : '';
        const serviceType =
          (metaFields?.serviceType &&
            ([7, 8].includes(metaFields?.serviceType)
              ? MRSPEEDY_VEHICLE_TYPE[metaFields?.serviceType]
              : capitalizeName(metaFields?.serviceType))) ||
          (metaFields?.grabVehicleType && capitalizeName(metaFields?.grabVehicleType)) ||
          MRSPEEDY_VEHICLE_TYPE[metaFields.mrSpeedyVehicle];
        const deliveryServiceType = isStandardDelivery ? productSize : serviceType;

        return (
          <div key={order.refNo} className="orders__card">
            <div className="row-1" onClick={() => onClickSelectOrder(order)}>
              <div className="column-1">
                <img src={ICON.ON_DEMAND} alt="on demand" />
                <div className="label">
                  <span>{isStandardDelivery ? 'Standard Delivery' : 'On-Demand Delivery'}</span>
                  <span>{`Order No. ${refNo}`}</span>
                </div>
              </div>
              <div className="column-2">
                {!isNotForPending ? <FontAwesomeIcon icon={solid('circle')} /> : ''}
                <div className={`status ${ORDER_STATUSES[status]}`}>
                  {ORDER_STATUSES[status] || ORDER_STATUSES[subStatus]}
                </div>
                <FontAwesomeIcon icon={solid('angle-right')} />
              </div>
            </div>
            <div className="row-2" onClick={() => onClickSelectOrder(order)}>
              <div className="icons">
                <FontAwesomeIcon icon={solid('circle-dot')} />
                <div className="vertical-line"></div>
                <FontAwesomeIcon icon={solid('location-dot')} />
              </div>
              <div className="location-details">
                <div className="from">{order.customerName}</div>
                <div className="to">{deliveryDetails.customerName}</div>
              </div>
            </div>
            <div className="row-3">
              <div className="payment-details">
                <img src={COURIER_IMAGES[courierId]} alt="courier logo" className="courier-image" />
                <div className="courier-details">
                  <div className="payment-amount">{`P ${
                    gcash?.paymentAmount?.value ? formatAmount(gcash.paymentAmount.value) : '00.0'
                  }`}</div>
                  <div className="service-type">{deliveryServiceType}</div>
                </div>
                <div className="payment-status">{paymentStatus(order)}</div>
              </div>
              <OrderControlBtn
                onClickForRefund={onClickForRefund}
                onClickBookAgain={() => handleClickBookAgain(order, isStandardDelivery)}
                onClickTrackOrder={handleOpenLink}
                paymentStatus={gcash?.refund?.success || false}
                status={status}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrdersCard;
