import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProcessGif from '../../../shared/assets/images/Process/processing-animation.gif';
import splashBackground from '../../../shared/assets/images/SplashScreen/splash-background.png';
import { setLoader } from '../../../shared/redux/home/actions';
import PaymentDAO from '../../../shared/utils/dao/PaymentDAO';
import OnDemandDAO from '../../../shared/utils/dao/DPH';
import AddressBookDAO from '../../../shared/utils/dao/AddressBook';
import { createOrderPayload, isEmpty } from '../../../shared/utils/helpers/purefunctions';
import { MODULE_PATH } from '../../../shared/constants/Module';
import { message } from 'antd';

const PaymentProcess = ({ userInfo, onDemandProps, standardProps, recentlyAddress, view }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const [happyPath, setHappyPath] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [bookingCreated, setBookingCreated] = useState(false);
  const isStandard = view === MODULE_PATH.STANDARD.MAIN;
  const styles = {
    cover: {
      backgroundImage: `url(${splashBackground})`,
      backgroundSize: 'cover',
      height: '100vh',
    },
    container: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      width: '44.445%',
    },
    logo: {
      width: '100%',
    },
  };

  useEffect(() => {
    intervalInquiry();
  }, []);

  const intervalInquiry = async () => {
    if (happyPath && bookingCreated && paymentStatus) {
      return navigate(MODULE_PATH.PAYMENT.MAIN, {
        state: {
          status: paymentStatus,
        },
      });
    }

    const paymentReqId = location?.state?.gcash.paymentRequestId;

    const refNo = location?.state?.refNo;

    const PaymentDao = new PaymentDAO();
    const inquiry = await PaymentDao.gcashPaymentInquiry({
      userId: userInfo.currentUser.id,
      paymentRequestId: paymentReqId,
    });

    if (inquiry) {
      let postOrder = {};
      const statusPayment = inquiry?.result?.paymentStatus || 'INITIATED';

      if (isStandard) {
        const { pickUp, dropOff, orderId } = standardProps;
        const { senderDetails } = pickUp;
        const { recipientDetails } = dropOff;

        postOrder = {
          ...standardProps,
          refNo: orderId,
          userId: userInfo.currentUser.id,
          emailAddress: userInfo.currentUser.email,
          gcash: {
            ...location?.state?.gcash,
            refund: { success: false },
            status: statusPayment,
            pickupNow: standardProps.pickUpTime === 'PickUpNow',
            itemType: standardProps.itemType,
            pickupUnitFloor: senderDetails.unitFloorHouse,
            pickupBldgStreet: senderDetails.bldgStreet,
            dropOffUnitFloor: recipientDetails.unitFloorHouse,
            dropOffBldgStreet: recipientDetails.bldgStreet,
            declaredItemPrice: standardProps.itemPrice,
          },
        };
      } else {
        const { pickUp, dropOff, orderId } = onDemandProps;
        const { senderDetails } = pickUp;
        const { recipientDetails } = dropOff;

        postOrder = {
          ...onDemandProps,
          refNo: orderId,
          userId: userInfo.currentUser.id,
          emailAddress: userInfo.currentUser.email,
          gcash: {
            ...location?.state?.gcash,
            refund: { success: false },
            status: statusPayment,
            pickupNow: onDemandProps.pickUpTime === 'PickUpNow',
            itemType: onDemandProps.itemType,
            pickupUnitFloor: senderDetails.unitFloorHouse,
            pickupBldgStreet: senderDetails.bldgStreet,
            dropOffUnitFloor: recipientDetails.unitFloorHouse,
            dropOffBldgStreet: recipientDetails.bldgStreet,
            declaredItemPrice: onDemandProps.itemPrice,
          },
        };
      }

      const { gcash, userId } = postOrder;
      const { paymentId, paymentRequestId, paymentAmount } = gcash;

      if (statusPayment === 'SUCCESS') {
        const { orderPayload, courierPayload } = createOrderPayload(postOrder);
        const booking = await OnDemandDAO.createOrder(orderPayload, courierPayload);

        if (booking?.success) {
          const addressBook = onDemandProps.pickUp.senderDetails?.addressBook || {};

          if (!isEmpty(addressBook)) {
            const addressDao = new AddressBookDAO();
            addressDao.editAddress(addressBook.id, userInfo.currentUser.id, {
              ...addressBook,
              recently: true,
            });
            !isEmpty(recentlyAddress) &&
              addressDao.editAddress(recentlyAddress.id, userInfo.currentUser.id, {
                ...recentlyAddress,
                recently: false,
              });
          }
          setHappyPath(true);
          setBookingCreated(true);
          setPaymentStatus(statusPayment);

          return navigate(MODULE_PATH.PAYMENT.MAIN, {
            state: {
              status: statusPayment,
            },
          });
        } else {
          // save to mongoDB for refund purposes
          cancelledTransaction(statusPayment, {
            userId: userInfo.currentUser.id,
            orderDetails: JSON.stringify({
              ...postOrder,
              gcash: {
                ...postOrder.gcash,
                extendInfo: JSON.stringify(booking?.message || ''),
              },
            }),
            status: statusPayment,
          });
          await PaymentDao.refund({
            paymentId,
            orderNo: refNo,
            paymentRequestId,
            refundAmount: {
              currency: paymentAmount.currency,
              value: paymentAmount.value,
            },
            userId,
            refundReason: 'The user cancels the order due to some reason.',
          });
          return navigate('/failedOrder');
          // return navigate('/error', {
          //   state: { refundStatus: refundResultStatus, attempt: gcash?.refund?.attempt },
          // });
        }
      } else if (statusPayment === 'FAIL' || statusPayment === 'CANCELLED') {
        // save to mongoDB
        cancelledTransaction(statusPayment, {
          userId: userInfo.currentUser.id,
          orderDetails: JSON.stringify(postOrder),
          status: statusPayment,
        });
      } else {
        await new Promise(resolve => setTimeout(resolve, 5000));
        intervalInquiry();
      }
    }
  };
  const cancelledTransaction = async (paymentStatus, order) => {
    const PaymentDao = new PaymentDAO();
    const transaction = await PaymentDao.pushTransaction(order);
    if (transaction) {
      setHappyPath(true);
      setBookingCreated(true);
      setPaymentStatus(paymentStatus);
      // return navigate('/payment', {
      //   state: {
      //     status: paymentStatus,
      //   },
      // });
    }
  };
  return (
    <div style={styles.cover} className="about-us-container">
      <div className="about-us-content">
        <div className="body-content">
          <div className="parcels-logo-container process">
            <img src={ProcessGif} alt="Payment is in Process" className="process-logo" />
            <div className="payment-process-info">We are placing your order.</div>
            <div className="payment-process-info">Please do not close the app.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setLoader,
    },
    dispatch
  );
}
const mapStateToProps = state => ({
  onDemandProps: state.onDemandReducer,
  standardProps: state.standardReducer,
  onDemandPaymentRequestId: state.onDemandReducer.paymentRequestId,
  standardPaymentRequestId: state.standardReducer.paymentRequestId,
  userInfo: state.usersReducer,
  recentlyAddress: state.addressReducer.recentlyAddress,
  view: state.homeReducer.view,
});
export default connect(mapStateToProps, matchDispatchToProps)(PaymentProcess);
