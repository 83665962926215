import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  setDropOff,
  setLocation,
  setLocationReference,
  setPickUp,
} from '../../../../shared/redux/onDemand/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import AddressPin from '../../../../shared/assets/images/OndemandDelivery/start_point_location.png';
import { MODULE_PATH } from '../../../../shared/constants/Module';

const SearchLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: 'ph' },
  };

  const pickup = useSelector(state => state.onDemandReducer.pickUp);
  const dropff = useSelector(state => state.onDemandReducer.dropOff);
  const pointLocation = useSelector(state => state.onDemandReducer.pointLocation);
  const location = useSelector(state => state.onDemandReducer.location);
  const { senderDetails } = pickup;
  const { recipientDetails } = dropff;

  useEffect(() => {
    if (window.google) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );

      autoCompleteRef.current.addListener('place_changed', async () => {
        const place = await autoCompleteRef.current.getPlace();
        const addressComponents = place?.address_components;

        let city = '';
        let province = '';
        if (!addressComponents) return
        addressComponents.forEach(component => {
          if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            province = component.long_name;
          }
        });

        const getString = addressComponents.map(a => `${a.long_name}`);
        const address = `${place.name} ${city}. ${province}`;
        dispatch(setLocation(`${place.name} ${city}, ${province}, ${getString.toString()}`));
        if (pointLocation === 'PickUp') {
          dispatch(
            setPickUp({
              address,
              ...senderDetails, // Spread senderDetails first
              city,
              province,
            })
          );
        }
        if (pointLocation === 'DropOff') {
          dispatch(setDropOff({ address, ...recipientDetails, city, province }));
        }
        navigate(MODULE_PATH.ADDRESS.PICKUP_DETAILS, { state: { showAutoComplete: false } });
      });
    }

    return () => {};
  }, [pickup, dropff, location]);

  return (
    <div className="container__search__address">
      <div className="container__header">
        <Button
          className="back-button"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(MODULE_PATH.ADDRESS.SELECT_ADDRESS)}
        />
        <img src={AddressPin} alt="address pin" />
        <input placeholder="Search Address" className="search-address-input" ref={inputRef} />
      </div>
    </div>
  );
};
export default SearchLocation;
