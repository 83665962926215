import React, { useState, useEffect } from 'react';
import splashBackground from '../../../shared/assets/images/SplashScreen/splash-background.png';
// import logo from "../../../shared/assets/images/SplashScreen/parcels-splash-logo.png";
import logo from '../../../shared/assets/images/SplashScreen/Parcels-logo-animation.gif';

function Splash(props) {
  const styles = {
    cover: {
      backgroundImage: `url(${splashBackground})`,
      backgroundSize: 'cover',
      height: '100vh',
    },
    container: {
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      left: '50%',
      top: '50%',
      width: '44.445%',
    },
    logo: {
      width: '100%',
    },
  };

  const [animationStep, setAnimationStep] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setAnimationStep('splah-container-hide');
    }, 2000);
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     props.onSetShowComponent();
  //   }, 2000);
  // }, []);

  return (
    <div style={styles.cover} className={`${animationStep}`}>
      <div style={styles.container}>
        <img style={styles.logo} src={logo} alt="HTML5 Icon" />
      </div>
    </div>
  );
}

export default Splash;
