import React from 'react';
import { BG } from '../utils/enums/AppIcons'; 

const ComingSoonPage = () => {

  const styles = {
    cover: {
      width: '100vw',
      height: '105vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      backgroundImage: `url(${BG.COMING_SOON})`,
      backgroundSize: '100% auto',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center top',
      backgroundColor: 'white'
    },
  };

  return (
    <di className='coming-soon-ios' style={styles.cover} />
  );
};

export default ComingSoonPage;
