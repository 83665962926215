import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router';
import {
  formatAmount,
  showConfirmationModal,
} from '../../../../../../shared/utils/helpers/purefunctions';

import { MODULE_PATH } from '../../../../../../shared/constants/Module';
import { SUPPORT_CONTACTS } from '../../../../../../shared/utils/enums/AppConstants';

const PaymentDetails = ({
  payment,
  finalShippingFee,
  refundStatus,
  refundAttempt,
  refundSuccess,
  status,
}) => {
  const navigate = useNavigate();
  const { chatSupportLink } = SUPPORT_CONTACTS;

  const paymentAmount = payment?.paymentAmount;
  const formattedAmount = !paymentAmount
    ? finalShippingFee.toFixed(2)
    : formatAmount(paymentAmount?.value);

  const refundMessage =
    status === 'CANCELLED' &&
    ((refundAttempt && refundAttempt >= 3) || refundStatus === 'F' || !refundSuccess)
      ? 'We are currently processing your refund. Your refund will be credited to your GCash account within 3-5 business days.'
      : 'Paid via GCash.';

  const handleOpenLink = url => {
    showConfirmationModal(url);
  };

  return (
    <div className="payment-details">
      <div className="payment-header">
        <h1>Payment</h1>
        <span
          className="show-breakdown-btn"
          onClick={() => navigate(MODULE_PATH.ORDERS.PAYMENT_BREAKDOWN)}
        >
          Show Breakdown
        </span>
      </div>
      <div className="payment-contents">
        <span className="payment-status-icon">Paid</span>
        <span className="amount">{`Total: ${formattedAmount}`}</span>
      </div>
      <div className="payment-status">
        <div className="wallet-status">
          <FontAwesomeIcon icon={solid('wallet')} />
          <span>{refundMessage}</span>
        </div>
        <div className="chat-support">
          <div className="content">
            <FontAwesomeIcon icon={solid('info-circle')} className="info-circle" />
            <span>
              For refund concerns, chat with Parcels PH support by tapping the button below.
            </span>
          </div>
          <div className="copy-btn" onClick={() => handleOpenLink(chatSupportLink)}>
            <span className="copy__icon">
              <FontAwesomeIcon icon={solid('arrow-up-right-from-square')} />
            </span>
            <div className="label">Chat support link</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PaymentDetails;
