export const PROVIDERS_HOTLINE = {
  MRSPEEDY: '(02)123456789',
  LALAMOVE: '(02)234567891',
  GRAB: '(02)345678912',
};

export const MRSPEEDY_VEHICLE_TYPE = {
  7: 'Car',
  8: 'Motorcycle',
};

export const COURIER_VEHICLE_TYPES = {
  motorcycle: 'MOTORCYCLE',
  sedan: 'SEDAN',
  mpv: 'SEDAN',
  van_l300: 'L300',
  van_multicab: 'L300',
  truck: 'TRUCK',
  truck330: 'TRUCK',
  ld_10w_truck: 'TRUCK',
  suv: 'SUV',
  pickup_truck: 'PICKUP_TRUCK',
};

export const MRSPEEDY = 'MRSPEEDY';
export const BORZO = 'BORZO';
