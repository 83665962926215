import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  fetchListOfAddress,
  setSelectedAddress,
  setAddressBookInitialState,
} from '../../../shared/redux/addressBook/actions';

import { PAGE, MODULE_PATH } from '../../../shared/constants/Module';
import AddressBookList from './components/List';
import StickyHeader from '../../../shared/components/StickyHeader';
import Footer from '../Home/components/Footer';

import { ICON } from '../../../shared/utils/enums/AppIcons';
import AddressBookDAO from '../../../shared/utils/dao/AddressBook';

import { Modal } from 'antd';
import { isEmpty } from '../../../shared/utils/helpers/purefunctions';

const confirm = Modal.confirm;

const AddressBook = ({
  addressList,
  setSelectedAddress,
  user,
  listOfAddress,
  setAddressBookInitialState,
}) => {
  let navigate = useNavigate();

  // sticker header props
  const nextNavigate = {
    label: 'Add New',
    imgPath: ICON.NEW_ICON,
    path: MODULE_PATH.ADDRESS_BOOK.FORM,
    state: { addressBookForm: 'Add New' },
  };

  useEffect(() => {
    fetchAddress();
    return () => {
      fetchAddress();
    };
  }, []);

  const fetchAddress = () => {
    fetchListOfAddress(user.id);
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    confirm({
      className: 'delete-addressbook',
      title: 'Delete address',
      okText: 'Delete',
      cancelText: 'Cancel',
      content: (
        <>
          This address will be permanently deleted from your <b>address book</b>.<br />
          <br />
          Are you sure?
        </>
      ),
      onOk: () => {
        const addressBookDao = new AddressBookDAO();
        addressBookDao.deleteAddress(user.id, id);
        if (addressList.length === 1) {
          setAddressBookInitialState();
        }
      },
      onCancel: () => {
        console.log('Cancel');
      },
    });
  };

  const handleUpdate = obj => {
    setSelectedAddress(obj);
    navigate(MODULE_PATH.ADDRESS_BOOK.FORM, { state: { addressBookForm: 'Edit' } });
  };

  return (
    <>
      <StickyHeader title={PAGE.ADDRESS_BOOK} onNextNavigate={nextNavigate} />
      {!isEmpty(addressList) ? (
        <AddressBookList
          handleDelete={(e, id) => handleDelete(e, id)}
          data={addressList}
          handleUpdate={obj => handleUpdate(obj)}
        />
      ) : (
        <div className="empty__addressbook__container">
          <div className="empty__addressbook">
            <img src={ICON.EMPTY_ADDRESS_BOOK} />
            <div>Addresss book is empty</div>
            <div>
              Add new address by clicking the <span>Add New</span> button above.
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchListOfAddress,
      setSelectedAddress,
      setAddressBookInitialState,
    },
    dispatch
  );

const mapStateToProps = state => ({
  addressList: state.addressReducer.listOfAddress,
  user: state.usersReducer.currentUser,
  addressReducer: state.addressReducer,
});

export default connect(mapStateToProps, matchDispatchToProps)(AddressBook);
