import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setVehicleType, setCourier } from '../redux/onDemand/actions';

import kgImage from '../assets/images/OndemandDelivery/kg.png';
import boxImage from '../assets/images/OndemandDelivery/box.png';
import { VEHICLE_TYPES } from '../utils/enums/DeliveryConstants';
import StickyHeader from './StickyHeader';
import { ICON, ON_DEMAND_ICON } from '../utils/enums/AppIcons';
import { MODULE_PATH } from '../constants/Module';
import { COURIER_VEHICLE_TYPES } from '../utils/enums/Providers';
import { formatUnderscoredText } from '../utils/helpers/purefunctions';

const VehicleTypeList = ({ setVehicleType, setCourier }) => {
  let navigate = useNavigate();
  const location = useLocation();

  const prevNavigate = {
    label: 'Choose Vehicle Type',
    imgPath: ICON.ON_DEMAND,
    path: MODULE_PATH.ON_DEMAND.MAIN,
  };

  const VehicleIcon = ({ value }) => {
    let vehicleIcon = 'MOTORCYCLE';
    vehicleIcon = COURIER_VEHICLE_TYPES[value.toLowerCase()];
    return <img src={ON_DEMAND_ICON[vehicleIcon]} alt={value} />;
  };

  const handleSelectVehicleType = data => {
    setVehicleType(data);
    setCourier({});
    navigate(MODULE_PATH.ON_DEMAND.MAIN, { state: location?.state });
  };

  return (
    <div className="vehicle__container">
      <StickyHeader title="Choose Vehicle Type" onPrevNavigate={prevNavigate} />
      {VEHICLE_TYPES.map((data, index) => (
        <div className="vehicle__list" key={index}>
          <div className="info" onClick={() => handleSelectVehicleType(data)}>
            <VehicleIcon value={data.value} />
            <div className="vehicleType-info">
              <div className="header">
                {data.value === 'MPV' ? data.value : formatUnderscoredText(data.value)}
              </div>
              <div className="details">
                <span>
                  <img src={kgImage} alt="kg" /> {data.description.weightText}
                </span>

                <span>
                  <img src={boxImage} alt="bag" /> {data.description.dimensionText}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setVehicleType,
      setCourier,
    },
    dispatch
  );

export default connect(null, matchDispatchToProps)(VehicleTypeList);
