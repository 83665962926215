import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { handleReturnMyAccounts } from '../../../../../shared/util/helpers/purefunctions';
import {
  capitalizeName,
  formatPhoneNumber,
} from '../../../../../shared/utils/helpers/purefunctions';

const AccountDetails = ({ user }) => {
  const navigate = useNavigate();
  const name = `${user.firstName} ${user.lastName}`;
  return (
    <div className="account-details-container">
      <div className="account-details-content">
        <div className="header">
          <Button
            className="btn-return-home"
            onClick={() => handleReturnMyAccounts(navigate)}
            icon={<ArrowLeftOutlined />}
          />
          <div className="account-details"> Account Details </div>
        </div>
        <div className="account-details-container">
          <div className="account-details-card">
            <div className="name">
              <div className="name-tag">Name</div>
              <div className="account-name">{capitalizeName(name)}</div>
            </div>
            <div className="gcash-number">
              <div className="gcash-number-tag">GCash Number</div>
              <div className="account-gcash-number">{formatPhoneNumber(user.gcashNumber)}</div>
            </div>
            <div className="email">
              <div className="email-tag">Email</div>
              <div className="email-account">{user.email}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  user: state.usersReducer.currentUser,
});
export default connect(mapStateToProps)(AccountDetails);
