import { Button } from 'antd';
import React from 'react';

import { ICON } from '../utils/enums/AppIcons';

const SubmitButton = ({ name, onClick, isProceedValue, className, hasIcon }) => {
  console.log('isProceedValue:', isProceedValue);
  return (
    <Button
      className={`submit-button ${isProceedValue ? 'proceed' : ''} ${className || ''}`}
      onClick={() => onClick()}
    >
      {hasIcon && <img src={ICON[hasIcon]} alt="icon" />} {name}
    </Button>
  );
};

export default SubmitButton;
