import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const DEPLOYMENT = {
  STAGING: {
    CONFIG: {
      apiKey: 'AIzaSyDpTZzlJO5kw2Xg2WSEKwneKhNxQDGTdMY',
      authDomain: 'fleet-staging-2333a.firebaseapp.com',
      databaseURL: 'https://fleet-staging-2333a.firebaseio.com',
      projectId: 'fleet-staging-2333a',
      storageBucket: 'fleet-staging-2333a.appspot.com',
      messagingSenderId: '300017822442',
      appId: '1:300017822442:web:91212cf246daaa74880593',
      measurementId: 'G-RW7DWZ5NG3',
    },
  },
  PRODUCTION: {
    CONFIG: {
      apiKey: 'AIzaSyC_n8J4XuNGtgoxhB0f73iJ4DJvK7D0XhY',
      authDomain: 'fleet-ph.firebaseio.com',
      databaseURL: 'https://fleet-ph.firebaseio.com',
      projectId: 'fleet-ph',
      storageBucket: 'fleet-ph.appspot.com',
      messagingSenderId: '965150477781',
      appId: '1:300017822442:web:91212cf246daaa74880593',
    },
  },
};

const app = initializeApp(DEPLOYMENT.PRODUCTION.CONFIG);
const auth = getAuth(app);
const firestoreDb = getFirestore(app);
const realtimeDb = getDatabase(app);

export { firestoreDb, auth, realtimeDb };
