import React from 'react';
import { Card, Collapse } from 'antd';
import mapMarker from '../../../../../../shared/assets/images/location/map-marker-alt.png';
import { formatPhoneNumber } from '../../../../../../shared/utils/helpers/purefunctions';
const { Panel } = Collapse;

const PickupDropoff = ({ pickUpdetails, deliveryDetails }) => {
  const { customerName, pickupAddress, contactNumber, emailAddress } = pickUpdetails;
  return (
    <div className="pickup-dropoff-details">
      <Collapse expandIconPosition="end" defaultActiveKey={1}>
        <Panel header="Pickup & Drop-off" key={1}>
          <div className="order-container">
            <Card>
              <div className="timeline-container">
                <ul className="timeline">
                  <li className="timeline-sender">
                    <div className="order-content">
                      <div className="order-trail">
                        <div className="sender-name">{customerName}</div>
                        <div className="sender-address">{pickupAddress}</div>
                        <span className="contact">{formatPhoneNumber(contactNumber)}</span> |
                        <span className="email">{emailAddress}</span>
                      </div>
                    </div>
                  </li>
                  <li className="timeline-receiver">
                    <img src={mapMarker} alt="map-marker" />
                    <div className="order-content">
                      <div className="order-trail">
                        <div className="sender-name">{deliveryDetails.customerName}</div>
                        <div className="sender-address">{deliveryDetails.deliveryAddress}</div>
                        <span className="contact">
                          {formatPhoneNumber(deliveryDetails.contactNumber)}
                        </span>{' '}
                        |<span className="email">{deliveryDetails.emailAddress}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Card>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default PickupDropoff;
