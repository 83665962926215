import React from 'react';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { setTargetOrder } from '../../../../../../shared/redux/home/actions';
import { useNavigate } from 'react-router';
import { MODULE_PATH } from '../../../../../../shared/constants/Module';
import { useDispatch } from 'react-redux';
import TrackOrderButton from '../../../../../../shared/components/TrackOrderButton';
import { ORDER_STATUSES } from '../../../../../../shared/utils/enums/AppConstants';
import AssignRiderLoader from '../../../../../../shared/elements/Loader/AssignRiderLoader';
import { STANDARD_COURIERS } from '../../../../../../shared/utils/enums/StandardDelivery';

const Orders = ({ orders }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const OnDemandLoader = ({ isNotPending }) => {
    if (!isNotPending) {
      return <AssignRiderLoader />;
    }
  };

  return (
    <>
      {orders?.map((order, index) => {
        const {
          refNo,
          status,
          subStatus,
          courierId,
          completionDateTime,
          customerName,
          meta,
          gcash,
        } = order;
        const deliveryDetails = meta ? JSON.parse(meta) : {};
        const isStandardDelivery = STANDARD_COURIERS.includes(courierId);
        const isNotPending = ['DONE', 'CANCELLED'].includes(status);
        const label = isStandardDelivery ? 'standard' : 'ondemand';

        const onClickSelectOrder = order => {
          dispatch(setTargetOrder(order));
          navigate(MODULE_PATH.ORDERS.ORDER_DETAILS, {
            state: { link: MODULE_PATH.MAIN },
          });
        };

        const handleClickBookAgain = order => {
          dispatch(setTargetOrder(order));
          navigate(MODULE_PATH.ON_DEMAND.MAIN);
        };

        const OrderTypeBtn = ({ label, order }) => {
          if (status === 'DONE') {
            return (
              <div className={`track-order ${label}`} onClick={() => handleClickBookAgain(order)}>
                <span>Book Again</span>
              </div>
            );
          }
          if (status === 'CANCELLED') {
            if (!gcash.refund.success) {
              return (
                <div
                  className={`track-order ${label}`}
                  onClick={() => navigate(MODULE_PATH.ORDERS.CANCEL_ORDER)}
                >
                  <span>For Refund</span>
                </div>
              );
            }

            return (
              <div className={`track-order ${label}`} onClick={() => handleClickBookAgain(order)}>
                <span>Rebook</span>
              </div>
            );
          }
          return <TrackOrderButton label={label} order={order} />;
        };

        return (
          <div className="order__container" key={index}>
            <div className="row-1" onClick={() => onClickSelectOrder(order)}>
              <div className="delivery-tag">
                <span className={isStandardDelivery ? 'standard' : 'ondemand'}>
                  {isStandardDelivery ? 'Standard Delivery' : 'On-Demand Delivery'}
                </span>
                <span>{moment(completionDateTime).format('ddd, MMM D, h:mm A')}</span>
              </div>
              <div className="ref-no">
                <div className="order-number">{`Order No. ${refNo}`}</div>
                <FontAwesomeIcon icon={solid('angle-right')} />
              </div>
            </div>
            <div className="row-2" onClick={() => onClickSelectOrder(order)}>
              <div className="icons">
                <FontAwesomeIcon icon={solid('circle-dot')} />
                <div className="vertical-line"></div>
                <FontAwesomeIcon icon={solid('location-dot')} />
              </div>
              <div className="customer-info">
                <span>{customerName}</span>
                <span>{deliveryDetails.customerName}</span>
              </div>
            </div>
            <div className={`row-3 ${isStandardDelivery ? 'standard' : 'ondemand'}`}>
              <div>
                <div className="status">{ORDER_STATUSES[status] || ORDER_STATUSES[subStatus]}</div>
                {isStandardDelivery ? null : <OnDemandLoader isNotPending={isNotPending} />}
              </div>
              <OrderTypeBtn label={label} order={order} />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Orders;
