import React from 'react';
import SearchImg from '../../../../../src/shared/assets/images/SearchOrders/search-icon.png';
const NoOrderSearch = () => (
  <>
    <div className="no-search-orders-container">
      <div className="no-search-orders-content">
        <img src={SearchImg} alt="Search orders" className="search-orders-img" />
        <div className="search-your-orders">Search your orders</div>
        <div className="find-your-orders">
          Find your order by entering the Order No., or Sender/Recipient Name
        </div>
      </div>
    </div>
  </>
);
export default NoOrderSearch;
