import React from 'react';
import { isEmpty } from '../utils/helpers/purefunctions';

import { ICON } from '../utils/enums/AppIcons';

const FormInput = ({
  value = '',
  onChange,
  onClick,
  onFocus,
  inputFocusName,
  onBlur,
  placeholder = '',
  isReqOptional,
  hasCounter,
  suffixIcon,
  className,
  prefixOn,
  validatePhone,
  onSetShow,
  ...props
}) => {
  const charLength = isEmpty(value) ? 0 : value.length;
  const handleOnFocus = () => {
    setTimeout(() => {
      onFocus();
    }, 200);
  };
  const handleOnBlur = () => {
    setTimeout(() => {
      onBlur();
    }, 100);
  };
  const handleOnClick = () => {
    setTimeout(() => {
      onClick();
    }, 300);
  };

  const customClassName =
    hasCounter && suffixIcon ? 'complete-flow' : hasCounter || suffixIcon ? 'over-flow' : '';

  return (
    <div className="form-input">
      {prefixOn && <span className="prefix">+63</span>}
      {onSetShow ? (
        <input
          value={value}
          onChange={e => onChange(e.target.value)}
          onFocus={() => handleOnFocus()}
          onBlur={() => handleOnBlur()}
          placeholder=" "
          type="text"
          name={placeholder}
          className={`${className} ${customClassName} ${hasCounter ? 'counter' : ''}  ${
            suffixIcon ? 'avatar' : ''
          }`}
          onClick={e => onSetShow(e)}
          {...props}
        />
      ) : (
        <input
          value={value}
          onChange={e => onChange(e.target.value)}
          onFocus={() => handleOnFocus()}
          onBlur={() => handleOnBlur()}
          placeholder=" "
          type="text"
          name={placeholder}
          className={`${className} ${customClassName} ${hasCounter ? 'counter' : ''}  ${
            suffixIcon ? 'avatar' : ''
          }`}
          {...props}
        />
      )}
      {inputFocusName && !isEmpty(value) && (
        <img
          onClick={() => handleOnClick()}
          className={`cancel-clear-icon ${hasCounter ? 'counter' : ''}  ${
            suffixIcon ? 'avatar' : ''
          }`}
          src={ICON.CANCEL_CLEAR}
          alt="clear icon"
        />
      )}
      {hasCounter && <span className="char-counter">{`${charLength} / 120 `}</span>}
      {suffixIcon && <img className="avatar" src={ICON[suffixIcon]} alt="avatar" />}
      <label className={isReqOptional}>{placeholder}</label>
    </div>
  );
};

export default FormInput;
