import { ArrowLeftOutlined } from '@ant-design/icons';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input } from 'antd';
import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import OrderCard from '../../../../../src/shared/components/OrderCard';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import NoOrderSearch from './NoOrderSearch';
import { setTargetOrder } from '../../../../shared/redux/home/actions';
import OrdersCard from '../../../../shared/components/OrdersCard';

const SearchOders = () => {
  const [searchOrderInfo, setSearchOrderInfo] = useState(null);
  const navigate = useNavigate();
  const orderList = useSelector(state => state.homeReducer.orderList);
  const forRefundOrders = useSelector(state => state.homeReducer.forRefundOrders);
  const cancelledOrders = useSelector(state => state.homeReducer.cancelledOrders);
  const completedOrderList = useSelector(state => state.homeReducer.completedOrderList);
  const allOrders = [...orderList, ...forRefundOrders, ...cancelledOrders, ...completedOrderList];
  const dispatch = useDispatch();

  const handleReturnHome = () => {
    navigate(MODULE_PATH.HOME);
  };

  const handleChange = useCallback(e => {
    const { value } = e.target;
    if (!value) {
      setSearchOrderInfo(null);
    } else {
      setSearchOrderInfo(value);
    }
  }, []);

  const filterOrders = allOrders.filter(order => {
    const meta = JSON.parse(order.meta);
    const orderData =
      order.refNo.includes(searchOrderInfo) ||
      meta.customerName.toLowerCase().includes(searchOrderInfo) ||
      meta.customerName.includes(searchOrderInfo) ||
      order.customerName.toLowerCase().includes(searchOrderInfo) ||
      order.customerName.includes(searchOrderInfo);
    return orderData;
  });

  const onClickSelectOrder = order => {
    dispatch(setTargetOrder(order));
    navigate(MODULE_PATH.ORDERS.ORDER_DETAILS, {
      state: {
        link: MODULE_PATH.ORDERS.SEARCH_ORDER,
      },
    });
  };

  const SearchResults = ({ filterOrders }) => (
    <div className="search-result-container">
      <div className="search-result-content">
        <span>Search Results:</span>
        <span>{filterOrders.length} order(s) found</span>
      </div>
    </div>
  );

  return (
    <>
      <div className="search-container">
        <div className="search-header-content">
          <Button
            className="btn-return-home"
            onClick={handleReturnHome}
            icon={<ArrowLeftOutlined />}
          />
          <FontAwesomeIcon icon={solid('search')} />
          <input bordered={false} placeholder="Search order..." onChange={handleChange} />
        </div>
        <div className="search-body-container">
          <div className="search-body-content">
            {filterOrders.length ? (
              <>
                <SearchResults filterOrders={filterOrders} />
                <OrdersCard orders={filterOrders} onClickSelectOrder={onClickSelectOrder} />
              </>
            ) : (
              <NoOrderSearch />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default SearchOders;
