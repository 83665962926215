import React from 'react';
import { useNavigate } from 'react-router-dom';

import { MODULE_PATH } from '../constants/Module';
import FindCourier from '../assets/images/OndemandDelivery/find_courier.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSelector } from 'react-redux';
import { capitalizeName, formatBills, isEmpty } from '../utils/helpers/purefunctions';
import boxImage from '../assets/images/OndemandDelivery/box.png';
import { message } from 'antd';

const Courier = () => {
  const navigate = useNavigate();
  const { pickUp, dropOff } = useSelector(state => state.onDemandReducer);

  const handleSelectCourier = () => {
    if (isEmpty(pickUp?.address) || isEmpty(dropOff?.address)) {
      return message.error('Pickup and Drop-off address are required.');
    }

    navigate(MODULE_PATH.SHARED.COURIER, {});
  };

  const courier = useSelector(state => state.onDemandReducer.courier);
  const selectedVehicle = useSelector(state => state.onDemandReducer.vehicleType);

  const courierName = courier.courier === 'MRSPEEDY' ? 'BORZO' : courier.courier;

  return (
    <div className="courier" onClick={() => handleSelectCourier()}>
      <div className="title">Couriers</div>
      <div className="info">
        <img src={FindCourier} alt="Find courier" />
        <div className="courier-info">
          <div className="header">
            {!isEmpty(courier) ? capitalizeName(courierName.toLowerCase()) : 'Find a courier'}{' '}
            {isEmpty(courier) && <span>*</span>}
          </div>
          <div className="details">
            {isEmpty(courier) ? (
              'Courier Description'
            ) : (
              <>
                <img src={boxImage} alt="bag" />
                <span>{selectedVehicle.description.dimensionText}</span>
              </>
            )}
          </div>
        </div>
        <div className="price">{!isEmpty(courier) ? `P ${formatBills(courier.rate)}` : ''}</div>
        <FontAwesomeIcon icon={solid('angle-right')} />
      </div>
    </div>
  );
};

export default Courier;
