import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import PointLocation from '../../../../shared/assets/images/OndemandDelivery/point_location.png';
import AddBookmark from '../../../../shared/assets/images/OndemandDelivery/bookmark_add.png';
import EditIcon from '../../../../shared/assets/images/OndemandDelivery/edit.png';
import { MODULE_PATH } from '../../../../shared/constants/Module';

import { MAPS_API_KEY } from '../../../../shared/utils/enums/AppConstants';
import { setDropOff, setLocation, setPickUp } from '../../../../shared/redux/standard/actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  setSelectedAddress,
  fetchListOfAddress,
} from '../../../../shared/redux/addressBook/actions';
import { setLoader } from '../../../../shared/redux/home/actions';
import { bindActionCreators } from 'redux';
import StickyHeader from '../../../../shared/components/StickyHeader';

const SelectAddress = ({
  fetchListOfAddress,
  addressList,
  user,
  setSelectedAddress,
  setPickUp,
  setDropOff,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pickUp, dropOff, pointLocation } = useSelector(state => state.standardReducer);
  const { senderDetails } = pickUp;
  const { recipientDetails } = dropOff;

  // const [currentAddress, setCurrentAddress] = useState('');
  const [viewAll, setViewAll] = useState(2);
  const prevNavigate = { path: MODULE_PATH.STANDARD.MAIN, state: {} };

  useEffect(() => {
    fetchAddress();
  }, [fetchListOfAddress]);

  useEffect(() => {}, [addressList]);

  const fetchAddress = () => {
    console.log('userid', user.id);
    fetchListOfAddress(user.id);
    setLoader(false);
    // onSetLoader(false);
  };

  const onClickSavedAddress = savedAddress => {
    if (pointLocation === 'PickUp') {
      setPickUp({
        latitude: savedAddress.latitude,
        longitude: savedAddress.longitude,
        city: savedAddress.city,
        province: savedAddress.province,
        address: savedAddress.address,
        barangay: savedAddress.barangay,
        senderDetails: {
          ...senderDetails,
          fullName: savedAddress.fullName,
          unitFloorHouse: savedAddress.unitFloorHouse,
          contactNo: savedAddress.contactNo,
          address: savedAddress.address,
          city: savedAddress.city,
          province: savedAddress.province,
          postalCode: savedAddress.postalCode,
          bldgStreet: savedAddress.bldgStreet,
        },
      });
    }
    if (pointLocation === 'DropOff') {
      setDropOff({
        latitude: savedAddress.latitude,
        longitude: savedAddress.longitude,
        city: savedAddress.city,
        province: savedAddress.province,
        address: savedAddress.address,
        barangay: savedAddress.barangay,
        recipientDetails: {
          ...recipientDetails,
          fullName: savedAddress.fullName,
          unitFloorHouse: savedAddress.unitFloorHouse,
          contactNo: savedAddress.contactNo,
          address: savedAddress.address,
          city: savedAddress.city,
          province: savedAddress.province,
          postalCode: savedAddress.postalCode,
          bldgStreet: savedAddress.bldgStreet,
        },
      });
    }
    dispatch(setLocation(savedAddress.address));
    navigate(MODULE_PATH.STANDARD.SENDER_DETAILS);
  };

  const handleUpdate = obj => {
    setSelectedAddress(obj);
    navigate(MODULE_PATH.ADDRESS_BOOK.FORM, { state: { addressBookForm: 'Edit' } });
  };

  const handleViewAll = () => {
    setViewAll(addressList.length);
  };

  return (
    <div className="container__select__address">
      <StickyHeader onPrevNavigate={prevNavigate} title="Address" />

      {addressList.length > 0 &&
        addressList
          .filter(filteredAddress => filteredAddress.isSavePlaces === true)
          .sort((a, b) => b.timestamp - a.timestamp)
          .map(
            (address, index) =>
              index === 0 && (
                <div
                  className="recently__used__location"
                  key={index}
                  onClick={() => onClickSavedAddress(address)}
                >
                  <div className="title">Recently Used</div>
                  <div className="content">
                    <img src={PointLocation} alt="point location" />
                    <div className="address-data">
                      <span>{address?.addressType || address?.province}</span>
                      <span>
                        {address.unitFloorHouse} {address.address}
                      </span>
                      <span>
                        {address.fullName} •{`+63 ${address.contactNo}`}
                      </span>
                      <span>Recently Used</span>
                    </div>
                    <img src={AddBookmark} alt="bookmark add" />
                  </div>
                </div>
              )
          )}

      <div className="saved__places">
        <div className="title">
          <span>Saved Places</span>
          <span onClick={() => handleViewAll()}>View all</span>
        </div>

        {addressList.length > 0 &&
          addressList
            .filter(filteredAddress => filteredAddress.isSavePlaces === true)
            .sort((a, b) => b.timestamp - a.timestamp)
            .map(
              (address, index) =>
                index <= viewAll && (
                  <div className="content">
                    <img src={AddBookmark} alt="bookmark" />
                    <div
                      className="address-data"
                      key={index}
                      onClick={() => onClickSavedAddress(address)}
                    >
                      <span>{address?.addressType || address?.province}</span>
                      <span>
                        {address.unitFloorHouse} • {address.address}
                      </span>
                      <span>
                        {address.fullName} • {`+63 ${address.contactNo}`}
                      </span>
                      {index === 0 && <span>Recently Used</span>}
                    </div>
                    <img src={EditIcon} alt="Edit" onClick={() => handleUpdate(address)} />
                  </div>
                )
            )}
      </div>
    </div>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedAddress,
      fetchListOfAddress,
      setLoader,
      setPickUp,
      setDropOff,
    },
    dispatch
  );

const mapStateToProps = state => ({
  addressList: state.addressReducer.listOfAddress,
  recentlyAddress: state.addressReducer.recentlyAddress,
  user: state.usersReducer.currentUser,
  propsPickUp: state.onDemandReducer.pickUp,
});

export default connect(mapStateToProps, matchDispatchToProps)(SelectAddress);
