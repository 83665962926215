import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { formatAmount } from '../../../shared/utils/helpers/purefunctions';

import { MODULE_PATH } from '../../../shared/constants/Module';

const PaymentBreakdown = () => {
  const navigate = useNavigate();
  const orderDetails = useSelector(state => state.homeReducer.targetOrder);
  const paymentAmount = orderDetails.gcash?.paymentAmount;
  const formattedAmount = formatAmount(paymentAmount?.value);
  const formatShippingFee = () => {
    const tempAmount = Number(paymentAmount?.value);
    const amount = Math.floor(tempAmount / 100);
    const shippingAmount = amount - 5;
    const [tempValue, decimal] = shippingAmount.toFixed(2).split('.');

    if (Number(tempValue) >= 4) {
      return `${Number(tempValue).toLocaleString()}.${decimal}`;
    }

    return `${tempValue}.${decimal}`;
  };

  return (
    <>
      <div className="payment-breakdown-container">
        <div className="payment-breakdown-content">
          <div className="header">
            <Button
              className="btn-return"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(MODULE_PATH.ORDERS.ORDER_DETAILS)}
            />
            <div className="payment-breakdown">Payment Breakdown</div>
          </div>
          <div className="body-content">
            <div className="details">
              <div className="level">
                <span>Shipping Fee</span>
                <span>Convenience Fee</span>
                <b>Total</b>
              </div>
              <div className="value">
                <span>{formatShippingFee()}</span>
                <span>5.00</span>
                <b>{`P ${formattedAmount}`}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentBreakdown;
