import produce from 'immer';
import { SET_BANNER } from '../enums/types';

const initialState = {
  bannersList: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action = {}) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_BANNER:
        draft.bannersList = [...state.bannersList, action.payload];
        break;
      default:
        return draft;
    }
  });