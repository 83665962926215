import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { formatText } from '../utils/helpers/purefunctions';
import ItemDetailsList from './ItemDetailsList';
import BoxaddItem from '../assets/images/OndemandDelivery/box_add_item.png';
import { MODULE_PATH } from '../constants/Module';

const ItemDetails = ({
  view,
  onDemandItemType,
  standardItemType,
  onDemandItemDescription,
  standardItemDescription,
}) => {
  const [showItemDetailsDrawer, setItemDetailsDrawer] = useState(false);

  const isStandard = view === MODULE_PATH.STANDARD.MAIN;
  const itemType = isStandard ? standardItemType : onDemandItemType;
  const itemDescription = isStandard ? standardItemDescription : onDemandItemDescription;

  return (
    <>
      <div className="item__details" onClick={() => setItemDetailsDrawer(true)}>
        <div className="title">Item Details</div>
        <div className="info">
          <img src={BoxaddItem} alt="motorcycle" />
          <div className="item-info">
            <div className="header">
              {itemType ? `${formatText(itemType)}` : `Add Item Details`}
              {!itemType && <span>*</span>}
            </div>
            <div className="details">{`${
              itemDescription ? itemDescription : `Item Description`
            }`}</div>
          </div>
          <FontAwesomeIcon icon={solid('angle-right')} />
        </div>
      </div>
      <ItemDetailsList
        show={showItemDetailsDrawer}
        onSetItemDetailsDrawer={() => setItemDetailsDrawer(false)}
      />
    </>
  );
};

const mapStateToProps = state => ({
  view: state.homeReducer.view,
  onDemandItemType: state.onDemandReducer.itemType,
  standardItemType: state.standardReducer.itemType,
  onDemandItemDescription: state.onDemandReducer.itemDescription,
  standardItemDescription: state.standardReducer.itemDescription,
});

export default connect(mapStateToProps, null)(ItemDetails);
