import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import axios from 'axios';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { setKey, setDefaults, geocode, RequestType } from 'react-geocode';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Skeleton, Space, message } from 'antd';

import SubmitButton from '../../../../shared/components/SubmitButton';
import Preloader from '../../../../shared/elements/Preloader';

import {
  MAPS_GEOCODE,
  MAPS_API,
  MAPS_API_KEY,
  zeroKm,
} from '../../../../shared/utils/enums/AppConstants';
import { isEmpty } from '../../../../shared/utils/helpers/purefunctions';
import { setSelectedAddress } from '../../../../shared/redux/addressBook/actions';

import { ICON, BG } from '../../../../shared/utils/enums/AppIcons';
import { MODULE_PATH } from '../../../../shared/constants/Module';

import Maps from './Maps';

const PinMapLocation = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const selectedAddress = useSelector(state => state.addressReducer.selectedAddress);

  const [pinLocation, setPinLocation] = useState(null);
  const [loader, setPreLoader] = useState(false);
  const [address, setAddress] = useState('');
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [setCurrent, onSetCurrent] = useState(false);

  setDefaults({
    key: MAPS_API_KEY,
  });

  setKey(MAPS_API_KEY);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        if (latitude && longitude) {
          setCurrentLocation({
            lat: latitude,
            lng: longitude,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    const address = `${selectedAddress?.unitFloorHouse}, ${selectedAddress?.bldgStreet}, ${selectedAddress?.barangay}, ${selectedAddress?.city}, ${selectedAddress?.province}`;
    setAddress(address);
    onSetGeocodeByAddress(address);
  }, []);

  const onSetGeocodeByAddress = debounce(address => {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        onSetPinLocation({
          lat: latLng.lat,
          lng: latLng.lng,
        });
      })
      .catch(err => {
        if (err === 'ZERO_RESULTS') {
          message.error('Address not found. Please manually pin your location instead.');
        } else {
          message.error(err);
        }

        setPreLoader(true);
      });
  }, 1000);

  const onSetPinLocation = debounce(async data => {
    try {
      const res = await axios.get(
        `${MAPS_GEOCODE}?latlng=${data.lat},${data.lng}&key=${MAPS_API_KEY}`
      );
      if (res.data.results[0]) {
        const resData = res.data.results[0];
        setPinLocation(resData);
        setAddress(resData.formatted_address);
      } else {
        message.error("Can't locate address. ");
      }
      setPreLoader(true);
    } catch (err) {
      setPreLoader(true);
      message.error(err);
    }
  }, 1000);

  const handleChange = address => {
    onSetCurrent(false);
    setAddress(address);
    setSuggestionsOpen(true);
  };

  const handleSelect = async selectedAddress => {
    onSetCurrent(false);
    setPreLoader(false);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);
      setAddress(selectedAddress);
      onSetPinLocation({
        lat: latLng.lat,
        lng: latLng.lng,
      });
      setSuggestionsOpen(false);
      setPreLoader(true);
    } catch (error) {
      setPreLoader(true);
      message.error('Error selecting place:', error);
    }
  };

  const handleMarkerDragEnd = e => {
    onSetCurrent(false);
    setPreLoader(false);
    onSetPinLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const onSetCurrentLocation = () => {
    if (setCurrent) return;
    onSetCurrent(true);
    setPreLoader(false);
    onSetPinLocation(currentLocation);
  };

  const handleBlur = () => {
    setSuggestionsOpen(false);
  };

  const confirmLocation = () => {
    if (!loader) return;
    let latLng = {
      latitude: pinLocation?.geometry?.location?.lat || 0,
      longitude: pinLocation?.geometry?.location?.lng || 0,
    };

    dispatch(
      setSelectedAddress({
        ...selectedAddress,
        ...latLng,
        addressType: !isEmpty(selectedAddress?.addressType)
          ? selectedAddress?.addressType
          : `${pinLocation?.address_components[0]?.long_name || ''} ${
              pinLocation?.address_components[1]?.long_name || ''
            }`,
        address: pinLocation?.formatted_address || selectedAddress.address,
      })
    );
    navigate(MODULE_PATH.ADDRESS_BOOK.FORM, { state: location?.state });
  };

  return (
    <div className={`map-search ${suggestionsOpen ? 'hidden' : ''}`}>
      <div className="map-search-input">
        <div className="addressbook-container">
          <div className="recently__used__location">
            <Button
              className="btn-return"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(MODULE_PATH.ADDRESS_BOOK.FORM, { state: location?.state })}
            />
            <img src={ICON.CENTER_MAP} alt="point location" />
            <div className="address-data">
              <span className={`address-info ${loader ? '' : 'address-loader'}`}>
                {loader ? (
                  <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    searchOptions={{
                      types: ['(regions)'],
                      componentRestrictions: { country: 'PH' },
                    }}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div className="place-auto-complete">
                        <input
                          {...getInputProps({
                            placeholder: 'Enter a location',
                            className: 'location-search-input',
                            onBlur: handleBlur,
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <Preloader />}

                          {suggestions.map(suggestion => {
                            return (
                              <>
                                <div
                                  {...getSuggestionItemProps(suggestion)}
                                  className="search-address-set-points"
                                >
                                  <span>
                                    <img src={ICON.SEARCH_PIN} />
                                  </span>
                                  <span>
                                    <span>{suggestion.formattedSuggestion.mainText}</span>
                                    <span>{suggestion.formattedSuggestion.secondaryText}</span>
                                  </span>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : (
                  <Space style={{ display: 'grid' }}>
                    <Skeleton.Input block={true} active={true} size={'small'} />
                    <Skeleton.Input block={true} active={true} size={'small'} />
                  </Space>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      {loader && !isEmpty(pinLocation) ? (
        <Maps
          googleMapURL={`${MAPS_API}?key=${MAPS_API_KEY}`}
          coordinates={pinLocation?.geometry?.location || zeroKm}
          onSetPinLocation={(location, latlong) => onSetPinLocation(location)}
          containerElement={
            <div
              style={{ height: `100vh`, marginTop: `-115px` }}
              className={`${suggestionsOpen ? 'hidden' : ''}`}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
          loadingElement={<div style={{ height: `100%` }} />}
          onMarkerDragEnd={handleMarkerDragEnd}
        />
      ) : (<div className='loading__map__container'><img src={BG.KM_ZERO} className='loading__map'/></div>)
      }
      {!suggestionsOpen && currentLocation && !setCurrent && (
        <img
          className="current__location__addressbook"
          onClick={() => onSetCurrentLocation()}
          src={ICON.CURRENT_LOC}
        />
      )}

      <div className={`address-book-confirm-pin ${suggestionsOpen ? 'hidden' : ''}`}>
        <SubmitButton
          isProceedValue={loader && true}
          onClick={() => confirmLocation()}
          name="Confirm Pin Location"
        />
      </div>
    </div>
  );
};

export default PinMapLocation;
