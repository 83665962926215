import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { MODULE_PATH } from '../constants/Module';

import kgImage from '../assets/images/OndemandDelivery/kg.png';
import boxImage from '../assets/images/OndemandDelivery/box.png';
import { ON_DEMAND_ICON } from '../utils/enums/AppIcons';
import { COURIER_VEHICLE_TYPES } from '../utils/enums/Providers';
import { capitalizeName, formatUnderscoredText } from '../utils/helpers/purefunctions';

const VehicleType = ({ selectedVehicle }) => {
  const navigate = useNavigate();

  const handleSelectVehicle = () => {
    navigate(MODULE_PATH.SHARED.VEHICLE, {});
  };

  const VehicleIcon = ({ value }) => {
    let vehicleIcon = 'MOTORCYCLE';
    vehicleIcon = COURIER_VEHICLE_TYPES[value.toLowerCase()];
    return <img src={ON_DEMAND_ICON[vehicleIcon]} alt={value} />;
  };

  return (
    <div className="vehicle__type" onClick={() => handleSelectVehicle()}>
      <div className="title">Available Vehicle</div>
      <div className="info">
        <VehicleIcon value={selectedVehicle.value} />
        <div className="vehicleType-info">
          <div className="header">
            {selectedVehicle.vehicleType === 'MPV'
              ? selectedVehicle.vehicleType
              : formatUnderscoredText(selectedVehicle.vehicleType)}
          </div>
          <div className="details">
            <span>
              <img src={kgImage} alt="kg" /> {selectedVehicle?.description?.weightText || ''}
            </span>

            <span>
              <img src={boxImage} alt="bag" /> {selectedVehicle?.description?.dimensionText || ''}
            </span>
          </div>
        </div>
        <FontAwesomeIcon icon={solid('angle-right')} />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  selectedVehicle: state.onDemandReducer.vehicleType,
});

export default connect(mapStateToProps, null)(VehicleType);
