import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { Drawer, Space, Button, TimePicker, DatePicker, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import SubmitButton from '../../../../shared/components/SubmitButton';
import { SCHEDULE } from '../../../../shared/utils/enums/AppIcons';
import { PICK_CARD } from '../../../../shared/constants/OnDemand';
import {
  DAY_MMM_D_HH_MM,
  DAY_MMM_D_YYYY,
  H_MM_A,
  KEEN_DATE,
  YYYY_MM_DD_H_MM_A,
} from '../../../../shared/utils/enums/dateFormats';
import { setTimeInterval, getServerTime } from '../../../../shared/utils/helpers/purefunctions';
import { setPickupDateTime, setPickUpTime } from '../../../../shared/redux/onDemand/actions';

const Schedule = ({ show, setScheduleDrawer }) => {
  const dispatch = useDispatch();
  const scheduledDateTime = useSelector(state => state.onDemandReducer.pickupDateTime);
  const pickUpTime = useSelector(state => state.onDemandReducer.pickUpTime);

  const [defaultTime, setDefaultTime] = useState(moment());
  const [scheduleDateTime, setScheduleDateTime] = useState(moment());
  const [time, SetTime] = useState('0:00 AM');
  const [noToBackDating, setNoToBackDating] = useState(true);
  // const [isPanelVisible, setIsPanelVisible] = useState(false);
  // const [timeoutId, setTimeoutId] = useState(null);

  const [timeSched, setTimeSched] = useState(PICK_CARD.NOW);

  const [isOpen, setIsOpen] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(282);

  useEffect(() => {
    if (show) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [show]);

  useEffect(() => {
    setCurrentDateAndTime();
    setTimeSched(pickUpTime);
    // change change UX 410 to 560
    const pickLater = pickUpTime === PICK_CARD.LATER ? 560 : 284;
    setDrawerHeight(pickLater);
  }, [scheduledDateTime, pickUpTime]);
  const setCurrentDateAndTime = async () => {
    let currDateTime = await getServerTime();
    let dateTime = setTimeInterval(currDateTime);
    setDefaultTime(dateTime);
    if (scheduledDateTime) {
      dateTime = moment(scheduledDateTime);
      setScheduleDateTime(dateTime);
    }
    SetTime(moment(dateTime, 'x').format(H_MM_A));
  };

  const handleSetSchedulerDrawer = () => {
    setDrawerHeight(284);
    setTimeSched(PICK_CARD.NOW);
    setScheduleDrawer();
  };

  const handledSetTimeSched = value => {
    if (!isOpen) {
      // change change UX 410 to 560
      const pickLater = value === PICK_CARD.LATER ? 560 : 284;
      setDrawerHeight(pickLater);

      setTimeSched(value);
    }
  };

  const handleChangeDate = action => {
    if (action === 'subtract' && noToBackDating) return;
    const newPickUpDate = moment(scheduleDateTime)[action](1, 'day');
    setNoToBackDating(
      moment(newPickUpDate.format(KEEN_DATE)).diff(moment(defaultTime).format(KEEN_DATE)) <= 0
    );
    setScheduleDateTime(newPickUpDate);
  };

  const onChangeTime = (time, timeString) => {
    const getSelectedTime = moment(
      `${moment(scheduleDateTime).format(KEEN_DATE)} ${timeString}`,
      YYYY_MM_DD_H_MM_A
    );

    SetTime(moment(getSelectedTime, 'x').format(H_MM_A));
  };

  const handleTimePickerOpenChange = status => {
    setIsOpen(status);

    // change change UX
    // if (status) {
    //   setDrawerHeight(560);
    // } else {
    //   setDrawerHeight(410);
    // }
  };

  const onSetPickUpTime = () => {
    const getSelectedDateTime = moment(
      `${moment(scheduleDateTime).format(KEEN_DATE)} ${time}`,
      YYYY_MM_DD_H_MM_A
    );
    if (timeSched === PICK_CARD.LATER && moment(defaultTime).diff(getSelectedDateTime) > 0) {
      return message.info('Past Date and Time is not allowed');
    }

    dispatch(setPickupDateTime(getSelectedDateTime.valueOf()));
    dispatch(setPickUpTime(timeSched));

    handleSetSchedulerDrawer();
  };

  let pickSelected = 'pick__schedule';
  let pickUpTimer = 'pickup__timepicker';
  if (timeSched === PICK_CARD.LATER) {
    pickSelected = `${pickSelected} selected`;
  }
  if (isOpen) {
    pickSelected = `${pickSelected} slide`;
    pickUpTimer = `${pickUpTimer} slide`;
  }

  const pickUpLaterDisplay = moment(scheduleDateTime).format(DAY_MMM_D_HH_MM);

  return (
    <Drawer
      title="Pickup Time"
      placement="bottom"
      height={drawerHeight}
      className="schedule__time"
      onClose={() => handleSetSchedulerDrawer()}
      open={show}
      closable={false}
      extra={
        <Space>
          <Button className="close-btn" onClick={() => handleSetSchedulerDrawer()}>
            <FontAwesomeIcon icon={solid('x')} />
          </Button>
        </Space>
      }
    >
      <div className="schedule__time__container">
        <div
          className={`pick__schedule ${timeSched === PICK_CARD.NOW ? 'selected' : ''}`}
          onClick={() => handledSetTimeSched(PICK_CARD.NOW)}
        >
          <span>
            <FontAwesomeIcon icon={solid('bolt')} style={{ color: '#e94e75' }} />
          </span>
          <span>
            Pickup Now <span>(As soon as possible)</span>
          </span>
          <span className="selected__icon">
            <img src={SCHEDULE.SELECTED} />
          </span>
        </div>
        <div className={pickSelected} onClick={() => handledSetTimeSched(PICK_CARD.LATER)}>
          <div className="schedule__picklater__heading">
            <span>
              <img src={SCHEDULE.CLOCK} />
            </span>
            <span>{`Pickup Later (${pickUpLaterDisplay})`}</span>
            <span className="selected__icon">
              <img src={SCHEDULE.SELECTED} />
            </span>
          </div>
          <div className="schedule__date_time">
            <div className="schedule__date">
              <FontAwesomeIcon
                className={`schedule__date__nav left ${noToBackDating}`}
                icon={solid('chevron-left')}
                onClick={() => handleChangeDate('subtract')}
              />
              <div className="schedule__date__picker">
                <DatePicker
                  size={'large'}
                  defaultValue={scheduleDateTime}
                  format={DAY_MMM_D_YYYY}
                  value={scheduleDateTime}
                  allowClear={false}
                  disabled
                />
              </div>
              <FontAwesomeIcon
                className="schedule__date__nav right"
                icon={solid('chevron-right')}
                onClick={() => handleChangeDate('add')}
              />
            </div>
            <div className="time__container">
              <TimePicker
                className={pickUpTimer}
                defaultValue={moment(
                  `${moment(scheduleDateTime).format(KEEN_DATE)} ${time}`,
                  YYYY_MM_DD_H_MM_A
                )}
                use12Hours
                format={H_MM_A}
                minuteStep={15}
                onChange={onChangeTime}
                inputReadOnly={true}
                // open={isPanelVisible}
                onOpenChange={handleTimePickerOpenChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`schedule__time__footer ${isOpen ? 'slide' : ''}`}>
        <SubmitButton
          isProceedValue={true}
          name="Confirm Schedule"
          onClick={() => onSetPickUpTime()}
        />
      </div>
    </Drawer>
  );
};
export default Schedule;
