import React from 'react';
import { sortList } from '../../../../../shared/utils/helpers/purefunctions';
import Orders from './elements/Orders';

function ListOfOrders({
  orderList,
}) {

  const OrdersList = ({ status, orders }) =>
    !orders.length ? (
      <span className="no-order">{`You have no ${status} bookings.`}</span>
    ) : (
      <Orders orders={sortList(orders)} />
    );

  return (
    <div className="list-of-orders">
    <OrdersList status="pending" orders={orderList} />
    
    </div>
  );
}

export default ListOfOrders;
