export const PRIVACY_POLICY = {
    first_content: [
        [
            { title: 'Privacy Notice' },
            {
                0:'CTO Global Services Inc. (“CTO Global Services Inc”) operating under the trade name “Last Mile Inc.” , understands and respects our users’ need for privacy. This Privacy Notice (“Notice”) describes the types of information we collect, the purposes for which it is used, and the choices you have with respect to its use.'
            }
        ],
        [
            { title: 'About Parcels App' },
            {
                0: 'Parcels.ph is a 3PL services booking platform that provides the user access to the largest logistics providers and book their preferred service in a single app. You can access Parcels.ph service (“Service” or “Services”) via the GCASH MINI application.'
            }
        ],
        [
            { title: 'Scope and Application' },
            { 0: 'This Notice applies to users of the Platform, as well as to our Clients’ customers (“Recipients”). We encourage you to read this Notice in full to understand our privacy practices before using our Platform.' }
        ],
        [
            { title: 'Information We Receive From You' },
            {
                0: 'We collect the following information through your use of our Platform, and otherwise with your consent. In some cases, we receive information directly from you, such as when you sign up for an account at Parcels.ph., we collect your name, email address, and phone number. In other cases, we receive information about the other parties such as recipients or sender, from our Clients when they use our Platform, such as name, email address, phone number and geolocation as a required information when booking services of the 3 rd party logistics providers. The types of information that we collect include:', 
                1: '1. Information about you the delivery details – Name, email address, postal address, phone number, company name, customer and delivery information (such as scheduling information and delivery destination) for both the sender and recipient, and in some cases, your photograph. ',
                2: '2. Personal Data – Which are voluntarily entered by the user in free text box as delivery details. ',
                3: '3. Business contact information – We collect personal information from individuals when we attend or host events, conferences, and other business meetings. This information includes business contact information such as name, email address, and phone number. ',
                4: '4. Device information – IP address, device type, operating system, manufacturer, model and version number, and unique device identifiers such as your device ID, but not IMEI. We collect this information automatically when you use our Platform.',
                5: '5. Browser and usage information – Operating System (OS) running on your device, Internet Protocol (IP) address, access times, browser type, and language, and the website you visited before our Website. We also collect usage details, such as time, frequency, and use pattern. In some cases, we analyze information on specific end users such as the amount of time spent on the Platform. In other cases, we look at data in the aggregate. We use this information to optimize our Services. We collect this information automatically when you use our Platform. To learn about your information collection choices and to opt-out of data collection, see the “Your Choices” section below. ',
            }
        ],
        [
            { title: 'Information We Receive from Other Sources' },
            {
                1: '1. Clients of our Services – Clients of our Platform may provide information about you when they submit content through our Platform. For example, you may be mentioned in a troubleshooting ticket opened by someone else. We also receive your email address from other users when they provide it to invite you to our Platform. Similarly, a dispatcher may provide your contact information when they designate you as a driver on their company’s account. ',
                2: '2. Client customers – We may receive personal data about you from one of our Client’s customers. We will only use this information for the purpose of providing our Services. For example, a recipient of a delivery may provide personal information about you when submitting a support ticket.',
                3: '3. Other partners – We receive information from partners to help us find potential customers and enhance our Platform with useful information. For example, information provided by our partners allows us to provide route optimization services, such as when we receive mapping and location information or spatial data analytics from Google Maps.'
            }
        ],
        [
            { title: 'How We Use, Share, and Disclose Information'},
            { 
                 0: 'We use collected information to:', 
                 1: '1. Communicate with you  or the intended parties– We may contact you to respond to your inquiries, requests, and/or send important notices either via email, SMS, push notifications, and in-app notifications. This includes, for example, sending confirmations, invoices, technical notices, routing updates, security alerts, administrative messages, and providing you with updates about our Platform and new features. See “Your Choices” below to learn how to manage your communication preferences.',
                 2: '2. Provide customer support – We use your personal and Platform usage information to resolve support issues that may arise through your use of our Platform. CTO Global Services Inc employees are prohibited from viewing the content of data you import into your Fleet.ph account, except when necessary to resolve your support issues. Access is limited to the data required to resolve your support issues.',
                 3: '3. Deliver and improve our Services – We use your information to deliver and analyze how you use our Platform, develop new products and services, and improve functionality, quality, and user experience. For example, we track geolocation, driver behavior, and other information to facilitate route optimization and other features. This includes using aggregated, anonymized data to improve our Services.',
                 4: '4. Market our Services – We use your information to market our Platform. For example, we may send you an email notification about new product features. Additionally, we may use pseudonymized personal information collected on our website to better tailor marketing or website content, including to run or optimize our service, or for other purposes, such as internal research. To learn more about how we track and use your information, see the “Use of Cookies and Other Tracking Technologies” section below.',
                 5: '5. Advertise – Our MobileApp does not contain or allow in-app targeted advertising at this time. We also do not use push notifications or in-app notifications to display advertisements to our users.',
                 6: '6. Store data – We store data on servers hosted by Google and DigitalOcean. We use appropriate technical, administrative, and physical measures to secure your data during storage. We share information about you and your vehicle(s) with third parties only in the manner described below:'
             }
         ],
         [
            { title: null },
            {
                1: '1. Marketing and advertising – We do not sell information about you to advertisers or other third parties. We do not currently share your information with third parties for marketing purposes, but if that changes we will obtain your consent first.',
                2: '2. Other users – If you register or access our Platform through a Last Mile platforms a Client, certain information about you including your name, contact info, photograph, content and past use of your account may become accessible to that Client and other individuals with whom the Client shares access. If you are a Client managing a particular group of users within our Platform, we may share your contact information with current or past users, for the purpose of facilitating Services-related requests.',
                3: '3. Mergers and acquisitions – If your personal information is transferred to a party unaffiliated with CTO Global Services Inc as part of merger, acquisition, or sale of all or a portion of our assets, we will provide you with notice prior to transferring your personal information to the new entity. Notice will be provided directly through our Services.',
                4: '4. Legal purposes – We disclose your information when we believe that disclosure is (1) reasonably necessary to comply with any applicable law, regulation, subpoena, legal process, or enforceable governmental request; (2) necessary to enforce the provisions of the Notice; (3) required to enforce our Terms of Service, including investigation of potential violations; or (4) necessary to protect against harm to the rights, property, or safety of CTO Global Services Inc, our users, or the public as required or permitted by law.',
                5: '5. External processing and Subprocessors – We provide your information to other third parties to help us with our business activities, products, and services. These companies are authorized to use your information only as necessary to provide these services or perform them on our behalfWe use the following sub-processors to operate our Services.'
            }
        ]

    ],
    second_content: [
        [
            { title: 'Use of Cookies and Other Tracking Technologies' },
            {
                0: 'We use cookies to collect your personal and other information as you navigate our Services. Cookies help make interactions with our Platform easier and faster for our users.'
            }
        ], 
        [
            { title: '“Do-Not-Track” and Targeted Ads' },
            {
                0: 'We do not respond to web browser “Do-Not-Track” signals. We do not serve targeted advertisements in our Services at this time. However, you may see targeted ads from us on other websites based on your browsing history or other online activities.'
            }
        ],
        [
            { title: 'Your Choices' },
            {
                0: 'Where appropriate or legally required, we will describe how we use personal information, so you can make choices about how your data is used. You can notify us of your preferences during the information collection process and change your selection at any time by contacting us directly.',
                1: '1. Geolocation – We collect your geographic location when you use our Platform. You can restrict our access to and collection of your location information by disabling location-sharing on your device, located in your device (e.g., mobile phone) settings. Please note that opting-out of may impact how our Platform function.',
                2: '2. Marketing emails – You can choose to stop receiving marketing emails by following the unsubscribe instructions included in these emails, or by using the email address listed in the “Contact Us” section below. If you opt out of receiving marketing emails, we may still send you non-promotional emails, such as emails about your Fleet.ph account.',
                3: '3. Push notifications – You can opt-out of receiving push notifications through your device settings. Please note that opting-out of receiving push notifications may impact how our Platform function',
                4: '4. Mobile application information – You can stop our Platform from collecting information through the MobileApp by uninstalling the mobile app. You can use the standard uninstall processes available on your mobile device or via the mobile application marketplace or network. You can also contact us to deactivate your account using the email address listed in the “Contact Us” section below.'
            }
        ],
        [
            { title: 'Your Rights' },
            { 
                A: 'You have certain rights in connection with the personal information we obtain about you. To update your preferences, correct your information, limit the communications you receive from us, or submit a request to exercise your rights, please contact us as specified in the “Contact Us” section.',
                B: 'As required by law, you have the right to:',
                1: '1. Request access to certain personal information we maintain about you;',
                2: '2. Request that we update, correct, amend, erase or restrict certain personal information;',
                3: '3. Object to some forms of automated decision-making or profiling;',
                4: '4. File a complaint with a relevant Data Protection Authority; and',
                5: '5. Exercise your right to data portability'
            }
        ],
        [
            { title: null },
            {
                0: 'Where our Services are administered for you by a Client, you may need to first contact the Client to assist with your requests. For all other requests, you can contact us as provided in the “Contact Us” section below to request assistance.',
                1: 'In some circumstances you can withdraw consent you previously provided to us or object to the processing of your personal information, and we will apply your preferences moving forward.',
                2: 'To help protect your privacy and maintain security, we may take steps to verify your identity before granting you access to the information. We may also decline your access request, but in the event we do, we will provide an explanation for our decision. Your request and choices may be limited in certain cases: for example, if fulfilling your request would reveal information about another person, or if you ask to delete information which we or your administrator are permitted by law or have compelling legitimate interests to keep. If you have unresolved concerns, you may have the right to complain to a data protection authority in the country where you live, where you work or where you feel your rights were infringed.'
            }
        ],
        [
            { title: 'Children’s Privacy' },
            {
                0: 'Our Platform are not directed to children under the age of 13, and we do not knowingly collect information from children under the age of 13.'
            }
        ],
        [
            { title: 'How Long We Retain Information' },
            {
                0: 'How long we keep information we collect about you depends on the type of information, as described below. We will either delete or anonymize your information or, if this is not possible (for example, the information is stored in backup archives), then we will securely store your information and isolate it from any further use until deletion is possible.',
                1: '1. Account information – We retain your account information for as long as your account is active and a reasonable period thereafter in case you decide to re-activate our services. We also retain some of your information as necessary to comply with our legal obligations, to resolve disputes, to enforce our agreements, to support business operations, and to continue to develop and improve our Platform. Where we retain information for the improvement and development of our Platform, we take steps to eliminate information that directly identifies you. If our Platform are made available to you through our Clients, we retain your information as long as agreed with the Client.',
                2: '2. Marketing information – If you have elected to receive marketing emails from us, we retain information about your marketing preferences for a reasonable period of time from the date you last expressed interest in our Services, such as when you last opened an email from us or ceased using your Fleet.ph account. We retain information derived from cookies and other tracking technologies for a reasonable period of time from the date the information was created.'
            }
        ],
        [
            { title: 'Security Safeguards' },
            {
                0: 'We use reasonable and appropriate physical, technical, and administrative safeguards to protect your information from unauthorized use, access, loss, misuse, alteration, or destruction. We also require that third party service providers acting on our behalf or with whom we share your information also provide appropriate security measures in accordance with industry standards. Notwithstanding our security safeguards, it is impossible to guarantee absolute security in all situations. If you have any questions about security of our Services, please contact us using the email address listed in the “Contact Us” section below.'
            }
        ],
        [
            { title: 'Changes to this Privacy Notice' },
            {
                0: 'We periodically update this Notice to describe new features, products, or services, and how those changes affect our use of your information. If we make material changes to this Notice, we will provide notification through our services and/or notify you directly. We encourage you to review this Notice for updates each time you use our Services.'
            }
        ],
        [
            { title: 'Third Party Services, Applications, and Websites' },
            {
                0: 'Certain third party services, websites, or applications you use, or navigate to from our Website and/or Platform may have separate user terms and privacy policies that are independent of this Notice. This includes, for example, websites owned and operated by our customers or partners. We are not responsible for the privacy practices of these third party services or applications. We recommend carefully reviewing the user terms and privacy statement of each third party service, website, and/or application prior to use.'
            }
        ],
        [ 
            { title: 'Contact Us' },
            {
                0: `If you have questions about this Notice or our information handling practices, please contact us at account@ctoglobal.co or write to us a: CTO Global Services Inc. 2F Comfac Global Group Technology Center 536 Calbayog St, Highway Hills, Mandaluyong City PH`
            }
        ]
    ]
}
export const SUB_PROCESSORS = [
    {
        title: 'Third-Party Service or Vendor',
        vendors: ['G Suite', 'mLab (MongoDB, Inc.)', 'AppXys', 'NextSMS', 'Site 24/7', 'Redislabs', 'Digital Ocean', 'PostgreSQL', 'Google Cloud', 'SendGrid', 'Bitly']
    },
    {
        title: 'Type of Service',
        services: [
            'Business communications', 'Database management', 'Support ticketing', 'SMS platform', 
            'Infrastructure Monitorig', 'Database management', 'Cloud storage', 'Database management',
            'Database management', 'Automated Emails', 'URL shortening'   
        ]
    },
    {
        title: 'Location',
        locations: ['USA', 'USA', 'PH', 'PH', 'USA', 'USA', 'USA', 'USA', 'USA', 'USA', 'USA' ]
    },
    {
        title: 'Website',
        websites: [
            'https://gsuite.google.com/', 'https://mlab.com/', 'https://www.appxys.com/', 'https://nextsms.io/', 'https://site24x7.com/', 
            'https://redislabs.com/', 'https://digitalocean.com/', 'https://postgresql.org/', 'https://cloud.google.com', 'https://sendgrid.com',
            'https://bitly.com/'
        ]
    }
]
