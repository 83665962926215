import { Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import AddressPin from '../../../../shared/assets/images/OndemandDelivery/start_point_location.png';
import AddressPink from '../../../../shared/assets/images/OndemandDelivery/start_point_location_pink.png';
import PointLocation from '../../../../shared/assets/images/OndemandDelivery/point_location.png';
import BookmarkIcon from '../../../../shared/assets/images/OndemandDelivery/bookmark_icon.png';
import AddBookmark from '../../../../shared/assets/images/OndemandDelivery/bookmark_add.png';
import EditIcon from '../../../../shared/assets/images/OndemandDelivery/edit.png';
import { ICON } from '../../../../shared/utils/enums/AppIcons';
import { MODULE_PATH } from '../../../../shared/constants/Module';

import { setKey, setDefaults, geocode, RequestType } from 'react-geocode';
import { MAPS_API_KEY } from '../../../../shared/utils/enums/AppConstants';
import {
  setDropOff,
  setLocation,
  setLocationReference,
  setPickUp,
} from '../../../../shared/redux/onDemand/actions';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  setSelectedAddress,
  fetchListOfAddress,
} from '../../../../shared/redux/addressBook/actions';
import { setLoader } from '../../../../shared/redux/home/actions';
import { bindActionCreators } from 'redux';
import AddressBookDAO from '../../../../shared/utils/dao/AddressBook';
import { isEmpty } from '../../../../shared/utils/helpers/purefunctions';
import { data } from 'autoprefixer';

const SelectPickupAddress = ({
  fetchListOfAddress,
  recentlyAddress,
  addressList,
  user,
  setSelectedAddress,
  setPickUp,
  propsPickup,
  setDropOff,
}) => {
  const pickup = useSelector(state => state.onDemandReducer.pickUp);
  const dropOff = useSelector(state => state.onDemandReducer.dropOff);
  const pointLocation = useSelector(state => state.onDemandReducer.pointLocation);
  const { senderDetails } = pickup;
  const { recipientDetails } = dropOff;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentAddress, setCurrentAddress] = useState('');
  const [loader, onSetLoader] = useState(true);
  const [successCoordinates, setSuccessCoordinates] = useState(false);
  const [viewAll, setViewAll] = useState(2);
  const addressListSliced = addressList.slice(0, 3);

  setDefaults({
    key: MAPS_API_KEY,
  });

  setKey(MAPS_API_KEY);

  useEffect(() => {
    if (navigator.geolocation) {
      // get the current users location
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setSuccessCoordinates(false);

          geocode(RequestType.LATLNG, `${latitude}, ${longitude}`).then(({ results }) => {
            const currentLocation = results[0].formatted_address;
            setCurrentAddress(currentLocation);
            let city = '';
            let province = '';

            results[0].address_components.forEach(component => {
              if (component.types.includes('locality')) {
                city = component.long_name;
              } else if (
                component.types.includes(
                  'administrative_area_level_1' || 'administrative_area_level_2'
                )
              ) {
                province = component.long_name;
              }
              console.log('city', city);
              console.log('province', province);

              if (pointLocation === 'PickUp') {
                setPickUp({ address: currentLocation, ...senderDetails, city, province });
              }
              if (pointLocation === 'DropOff') {
                setDropOff({ address: currentLocation, ...recipientDetails, city, province });
              }
            });
          });
        },

        error => {
          console.error('Error getting user location:', error);
          message.info('Please turn on location');
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
    fetchAddress();
  }, [fetchListOfAddress]);

  useEffect(() => {}, [addressList]);

  const fetchAddress = () => {
    console.log('userid', user.id);
    fetchListOfAddress(user.id);
    setLoader(false);
    onSetLoader(false);
  };

  const onClickCurrentAddress = () => {
    // dispatch(setLocationReference(true));
    navigate(MODULE_PATH.ADDRESS.PICKUP_DETAILS, { state: { showAutoComplete: true } });
    dispatch(setLocation(currentAddress));
  };
  console.log('addressList:', addressList);
  const onClickSavedAddress = savedAddress => {
    // console.log('savedAddress prev', savedAddress.address);
    if (pointLocation === 'PickUp') {
      setPickUp({
        latitude: savedAddress.latitude,
        longitude: savedAddress.longitude,
        city: savedAddress.city,
        province: savedAddress.province,
        address: savedAddress.address,
        senderDetails: {
          ...senderDetails,
          fullName: savedAddress.fullName,
          unitFloorHouse: savedAddress.unitFloorHouse,
          contactNo: savedAddress.contactNo,
          address: savedAddress.address,
          city: savedAddress.city,
          province: savedAddress.province,
        },
      });
    }
    if (pointLocation === 'DropOff') {
      setDropOff({
        latitude: savedAddress.latitude,
        longitude: savedAddress.longitude,
        city: savedAddress.city,
        province: savedAddress.province,
        address: savedAddress.address,
        recipientDetails: {
          ...recipientDetails,
          fullName: savedAddress.fullName,
          unitFloorHouse: savedAddress.unitFloorHouse,
          contactNo: savedAddress.contactNo,
          address: savedAddress.address,
          city: savedAddress.city,
          province: savedAddress.province,
        },
      });
    }
    dispatch(setLocation(savedAddress.address));
    // dispatch(setSelectedAddress(savedAddress));
    // dispatch(setLocationReference(true));
    navigate(MODULE_PATH.ON_DEMAND.MAIN);
  };

  const onClickSearchLocation = () => {
    dispatch(setLocationReference(false));
    navigate(MODULE_PATH.ADDRESS.SEARCH_LOCATION);
  };

  const handleUpdate = obj => {
    setSelectedAddress(obj);
    navigate(MODULE_PATH.ADDRESS_BOOK.FORM, { state: { addressBookForm: 'Edit' } });
  };

  const handleViewAll = () => {
    setViewAll(addressList.length);
  };

  const preventHandler = e => {
    e.stopPropagation();
  };

  return (
    <div className="container__select__address">
      <div className="container__header">
        <Button
          className="back-button"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(MODULE_PATH.ON_DEMAND.MAIN)}
        />
        <img onClick={e => preventHandler(e)} src={AddressPin} alt="address pin" />
        <input
          placeholder={pointLocation === 'PickUp' ? 'Pickup at?' : 'Drop-off at?'}
          className="search-address-input"
          onClick={() => onClickSearchLocation()}
        />
      </div>

      <div className="current__location">
        <img src={AddressPink} alt="address pin" />
        <div
          className="address-value"
          onClick={() => currentAddress !== '' && onClickCurrentAddress(currentAddress)}
        >
          <span>Current Location</span>
          <span>{currentAddress}</span>
        </div>
        <img src={AddBookmark} alt="bookmark add" />
      </div>

      {addressList.length > 0 &&
        addressList
          .filter(filteredAddress => filteredAddress.isSavePlaces === true)
          .sort((a, b) => b.timestamp - a.timestamp)
          .map(
            (address, index) =>
              index === 0 && (
                <div
                  className="recently__used__location"
                  key={index}
                  onClick={() => onClickSavedAddress(address)}
                >
                  <div className="title">Recently Used</div>
                  <div className="content">
                    <img src={PointLocation} alt="point location" />
                    <div className="address-data">
                      <span>{address?.addressType || address?.province}</span>
                      <span>
                        {address.unitFloorHouse} {address.address}
                      </span>
                      <span>
                        {address.fullName} •{`+63 ${address.contactNo}`}
                      </span>
                      <span>Recently Used</span>
                    </div>
                    <img src={AddBookmark} alt="bookmark add" />
                  </div>
                </div>
              )
          )}

      <div className="saved__places">
        <div className="title">
          <span>Saved Places</span>
          <span onClick={() => handleViewAll()}>View all</span>
        </div>

        {addressList.length > 0 &&
          addressList
            .filter(filteredAddress => filteredAddress.isSavePlaces === true)
            .sort((a, b) => b.timestamp - a.timestamp)
            .map(
              (address, index) =>
                index <= viewAll && (
                  <div className="content">
                    <img src={AddBookmark} alt="bookmark" />
                    <div
                      className="address-data"
                      key={index}
                      onClick={() => onClickSavedAddress(address)}
                    >
                      <span>{address?.addressType || address?.province}</span>
                      <span>
                        {address.unitFloorHouse} • {address.address}
                      </span>
                      <span>
                        {address.fullName} • {`+63 ${address.contactNo}`}
                      </span>
                      {index === 0 && <span>Recently Used</span>}
                    </div>
                    <img src={EditIcon} alt="Edit" onClick={() => handleUpdate(address)} />
                  </div>
                )
            )}

        <div className="content">
          <img src={ICON.ADD_ADDRESS_BTN} alt="add address btn" />
          <div
            className="address-data"
            onClick={() =>
              navigate(MODULE_PATH.ADDRESS_BOOK.FORM, {
                state: { addressBookForm: 'Add New', isSavePlaces: true },
              })
            }
          >
            <span>Add New</span>
            <span>Save your favorite places</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const matchDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSelectedAddress,
      fetchListOfAddress,
      setLoader,
      setPickUp,
      setDropOff,
    },
    dispatch
  );

const mapStateToProps = state => ({
  addressList: state.addressReducer.listOfAddress,
  recentlyAddress: state.addressReducer.recentlyAddress,
  user: state.usersReducer.currentUser,
  propsPickUp: state.onDemandReducer.pickUp,
});

export default connect(mapStateToProps, matchDispatchToProps)(SelectPickupAddress);
