/**
 * This will serve as enums for all redux action type
 * strictly NO HARD CODED ACTIONS!!!
 */

export const SET_ONDEMAND_INITIAL_STATE = 'SET_ORDER_INITIAL_STATE';
export const SET_STANDARD_INITIAL_STATE = 'SET_ORDER_INITIAL_STATE';
export const SET_ADDRESSBOOK_INITIAL_STATE = 'SET_ADDRESSBOOK_INITIAL_STATE';

export const SET_FORM_VIEW = 'SET_FORM_VIEW';
export const SET_LOADER = 'SET_LOADER';
export const SET_FETCH = 'SET_FETCH';
export const SET_FETCH_ALL = 'SET_FETCH_ALL';
export const SET_TARGET_ORDER = 'SET_TARGET_ORDER';
export const SET_ORDER_LIST = 'SET_ORDER_LIST';
export const SET_FOR_REFUND_ORDER_LIST = 'SET_FOR_REFUND_ORDER_LIST';
export const SET_CANCELLED_ORDER_LIST = 'SET_CANCELLED_ORDER_LIST';
export const SET_COMPLETED_ORDER_LIST = 'SET_COMPLETED_ORDER_LIST';
export const SET_ORDER_LIST_MONGO = 'SET_ORDER_LIST_MONGO';

/* ADDRESS BOOK */
export const SET_DEFAULT_ADDRESS = 'SET_RECENTLY_ADDRESS';
export const SET_RECENTLY_ADDRESS = 'SET_DEFAULT_ADDRESS';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';
export const SET_LIST_ADDRESS = 'SET_LIST_ADDRESS';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_PAYMENT_REQUEST_ID = 'SET_PAYMENT_REQUEST_ID';
export const SET_ONDEMAND_PAYMENT_REQS = 'SET_ONDEMAND_PAYMENT_REQS';
export const SET_ONDEMAND_PAYMENT_RES = 'SET_ONDEMAND_PAYMENT_RES';

export const SET_TRANSACTION = 'SET_TRANSACTION';
export const SET_BANNER = 'SET_BANNER';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LOCATION_REFERENCE = 'SET_LOCATION_REFERENCE';

//ondemand
export const SET_VEHICLE_TYPE = 'SET_VEHICLE_TYPE';
export const SET_ONDEMAND_ORDER_ID = 'SET_ONDEMAND_ORDER_ID';
export const SET_ONDEMAND_POINT_LOCATION = 'SET_ONDEMAND_POINT_LOCATION';
export const SET_ONDEMAND_COURIER = 'SET_ONDEMAND_COURIER';
export const SET_ONDEMAND_PICK_UP = 'SET_ONDEMAND_PICK_UP';
export const SET_ONDEMAND_DROP_OFF = 'SET_ONDEMAND_DROP_OFF';
export const SET_ONDEMAND_PICKUP_DATETIME = 'SET_ONDEMAND_PICKUP_DATETIME';
export const SET_ONDEMAND_ITEM_TYPE = 'SET_ONDEMAND_ITEM_TYPE';
export const SET_ONDEMAND_ITEM_DESCRIPTION = 'SET_ONDEMAND_ITEM_DESCRIPTION';
export const SET_ONDEMAND_ITEM_PRICE = 'SET_ONDEMAND_ITEM_PRICE';
export const SET_ONDEMAND_PICKUP_TIME = 'SET_ONDEMAND_PICKUP_TIME';

//standard
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_TOOL_TIP = 'SET_TOOL_TIP';
export const SET_STANDARD_PICK_UP = 'SET_STANDARD_PICK_UP';
export const SET_STANDARD_DROP_OFF = 'SET_STANDARD_DROP_OFF';
export const SET_STANDARD_ORDER_ID = 'SET_STANDARD_ORDER_ID';
export const SET_STANDARD_ITEM_TYPE = 'SET_STANDARD_ITEM_TYPE';
export const SET_STANDARD_COURIER = 'SET_STANDARD_COURIER';
export const SET_STANDARD_POINT_LOCATION = 'SET_STANDARD_POINT_LOCATION';
export const SET_STANDARD_PICKUP_DATETIME = 'SET_STANDARD_PICKUP_DATETIME';
export const SET_STANDARD_ITEM_DESCRIPTION = 'SET_STANDARD_ITEM_DESCRIPTION';
export const SET_STANDARD_ITEM_PRICE = 'SET_STANDARD_ITEM_PRICE';
export const SET_STANDARD_PICKUP_TIME = 'SET_STANDARD_PICKUP_TIME';
export const SET_STANDARD_PAYMENT_REQUEST_ID = 'SET_STANDARD_PAYMENT_REQUEST_ID';
