import React from 'react';
import { useDispatch } from 'react-redux';
import ListOfOrders from './components/ListOfOrders';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MODULE_PATH } from '../../../../shared/constants/Module';
import OrderLoader from '../../../../shared/elements/Loader/OrderLoader';
import { ICON } from '../../../../shared/utils/enums/AppIcons';
import { setShowTooltip } from '../../../../shared/redux/home/actions';

const Orders = ({ orderList, fetched, fetchedAll }) => {
  let navigate = useNavigate();

  const dispatch = useDispatch();

  const Repeat = ({ numberOfTimes = 0, children }) => [...Array(numberOfTimes)].map(() => children);

  const hasOrder = orderList.length;

  const displayOrder = fetchedAll && hasOrder;

  const DisplayList = () => {
    if (displayOrder) {
      return <ListOfOrders orderList={orderList} />;
    }

    return (
      <div className="no-orders-container">
        <div className="no-orders-icon">
          <iframe src={ICON.NO_ORDER_HOME} title="No Orders Yet" />
        </div>
        <span className="no-orders-txt">
          Place a <span style={{ color: '#E94E75' }}>new order</span> by clicking the add button
          below
        </span>
        <img src={ICON.NO_ORDER_ARROW_HOME} alt="No Orders Yet Arrow" />
      </div>
    );
  };

  const showFooterBtn = fetchedAll || orderList.length;

  const handleTooltip = () => {
    dispatch(setShowTooltip(true));
    setTimeout(() => {
      dispatch(setShowTooltip(false));
    }, 5000);
  };

  const handleNavigateAllOrder = () => {
    if (showFooterBtn) {
      navigate(MODULE_PATH.ORDERS.VIEW_ALL_ORDERS);
    } else {
      handleTooltip();
    }
  };
  return (
    <>
      <div className="orders-container">
        <h3>{displayOrder ? 'Ongoing Orders' : ''}</h3>
        <div className="past-orders-container">
          <button
            className={`past-orders-btn ${showFooterBtn ? '' : 'no_orders'}`}
            onClick={() => handleNavigateAllOrder()}
          >
            View All Orders
          </button>
        </div>
      </div>

      {!fetched ? (
        <Repeat numberOfTimes={3}>
          <OrderLoader />
        </Repeat>
      ) : (
        <DisplayList />
      )}
    </>
  );
};
const mapStateToProps = state => ({
  orderList: state.homeReducer.orderList,
  fetched: state.homeReducer.fetch,
  fetchedAll: state.homeReducer.fetchAll,
});
export default connect(mapStateToProps)(Orders);
