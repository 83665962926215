import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActiveMyOrdersSVG from '../../../../shared/assets/images/Icons/Menu Icons/my-orders-active.svg';
import InactiveMyOrdersSVG from '../../../../shared/assets/images/Icons/Menu Icons/my-orders-inactive.svg';
import InactiveAddressBookSVG from '../../../../shared/assets/images/Icons/Menu Icons/address-book-inactive.svg';
import ActiveAddressBookSVG from '../../../../shared/assets/images/Icons/Menu Icons/address-book-inactive-1.svg';
import AddNewOrderSVG from '../../../../shared/assets/images/Icons/Menu Icons/add-new-order-icon-1.svg';
import OnDemandSVG from '../../../../shared/assets/svg/OnDemandDelivery.svg';
import StandardSVG from '../../../../shared/assets/svg/StandardDelivery.svg';
import { Drawer, Space, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { setFormView } from '../../../../shared/redux/home/actions';
import { setStandardInitialState } from '../../../../shared/redux/standard/actions';
import { MODULE_PATH, PAGE } from '../../../../shared/constants/Module';
import { BG } from '../../../../shared/utils/enums/AppIcons';

const FooterOld = ({ view, setFormView, tooltip }) => {
  const [drawer, setDrawer] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleShowDrawer = () => {
    setDrawer(true);
  };
  const onClose = () => {
    setDrawer(false);
  };
  const navigateToOnDemand = () => {
    dispatch({ type: 'SET_TARGET_ORDER', payload: {} });
    setFormView(MODULE_PATH.ON_DEMAND.MAIN);
    navigate(MODULE_PATH.ON_DEMAND.MAIN);
  };
  const navigateToStandard = () => {
    dispatch({ type: 'SET_TARGET_ORDER', payload: {} });
    setFormView(MODULE_PATH.STANDARD.MAIN);
    navigate(MODULE_PATH.STANDARD.MAIN);
  };

  // const navigateToComingSoon = () => {
  //   navigate(MODULE_PATH.EXTRAS.COMING_SOON);
  // };

  const navigateToHome = () => {
    setFormView(PAGE.HOME);
    navigate(MODULE_PATH.HOME);
  };

  const navigateToAddressBook = () => {
    setFormView(PAGE.ADDRESS_BOOK);
    navigate(MODULE_PATH.ADDRESS_BOOK.MAIN);
  };

  const styles = {
    cover: {
      backgroundImage: `url(${BG.FOOTER})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  };

  // const showFooterBtn = fetched && orderList.length

  return (
    <>
      <div className="container__footer" style={styles.cover}>
        <div className="bar">
          {tooltip ? <div className="bubble">Click here to book new order</div> : null}
          <div className="footer-button">
            <div className="my-orders-container">
              {/* <div className={`my-orders-container ${showFooterBtn ? '' :'hide__footer_btn'}`}> */}
              <button
                className={`my-orders-btn ${view === PAGE.HOME ? '' : 'inactive'}`}
                onClick={navigateToHome}
              >
                <img
                  src={view === PAGE.HOME ? ActiveMyOrdersSVG : InactiveMyOrdersSVG}
                  alt="My Orders"
                  className="my-orders-icon"
                />
                Orders
              </button>
            </div>
            <div className="add-new-order">Add New Order</div>
            <div className="address-book-container">
              {/* <div className={`address-book-container ${showFooterBtn ? '' :'hide__footer_btn'}`}> */}
              <button
                className={`address-book-btn ${view === PAGE.ADDRESS_BOOK ? '' : 'inactive'}`}
                onClick={navigateToAddressBook}
              >
                <img
                  src={view === PAGE.ADDRESS_BOOK ? ActiveAddressBookSVG : InactiveAddressBookSVG}
                  alt="Address Book"
                  className="address-book-icon"
                />
                Address Book
              </button>
            </div>
          </div>
          <button className="button" onClick={handleShowDrawer}>
            <img src={AddNewOrderSVG} alt="Add New Order" className="add-new-order-icon" />
          </button>
        </div>
      </div>
      <Drawer
        title="Service Type"
        placement="bottom"
        height="330px"
        className="service-type-drawer"
        onClose={onClose}
        open={drawer}
        closable={false}
        extra={
          <Space>
            <Button onClick={onClose} className="close-btn">
              <FontAwesomeIcon icon={solid('x')} />
            </Button>
          </Space>
        }
      >
        <p className="service-tip">Charges will only apply upon placing order.</p>
        <div className="on-demand-option" onClick={navigateToOnDemand}>
          <div className="on-demand-icon">
            <img src={OnDemandSVG} alt="On-Demand Delivery" />
          </div>
          <div className="on-demand-content">
            <h3>On-Demand Delivery</h3>
            <p>Deliver your items instantly.</p>
          </div>
        </div>
        <div className="standard-option" onClick={navigateToStandard}>
          <div className="standard-icon">
            <img src={StandardSVG} alt="Standard Delivery" />
          </div>
          <div className="standard-content">
            <h3>Standard Delivery</h3>
            <p>Deliver your items within 2 or more days.</p>
          </div>
        </div>
      </Drawer>
    </>
  );
};
const matchDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFormView,
      setStandardInitialState,
    },
    dispatch
  );
};
const mapStateToProps = state => ({
  view: state.homeReducer.view,
  tooltip: state.homeReducer.tooltip,
  orderList: state.homeReducer.orderList,
  fetched: state.homeReducer.fetch,
});
export default connect(mapStateToProps, matchDispatchToProps)(FooterOld);
